import { AnyAction, ThunkDispatch } from "@reduxjs/toolkit";
import _ from "lodash";
import moment, { CalendarSpec, MomentInput } from "moment";
import { Paths } from "types";
import { RootState } from "../../../../app/reducer";
import { getIntl } from "../../../../locale";
import { ACTIONS_MAPPING, GetDeliveryFee, getDeliveryFeeEST, requsetType } from "../../../../services/delivery_request";
import {
    ActivedTask,
    initialNewTaskRequestState,
    Item,
    MessageModalPages,
    NewTaskForm,
    NewTasksType,
    PaginationsType,
    setCurrentMessageModalPage,
    setCurrentModalPage,
    setInputSelectionState,
    setNewTaskFormState,
    setShouldMessageModalOpen,
    setShouldModalOpen,
    TaskModalPages,
    UpdateTaskPath,
} from "../../../../slices/delivery_request";
import { DoordashSetting } from "../../../../types/doordash-setting";
import { Address, Task } from "../../../../types/get-delivery-task-respone";
import { DeliveryFlags } from "../../../../types/store-delivery-flags";
import { TipsSettings } from "../../../../types/store-details";
import { ThirdPartyDeliverySettings } from "../../../../types/store-third-party-delivery-setting";
import {
    ADD_NEW_ITEM_KEY,
    COLLAPSED_PADDING_HEIGHT,
    DATE_FORMAT,
    DUE_TIME_FORMAT,
    EMPTY_ITEM,
    FILTERS_HEIGHT,
    FOOTER_HEIGHT,
    HEADER_HEIGHT,
    MANDATORY_FIELDS_MAPPING,
    NAV_HEIGHT,
    PAYMENT_STATUS_CODE_MAPPING,
    TASK_BG_COLOR_MAPPING,
    TASK_STATUS_CODE_CATEGORY_MAPPING,
    TASK_STATUS_CODE_MAPPING,
    TASK_STAUS_DESC_MAPPING,
    TASK_TEXT_COLOR_MAPPING,
    TIME_ASAP_DATE,
    UNCOLLAPSED_PADDING_HEIGHT,
    DELIVERY_GOOPTER_ORDER,
} from "../../constants";
import { SelectOption } from "../Form/FormInput";
import { PREFERRED_DELIVERY_METHOD_VALUES } from "../../../settings/pages/pickup-delivery/constants";

/**
 * input tsx helper files/ functions and reexport from index.tx
 *
 * useage
 *
 * from import { getAddressSelectOptions } from "~/helper/AddressSlectOptions"
 * => import { getAddressSelectOptions } from "~/helper"
 */
import { AddressLabel, formatAddress, getAddressSelectOptions } from "./AddressSlectOptions";
import { getEditModalTitleByID } from "./getEditModalById";

const intl = getIntl();

export const filterMomentDateToString = (date: [moment.Moment | null, moment.Moment | null]): [string, string] => {
    const [startDate, endDate] = date;
    const startDateStr = startDate === null ? "" : startDate?.format?.(DATE_FORMAT);
    const endDateStr = endDate === null ? "" : endDate?.format?.(DATE_FORMAT);
    return [startDateStr, endDateStr];
};

export const buildSearchRequestBody = (
    paginationsState: PaginationsType,
    currOnActiveTask: Task | null
): requsetType => {
    const { currentPage, limit, searchConditions } = paginationsState;
    const { drawerFilter, statuses, quickSearch } = searchConditions;
    const { courierName, createdDateRange, assignedDateRange, deliveredDateRange } = drawerFilter;
    const [created_start_dt, created_end_dt] = filterMomentDateToString(createdDateRange);
    const [assigned_start_dt, assigned_end_dt] = filterMomentDateToString(assignedDateRange);
    const [delivered_start_dt, delivered_end_dt] = filterMomentDateToString(deliveredDateRange);

    const request: requsetType = {
        body: {
            action: ACTIONS_MAPPING.GET_DELIVERY_LIST,
            page: currentPage,
            limit: limit,
            statuses: statuses,
            quick_search: quickSearch,
            courier_name: courierName,
            created_start_dt,
            created_end_dt,
            assigned_start_dt,
            assigned_end_dt,
            delivered_start_dt,
            delivered_end_dt,
        },
        currOnActiveTask,
    };

    return request;
};

export const getTipsOptions = (storeTipsSettings: TipsSettings, orderValue: number | null): [number[], boolean] => {
    const _PREFIX_AMOINT = "tips_amount";
    const _PREFIX_PERCENT = "tips_percent";
    const result: number[] = [];
    const isPickPercentOptions = orderValue !== null && orderValue >= storeTipsSettings.tips_order_limit;
    const tipsOptions = isPickPercentOptions ? _PREFIX_PERCENT : _PREFIX_AMOINT;
    Object.entries(storeTipsSettings).forEach(([k, v]) => {
        if (k.match?.(tipsOptions) && !_.isNaN(Number(v))) result.push(Number(v));
    });

    return [result, isPickPercentOptions];
};

export const getTipsValue = (
    orderValue: number | null | undefined,
    tipValue: number,
    isTipOnPercent: boolean
): number | null => {
    if (orderValue === null || orderValue === undefined) return null;
    return isTipOnPercent ? (orderValue * tipValue) / 100 : tipValue;
};

export const popArrayByIndex = <T>(index: number, arr: T[]): T[] => {
    try {
        if (arr.length >= index && index > -1) arr.splice(index, 1);
        return arr;
    } catch (error) {
        return arr;
    }
};

/**
 * @param {
 *  state: object;
 *  requiredFields: Paths<state>[]
 * }
 *
 * @returns {
 *  [isValid: boolean,missing field: string]
 * }
 */
export const newDeliveryTaskFormValidation = <T, K extends Paths<T>>(
    formState: T,
    requireFields: K[]
): [boolean, K | null] => {
    let result: [boolean, K | null] = [true, null];
    for (let i = 0; i < requireFields.length; i++) {
        const field = requireFields[i];
        const key = (MANDATORY_FIELDS_MAPPING[field as keyof typeof MANDATORY_FIELDS_MAPPING] ?? field) as K;
        const value = _.get(formState, key);
        const isStringEmpty = typeof value === "string" && _.isEmpty(value);
        const isNumberSmallerThanZero = typeof value === "number" && value <= 0;
        const isArrayEmpty = Array.isArray(value) && value.length <= 0;
        if (key === "zipCode") {
            if (String(formState["countryCode" as unknown as keyof T]) === "US") {
                const regEx = /^\d{5}(-\d{4})?$/i;
                if (!regEx.test(value)) return [false, key];
            } else {
                const regEx = /^[ABCEGHJ-NPRSTVXY]\d[ABCEGHJ-NPRSTV-Z][ -]?\d[ABCEGHJ-NPRSTV-Z]\d$/i;
                if (!regEx.test(value)) return [false, key];
            }
        }

        if (key === "phone") {
            const regEx = /^(\+?\d{1,3}\s)?\(?\d{3}\)?[\s.-]?\d{3}[\s.-]?\d{4}$/i;
            if (!regEx.test(value)) return [false, key];
        }

        if (key === "firstName" || key === "lastName") {
            const regEx = /^[a-zA-Z -]*$/;
            if (!regEx.test(value)) return [false, key];
        }

        if (key === "email") {
            const regEx =
                /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
            if (!regEx.test(value)) return [false, key];
        }

        if (isNumberSmallerThanZero || isStringEmpty || isArrayEmpty || !value) {
            return (result = [false, key]);
        }
    }
    return result;
};

export const getPaymentStatusByCode = (code: number | string): string => {
    return (
        PAYMENT_STATUS_CODE_MAPPING[String(code) as unknown as keyof typeof PAYMENT_STATUS_CODE_MAPPING] ?? "not_paid"
    );
};

export const getTaskStatusCategoryByCode = (code: number | undefined): string => {
    if (!code) return "cancelled";
    const [key] = Object.entries(TASK_STATUS_CODE_CATEGORY_MAPPING).find(([, v]) => {
        return v.includes?.(code);
    }) ?? ["cancelled"];
    return key;
};

export const getTaskStatusByCode = (code: number | undefined): string => {
    if (!code) return "cancelled";
    return TASK_STATUS_CODE_MAPPING[String(code) as unknown as keyof typeof TASK_STATUS_CODE_MAPPING] ?? "cancelled";
};

export const getTaskBGColor = (code: keyof typeof TASK_BG_COLOR_MAPPING): string => {
    return TASK_BG_COLOR_MAPPING[code] ?? "";
};

export const getTaskTextColor = (code: keyof typeof TASK_TEXT_COLOR_MAPPING): string => {
    return TASK_TEXT_COLOR_MAPPING[code] ?? "";
};

export const getDasherMessage = (statusCode: number): string => {
    return TASK_STAUS_DESC_MAPPING[statusCode as keyof typeof TASK_STAUS_DESC_MAPPING] ?? TASK_STAUS_DESC_MAPPING[100];
};

export const getDasherContent = (statusCode: number | undefined, phone: string | undefined | null): string => {
    const curr = getTaskStatusCategoryByCode(statusCode);
    if (curr === TASK_STATUS_CODE_MAPPING["100"] || curr === TASK_STATUS_CODE_MAPPING["280"]) {
        return "";
    }
    return _.isEmpty(phone) ? intl.formatMessage({ id: "assigning_driver" }) : phone;
};

export const getTime = (time: string | null | undefined, format: CalendarSpec, showNAwhenASAP?: boolean): string => {
    if (!time) return "";
    if (time?.toUpperCase?.()?.includes("ASAP") || time?.match("2000-01-01 00:00:00"))
        return showNAwhenASAP ? "N/A" : "ASAP";
    if (time?.toUpperCase?.()?.includes("N/A")) return time;
    const _f: MomentInput =
        time?.toUpperCase?.()?.includes("AM") || time?.toUpperCase?.()?.includes("PM")
            ? "YYYY-MM-DD hh:mma"
            : undefined;
    const mo = moment(time, _f).calendar(null, format);
    return mo === "Invalid date" ? "N/A" : mo;
};

export const getStatusContainerStyle = (task: Task): { color: string; backgroundColor: string } => {
    return {
        backgroundColor: getTaskBGColor(getTaskStatusCategoryByCode(task.status) as keyof typeof TASK_BG_COLOR_MAPPING),
        color: getTaskTextColor(getTaskStatusCategoryByCode(task.status) as keyof typeof TASK_TEXT_COLOR_MAPPING),
    };
};

/**
 * @param {
 *  itemsList{name : string, amount : number, quantity : number}[]
 * }
 * @param {
 *  orderTaxPercent: number | null;
 * }
 * @if the sum of items list's amount * quantity = 0, total -> defaultValue
 * @retuns {
 *  [orderValue : number, taxAmount :number, totalAmount: number]
 * }
 */
export const getOrderValues = (
    itemsList: Item[] = [],
    orderTaxPercent: number | null
): [number | null, number | null, number | null] => {
    if (!orderTaxPercent) orderTaxPercent = 0;

    const initial = 0;
    const _subTotal = itemsList.reduce((sum, item) => {
        return (sum += item.price * item.quantity);
    }, initial);
    const subTotal = _subTotal > 0 ? _subTotal : null;
    const taxAmount = Number(subTotal) * orderTaxPercent <= 0 ? null : Number(subTotal) * orderTaxPercent;
    const total = Number(subTotal) + Number(taxAmount) <= 0 ? null : Number(subTotal) + Number(taxAmount);
    return [subTotal, taxAmount, total];
};

export const buildUpdatedOnActivedTask = (
    currTask: ActivedTask | null,
    key: UpdateTaskPath | typeof ADD_NEW_ITEM_KEY,
    // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
    value?: any
): ActivedTask | null => {
    if (currTask) {
        const result = _.cloneDeep(currTask);
        let path: string[] = [];
        if (key.match("additionalInfo.itemsInfo")) {
            const [, , itemIndex, prototype, optionIndex] = key.split(".");
            path = ["items", itemIndex, prototype === "amount" ? "price" : prototype];
            if (optionIndex) path.push(optionIndex);
            else value = [...result.items[itemIndex as unknown as number].options, value];
        } else if (key.match("additionalInfo.itemsCategory")) {
            path = ["item_category"];
        } else if (key.match(ADD_NEW_ITEM_KEY)) {
            result.items.push(EMPTY_ITEM);
        } else if (key.match("additionalInfo.orderValue")) {
            path = ["order_value"];
        } else if (key.match("additionalInfo.tipAmount")) {
            path = ["tip"];
        } else if (key.match("additionalInfo.orderTax")) {
            path = ["tax"];
        } else if (key.match("additionalInfo.subTotal")) {
            path = ["sub_total"];
        } else if (key.match("orderId")) {
            path = ["order_number"];
        } else if (key.match("pickUp.time") || key.match("pickUp.date")) {
            path = ["pickup_window_start_time"];
        } else if (key.match("dropOff.time") || key.match("dropOff.date")) {
            path = ["delivery_window_start_time"];
        }

        return _.set(result, path, value);
    }
    return null;
};

export const getAddress = (address: Address | null | undefined): string => {
    if (address) {
        const addressLine1 = `${address.street},`;
        const addressLine2 = `${address.city}, ${address.region}, ${address.zip_code}`;
        const fullAddress = addressLine1 + "\n" + addressLine2;
        return fullAddress;
    }
    return "";
};

/**
 * @dispatch
 */
export const getDeliveryFee =
    (newTaskState: NewTaskForm) =>
    async (dispatch: ThunkDispatch<RootState, void, AnyAction>): Promise<void> => {
        const { isPickUpAddress, address, pickUp, dropOff, additionalInfo } = newTaskState;
        const { orderValue } = additionalInfo;
        const [pickup_window_start_time, pickup_window_end_time] = timeSeperator(pickUp.date, pickUp.time);
        const [delivery_window_start_time, delivery_window_end_time] = timeSeperator(dropOff.date, dropOff.time);

        const req: GetDeliveryFee = {
            order_value: orderValue ? orderValue : undefined,
            pickup_window_start_time,
            pickup_window_end_time,
            delivery_window_start_time,
            delivery_window_end_time,
            action: ACTIONS_MAPPING.GET_DELIVERY_TASK_ESTIMATION_WITHOUT_GOOPTER_ORDER_ID,
        };
        if (isPickUpAddress) {
            req.pickup_address_id = address;
        } else {
            req.dropoff_address_id = address;
        }
        if (!_.isEmpty(address)) {
            dispatch(getDeliveryFeeEST(req));
        }
    };

export const getContainerHeight = (isCollapsed: boolean): string => {
    let temp = NAV_HEIGHT + HEADER_HEIGHT + FOOTER_HEIGHT;
    temp += isCollapsed ? FILTERS_HEIGHT + COLLAPSED_PADDING_HEIGHT : UNCOLLAPSED_PADDING_HEIGHT;
    return `calc(100vh - ${temp}px)`;
};

export const objectsToCSV = (arr: any[]): string => {
    const array = [Object.keys(arr[0])].concat(arr);
    return array
        .map((row) => {
            return Object.values(row)
                .map((value) => {
                    if (typeof value === "object")
                        return JSON.stringify(value).toString().replaceAll(/{|}|"/gi, "").replaceAll(/,/gi, " ");
                    return typeof value === "string" ? JSON.stringify(value) : value;
                })
                .toString();
        })
        .join("\n");
};

export const timeSeperator = (d: string, t: string): [string | undefined, string | undefined] => {
    const re: [string | undefined, string | undefined] = [TIME_ASAP_DATE, undefined];
    if (!_.isEmpty(t) && !t.toLowerCase().includes("asap")) {
        const times = t.split("-");
        times.forEach((time, index) => {
            if (!_.isEmpty(time) && !_.isEmpty(d)) {
                re[index] = `${d} ${time}`;
            }
        });
    }
    return re;
};

export const getNestedValueInObject = <T, K, P extends keyof T, J extends keyof T[P]>(
    obj: T,
    resultIfNull: K,
    key1: P,
    key2?: J
): string | number | K => {
    if (key2 && !_.isEmpty(key1) && obj[key1] && obj[key1][key2]) return obj[key1][key2] as unknown as string | number;
    if (key1 && obj[key1]) return obj[key1] as unknown as string | number;

    return resultIfNull ? resultIfNull : "";
};

export const getMessageModalWidth = (messageModalCurrPage: MessageModalPages): number | undefined => {
    switch (messageModalCurrPage) {
        case MessageModalPages.DELIVERY_FEE_DETAIL:
            return 350;
        case MessageModalPages.TRACK_DELIVERY_TASK:
            return 1000;
        default:
            return undefined;
    }
};

export const labelBuilder = <T, K extends keyof T>(
    state: T,
    key: K,
    subKey?: K,
    whenNull?: React.ReactNode
): React.ReactNode => {
    const d = _.isEmpty(state[key]) ? "" : state[key];
    const t = subKey && !_.isEmpty(state[subKey]) ? state[subKey] : "";
    if (!_.isEmpty(d) || !_.isEmpty(t)) return [d, t].join(" ");
    return whenNull ? whenNull : "";
};

export const getSelectionsByObject = (obj: Record<string, string>): SelectOption[] => {
    if (_.isEmpty(obj)) return [];
    const map: Map<string, SelectOption> = new Map();
    Object.entries(obj).map(([k, v], i) => {
        map.set(String(k), {
            name: String(k) + i + "key",
            value: String(v),
            label: String(k),
        });
    });
    return Array.from(map.values());
};

export const formatPrice = (v: number | string | null | undefined): number | string => {
    if (typeof v === "number") {
        return "$" + v.toFixed(2);
    }
    if (typeof v === "string") {
        if (!isNaN(Number(v))) {
            return "$" + Number(v).toFixed(2);
        }
        return v;
    }
    return "";
};

export const getMostRecentTime = (
    timesLot: Record<string, string[]>
): { date: string; time: string; isOpen: boolean } => {
    const _ASAP = { date: "", time: "ASAP", isOpen: true };

    const arr = Object.entries(timesLot);

    for (let i = 0; i < arr.length; i++) {
        const [, v] = arr[i];
        if (!_.isEmpty(v[0]) && v[0].toLowerCase().includes("asap")) return _ASAP;
    }
    return { date: "", time: "", isOpen: false };
};

export const getRequestTime = (timesLot: Record<string, string[]>, windowsStartTime: string | null): string => {
    if (!windowsStartTime) {
        const { isOpen } = getMostRecentTime(timesLot);
        if (isOpen) return "ASAP";
        else return "N/A";
    }
    return windowsStartTime;
};

export const getEtaTime = (time: string | null, status: string | number, lastUpdate: string): [string, 0 | 1 | 2] => {
    if (status == 100 || status == 280) return [getTime(lastUpdate, DUE_TIME_FORMAT), 0];

    let diff = moment(time).diff(moment(), "minutes");
    let isOverDue = false;
    if (diff <= 0) {
        diff = diff * -1;
        isOverDue = true;
    }

    if (diff > 60) {
        const mi = diff % 60;
        return [`${(diff / 60).toFixed(0)} h ${mi >= 0 ? mi : ""} m`, isOverDue ? 1 : 2];
    } else {
        return [`${diff} m`, isOverDue ? 1 : 2];
    }
};

export const getEtaLabel = (status: string | number): string => {
    switch (status) {
        case 100:
            return intl.formatMessage({ id: "status_cancelled" });
        case 280:
            return intl.formatMessage({ id: "status_delivered" });
        default:
            return intl.formatMessage({ id: "ETA" });
    }
};

export const auth = (flags: DeliveryFlags, storeThirdPartyDelivery: ThirdPartyDeliverySettings): boolean => {
    const { allow_delivery, preferred_delivery_method, enable_3rdparty_local_delivery } = flags;
    if (!Number(allow_delivery)) return false; // not allow delivery
    if (Number(preferred_delivery_method) === Number(PREFERRED_DELIVERY_METHOD_VALUES.self_local_delivery)) {
        // if prefer local delivery
        if (!Number(enable_3rdparty_local_delivery)) return false; // disabled 3 party delivery
        return !_.isEmpty(
            Object.entries(storeThirdPartyDelivery).find(([, v]) => {
                // find enabled 3 party delivery service
                return v.is_enabled;
            })
        );
    } else if (Number(preferred_delivery_method) === Number(PREFERRED_DELIVERY_METHOD_VALUES.third_party_local)) {
        // if prefer 3 party delivery
        return !_.isEmpty(
            Object.entries(storeThirdPartyDelivery).find(([, v]) => {
                // find enabled 3 party delivery service
                return v.is_enabled;
            })
        );
    }

    return true; // return true if prefer local delivery
};

export enum AddressType {
    PICKUP,
    DROPOFF,
}

export const buildOnActiveTaskState = (
    onActiveTaskState: Task,
    storeInformation: Record<string, string>
): ActivedTask => {
    const getState = (t: AddressType): AddressLabel => {
        if (onActiveTaskState) {
            const storeState: AddressLabel = {
                id: "",
                firstname: storeInformation?.store_nm_en ?? "",
                lastname: "",
                phone: storeInformation?.phone ?? "",
                street: storeInformation?.address_street ?? "",
                city: storeInformation?.address_city ?? "",
                region: storeInformation?.address_province ?? "",
                zip_code: storeInformation?.address_postal_code ?? "",
                unit: storeInformation?.address_unit ?? "",
                country_id: storeInformation?.address_country_code ?? "",
                customerId: undefined,
            };

            const { customer, dropoff_address, pickup_address, request_type } = onActiveTaskState;
            if (
                t === AddressType.PICKUP &&
                (customer?.customer_type === "pickup" || request_type == DELIVERY_GOOPTER_ORDER)
            ) {
                return { ...customer, ...pickup_address, customerId: String(customer.customer_id) };
            }
            // Use request type to classify local delivery task (2) or live order delivery (1)
            if (
                t === AddressType.DROPOFF &&
                (customer?.customer_type === "dropoff" || request_type == DELIVERY_GOOPTER_ORDER)
            )
                return { ...customer, ...dropoff_address, customerId: String(customer.customer_id) };
            return storeState;
        }
        return {
            customerId: undefined,
            id: "",
            firstname: "",
            lastname: "",
            phone: "",
            street: "",
            city: "",
            region: "",
            zip_code: "",
            delivery_option: null,
            buzz: "",
            unit: "",
            delivery_instructions: "",
            country_id: "",
        };
    };

    return {
        ...onActiveTaskState,
        pickUpAddressLabel: getState(AddressType.PICKUP),
        dropOffAddressLabel: getState(AddressType.DROPOFF),
    };
};

export const isNewTaskNoChanges = (currState: NewTasksType, defaultDeliverySettings: DoordashSetting): boolean => {
    const { default_order_value, default_item_name, default_tip_amount } = defaultDeliverySettings;

    const { additionalInfo: _curr, pickUp: _cP, dropOff: _cD, ...n } = currState;
    const { additionalInfo: _init, pickUp: _iP, dropOff: _iD, ...r } = initialNewTaskRequestState;

    const restEqual = _.isEqual(n, r);
    const orderValueEqual = _.isEqual(default_order_value, _curr.orderValue);
    const orderTipEqual = _.isEqual(default_tip_amount, _curr.tipAmount);
    const catEqual = _.isEqual(default_item_name, _curr.itemsCategory);

    return restEqual && orderValueEqual && orderTipEqual && catEqual;
    // return _.isEqual(currState, initialNewTaskRequestState);
};

export const closeDialogActions =
    (
        newTaskModalCurrPage: TaskModalPages,
        newTaskState: NewTasksType,
        onActiveTask: Task | null,
        updatedTask: Partial<NewTasksType>,
        defaultDeliverySettings: DoordashSetting
    ) =>
    (dispatch: ThunkDispatch<RootState, void, AnyAction>): void => {
        switch (newTaskModalCurrPage) {
            case TaskModalPages.EDIT_ORDER_ID:
                dispatch(setNewTaskFormState({ key: "orderId", value: "" }));
                if (_.isEqual(updatedTask.orderId, onActiveTask?.order_number)) {
                    dispatch(setShouldModalOpen(false));
                    return;
                }
                dispatch(setCurrentMessageModalPage(MessageModalPages.DISCARD_UPDATE));
                dispatch(setShouldMessageModalOpen(true));
                break;
            case TaskModalPages.EDIT_ADDRESS:
                if (_.isEmpty(updatedTask?.orderId)) {
                    dispatch(setShouldModalOpen(false));
                    return;
                }
                dispatch(setCurrentMessageModalPage(MessageModalPages.DISCARD_UPDATE));
                dispatch(setShouldMessageModalOpen(true));
                break;
            case TaskModalPages.SEARCH_CUSTOMER:
                dispatch(setCurrentModalPage(TaskModalPages.NEW_TASK_ADDRESS));
                dispatch(setInputSelectionState({ key: "searchParams", value: "" }));
                break;
            case TaskModalPages.READ_ONLY_ADDRESS:
                dispatch(setShouldModalOpen(false));
                break;
            case TaskModalPages.ADD_BALANCE:
                dispatch(setShouldModalOpen(false));
                break;
            default:
                if (isNewTaskNoChanges(newTaskState, defaultDeliverySettings)) {
                    dispatch(setShouldModalOpen(false));
                } else {
                    dispatch(setCurrentMessageModalPage(MessageModalPages.DISCARD_NEW_TASK));
                    dispatch(setShouldMessageModalOpen(true));
                }
                break;
        }
    };

export const formatDateTimeStringToObject = (
    startDT: string | null | undefined
    // endDT?: string | null | undefined
): { date: string; time: string } => {
    if (startDT?.toLowerCase?.()?.includes?.("asap") || startDT?.includes("2000-01-01 00:00:00"))
        return { date: "", time: "asap" };
    const [date, time] = startDT?.split(" ") ?? ["", "asap"];
    return { date, time } as any;
};

export const inputValidation = (regEx: RegExp, value: string, callback?: (result: boolean) => void): boolean => {
    const pass = regEx.test(value);
    callback?.(pass || _.isEmpty(value));
    return pass || _.isEmpty(value);
};

// reexport
export { getAddressSelectOptions, getEditModalTitleByID, formatAddress };
