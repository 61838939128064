import React, { Component } from "react";
import _ from "lodash";
import helper from "../../../../util/helper";
import { injectIntl, FormattedMessage } from "react-intl";
import { connect } from "react-redux";
import {
    formatOrderId,
    getDisplayTotal,
    getOrderDate,
    getOrderTypeText,
    getShortDeliveryStatus,
} from "../../../order-history/helper";
import { getStoreWithGid, isGroupedOrder } from "../../helper";
import cx from "classnames";
import moment from "moment";
import { shouldShowRed, getCaculateRemainTime } from "../../helper";
import { LIVE_ORDER_CARD_ID_PREFIX } from "../../helper";
import _actions from "../../../../slices/live-order";
import { getPaymentIcon, getOrderId } from "../../../order-history/helper-order-list";
import CopyIcon from "components/copy-icon";
import CopyArea from "components/copy-area";
import { store } from "../../../../app/store";
import config from "config";

const REMAIN_TIME_COUNTER = 60 * 1000;

class OrderList extends Component {
    state = {
        mouseOver: {},
    };

    componentDidMount() {
        this.setTimer();
        this.checkActiveIdUpdate({});
        this.checkListUpdate({});
        this.checkStyleUpdate({});
    }

    componentDidUpdate(prevsProps) {
        this.checkOrderUpdate(prevsProps);
        this.checkActiveIdUpdate(prevsProps);
        this.checkListUpdate(prevsProps);
        this.checkStyleUpdate(prevsProps);
    }

    componentWillUnmount() {
        this.clearTimer();
    }

    str = (id, values) => this.props.intl.formatMessage({ id: id ? id : " " }, values);

    getStyle = (props = this.props) => _.get(props, "state.listStyle", "list");

    getStore = () => {
        const storesData = _.get(this.props, "storesData", []);
        const orderGid = _.get(this.getOrder(), "g_id", "");
        return getStoreWithGid(orderGid, storesData);
    };

    getOrder = (props = this.props) => (props.order ? props.order : {});

    getCustomerName = () => _.get(this.getOrder(), "c_name") || _.get(this.getOrder(), "c_nm");

    getOrderId = () => formatOrderId(_.get(this.getOrder(), "ord_id"));

    getOrderType = () => getOrderTypeText(this.getOrder(), this.getStore(), this.props.lan);

    getOrderTotal = () => {
        const currency = store.getState().store?.storeCurrencyAndPricePlan?.store_currency ?? "CAD";
        const total = getDisplayTotal(this.getOrder());
        return helper.getCurrencyFormattedString(total, currency, "", false);
    };

    getTime = () => {
        const requstedTime = _.get(this.getOrder(), "exp_dt_start", "'2000-01-01 00:00:00'");
        const eta = _.get(this.getOrder(), "est_dt", "");
        return moment(eta).isValid() ? eta : requstedTime;
    };

    getTimeStr = () => {
        const requstedTime = _.get(this.getOrder(), "exp_dt_start", "'2000-01-01 00:00:00'");
        const eta = _.get(this.getOrder(), "est_dt", "");
        const format = {
            type: "shortest_no_year",
            local: false,
            asap: true,
            timeFormat: "hh:mmA",
        };
        const lan = this.props.lan;
        const store = this.getStore();
        if (moment(eta).isValid()) {
            return getCaculateRemainTime(eta, this.getOrder(), store, true, lan)
                ? getCaculateRemainTime(eta, this.getOrder(), store, true, lan)
                : helper.utcToLocal(eta, format);
        }
        if (requstedTime) {
            return getCaculateRemainTime(requstedTime, this.getOrder(), store, true, lan)
                ? getCaculateRemainTime(requstedTime, this.getOrder(), store, true, lan)
                : helper.utcToLocal(requstedTime, format);
        }
    };

    getTimeLabel = () => {
        const eta = _.get(this.getOrder(), "est_dt", "");
        if (moment(eta).isValid()) {
            return "due_time";
        }
        return "req_time_short";
    };

    getCurrentActiveOrder = () => _.get(this.props, "state.activeId");

    getOrderStatus = () => {
        return getShortDeliveryStatus(this.getOrder(), this.getStore());
    };

    get3rdPartyCourierName = () => {
        const thirdPartyCourierName = _.get(this.getOrder(), "delivery_partner_name", null);
        return thirdPartyCourierName ? <FormattedMessage id={thirdPartyCourierName} /> : "";
    };

    getCourierStatusCode = () => {
        const courierStatusCode = _.get(this.getOrder(), "courier_status", null);
        return courierStatusCode;
    };

    isActive = () => this.getCurrentActiveOrder() === _.get(this.getOrder(), "ord_id");

    isGroupedOrder = () => isGroupedOrder(this.getOrder());

    setTimer = () => {
        this.clearTimer();
        this.updateTimeStr();
        this.timer = setInterval(this.updateTimeStr, REMAIN_TIME_COUNTER);
    };

    clearTimer = () => {
        if (this.timer) clearInterval(this.timer);
    };

    checkOrderUpdate = (prevsProps) => {
        const orderUpdate = _.isEqual(this.getOrder(), this.getOrder(prevsProps)) && _.isEmpty(this.getOrder());
        if (orderUpdate) this.setTimer();
    };

    updateTimeStr = () => {
        const store = this.getStore();
        const shouldTimeRed = shouldShowRed(this.getTime(), store);
        const timeStr = this.getTimeStr();
        this.setState({ shouldTimeRed, timeStr });
    };

    onItemClick = () => {
        const orderId = _.get(this.getOrder(), "ord_id");
        const getController = () => _.get(this.props, `state.expansionControllers.${orderId}`, {});
        let expansionControllers = _.cloneDeep(_.get(this.props, "state.expansionControllers", {}));
        let controller = _.cloneDeep(getController());
        controller.showDetail = true;
        expansionControllers[orderId] = controller;
        this.props.setState({
            expansionControllers: expansionControllers,
            activeId: orderId,
        });
    };

    getActiveId = (props = this.props) => {
        return _.get(props, "state.activeId", "");
    };

    getSortedList = (props = this.props) => {
        return _.get(props, "state.groupedLiveOrderList", {});
    };

    checkListUpdate = (prevsProps) => {
        const update =
            !_.isEqual(this.getSortedList(prevsProps), this.getSortedList()) && !_.isEmpty(this.getSortedList());
        if (update) {
            this.scrollToAciveId();
            this.setTimer();
        }
    };

    checkActiveIdUpdate = (prevsProps) => {
        const update = this.getActiveId(prevsProps) !== this.getActiveId() && this.getActiveId();
        if (update) this.scrollToAciveId();
    };

    checkStyleUpdate = (prevsProps) => {
        const update = this.getStyle(prevsProps) !== this.getStyle() && this.getStyle();
        if (update) this.scrollToAciveId();
    };

    scrollToAciveId = () => {
        const orderId = this.getActiveId();
        const ref = this[`${LIVE_ORDER_CARD_ID_PREFIX}${orderId}`];
        if (ref) {
            ref.scrollIntoView({
                block: "center",
            });
        }
    };

    handleMouseEnter = (element) => {
        this.setState({
            mouseOver: {
                [element]: true,
            },
        });
    };

    handleMouseLeave = (element) => {
        this.setState({
            mouseOver: {
                [element]: false,
            },
        });
    };

    renderLeftSection = () => {
        const renderFirstLine = () => {
            return (
                <div className="list-item-customer-name">
                    <CopyArea
                        copyData={this.getCustomerName()}
                        messageId="customer_name"
                        iconPosition="start"
                        className="recent-order-first-row"
                    >
                        {this.getCustomerName()}
                    </CopyArea>
                </div>
            );
        };

        const render3rdPartyDeliveryStatus = () => {
            const courierStatusCode = this.getCourierStatusCode();
            const shouldTextRed =
                courierStatusCode ==
                    config.ORDER_DELIVERY_STATUS_MAPPING_TO_NUMERIC["delivery_task_cancelled_by_merchant"] ||
                courierStatusCode ==
                    config.ORDER_DELIVERY_STATUS_MAPPING_TO_NUMERIC[
                        "delivery_task_cancelled_by_3rd_party_delivery_partner"
                    ];
            if (courierStatusCode) {
                return (
                    <div
                        className={cx({
                            "list-item-order-info": true,
                            "red": shouldTextRed,
                        })}
                    >
                        <FormattedMessage id={`${config.ORDER_DELIVERY_STATUS_MAPPING[courierStatusCode]}`} />
                    </div>
                );
            } else {
                return null;
            }
        };

        const renderSecondLine = () => {
            return (
                <div className="list-item-order-info">
                    {this.isGroupedOrder() ? null : (
                        <span
                            onMouseEnter={() => this.handleMouseEnter(`#${this.getOrderId()}`)}
                            onMouseLeave={() => this.handleMouseLeave(`#${this.getOrderId()}`)}
                            className="list-item-order-marginleft"
                        >
                            <CopyIcon
                                show={this.state.mouseOver[`#${this.getOrderId()}`]}
                                copyData={getOrderId(this.getOrder())}
                                messageId="order_id"
                            />
                            #{this.getOrderId()} <b>&nbsp;·&nbsp;</b>
                        </span>
                    )}
                    <span className="list-item-order-info-type">{this.getOrderType()}</span>
                    <b>&nbsp;·&nbsp;</b>
                    {helper.isPPCP3dsOrder(this.getOrder()) ? <div id="lock-icon">&#128274;</div> : null}
                    <img
                        alt="payment-method"
                        src={getPaymentIcon(this.getOrder())}
                        className="live-order-list-payment-method"
                    />
                    &nbsp;
                    {this.getOrderTotal()}
                </div>
            );
        };

        const renderThirdRow = () => {
            const orderIds = Array.isArray(_.get(this.getOrder(), "orderIds"))
                ? _.get(this.getOrder(), "orderIds")
                : [];
            let idsStr = "";
            orderIds.forEach((id) => {
                const idStr = idsStr ? ` · #${formatOrderId(id)}` : `#${formatOrderId(id)}`;
                idsStr += idStr;
            });
            if (this.isGroupedOrder()) return <div className="list-item-order-info">{idsStr}</div>;
        };

        const renderFourthRow = () => {
            return (
                <div className="list-item-order-info">
                    {this.str("order_time")}: &nbsp;
                    {getOrderDate(this.getOrder(), this.getStore())}
                </div>
            );
        };

        return (
            <div className="draw-list-item-right-section">
                {renderFirstLine()}
                {render3rdPartyDeliveryStatus()}
                {renderSecondLine()}
                {renderThirdRow()}
                {renderFourthRow()}
            </div>
        );
    };

    renderRightSection = () => {
        const timeStr = this.state.timeStr ? this.state.timeStr : this.getTimeStr();
        const store = this.getStore();
        const shouldTimeRed = this.state.shouldTimeRed
            ? this.state.shouldTimeRed
            : shouldShowRed(this.getTime(), store);
        const status = () => {
            const orderStatus = this.getOrderStatus();
            const isCourierOrderStatusAfterPickup =
                orderStatus == "picked_up_package" ||
                orderStatus == "enroute_to_dropoff" ||
                orderStatus == "arrived_at_dropoff";
            return (
                <div
                    className={cx({
                        "status-text": true,
                        [`${orderStatus}-status-text`]: true,
                        "red": isCourierOrderStatusAfterPickup && shouldTimeRed,
                    })}
                >
                    <FormattedMessage id={`status_${orderStatus}`} />
                </div>
            );
        };
        const time = () => (
            <div
                className={cx({
                    "time-text": true,
                    "red": shouldTimeRed,
                })}
            >
                {timeStr}
            </div>
        );
        const timeLabel = () => <div className="time-label-text">{<FormattedMessage id={this.getTimeLabel()} />}</div>;
        return (
            <div className="draw-list-item-left-section">
                {status()}
                {timeStr ? (
                    <>
                        {time()}
                        {timeLabel()}
                    </>
                ) : null}
            </div>
        );
    };

    render() {
        return (
            <div
                ref={(el) => (this[`${LIVE_ORDER_CARD_ID_PREFIX}${_.get(this.getOrder(), "ord_id")}`] = el)}
                onClick={this.onItemClick}
                className={cx({
                    "draw-list-item": true,
                    "draw-list-item-active": this.isActive(),
                    "draw-list-item-grouped": this.isGroupedOrder(),
                    "draw-list-item-grouped-active": this.isGroupedOrder() && this.isActive(),
                })}
            >
                {this.renderLeftSection()}
                {this.renderRightSection()}
            </div>
        );
    }
}

const mapStateToProps = (state) => ({
    lan: _.get(state, "setting.lan", "en"),
    state: _.get(state, "liveOrders", {}),
    storesData: _.get(state, "managedStores.activeStores", []),
});

const mapDispatchToProps = {
    setState: _actions.setState,
};

export default injectIntl(connect(mapStateToProps, mapDispatchToProps)(OrderList));
