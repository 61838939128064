import React, { Component } from "react";
import { connect } from "react-redux";
import _ from "lodash";
import { FormattedMessage, injectIntl } from "react-intl";
import { Button, withStyles } from "@material-ui/core";
import { Checkbox, Modal } from "antd";
import "./bulk-update-controller.scss";
import { ORDER_DETAIL_ACTIONS, allowBulkUpdate, getDefaultETA } from "../../../helper";
import actions from "../../../../../slices/order-history";
import liveOrderActions from "../../../../../slices/live-order";
import { getStoreWithGid } from "../../../../liveorder/helper";
import ProcessingModal from "../../../../liveorder/_components/processing-modal";
import BulkProcessingModal, { BULK_UPDATE_STATUES } from "./bulk-update-process-modal";

//blue not filled
const StyledButton1 = withStyles({
    root: {
        lineHeight: 1.3,
        fontSize: 16,
        paddingLeft: 10,
        paddingRight: 10,
        textTransform: "initial",
        borderColor: "#1890ff",
        color: "#1890ff",
        borderRadius: 3,
        "&:hover": {
            color: "#016cd0",
            borderColor: "#016cd0",
            boxShadow: "none",
        },
        "&:active": {
            boxShadow: "none",
            color: "#016cd0",
            borderColor: "#016cd0",
        },
    },
})(Button);

//blue filled
const StyledButton2 = withStyles({
    root: {
        lineHeight: 1.3,
        fontSize: 16,
        paddingLeft: 5,
        paddingRight: 5,
        textTransform: "initial",
        backgroundColor: "#1890ff",
        borderColor: "#1890ff",
        color: "white",
        "&:hover": {
            backgroundColor: "#016cd0",
            borderColor: "#016cd0",
            boxShadow: "none",
        },
        "&:active": {
            boxShadow: "none",
            backgroundColor: "#016cd0",
            borderColor: "#016cd0",
        },
    },
})(Button);

//red filled
const StyledButton3 = withStyles({
    root: {
        lineHeight: 1.3,
        fontSize: 16,
        paddingLeft: 5,
        paddingRight: 5,
        textTransform: "initial",
        backgroundColor: "#ff4d4f",
        borderColor: "#ff4d4f",
        color: "white",
        "&:hover": {
            backgroundColor: "#ff4d4f",
            borderColor: "#0062cc",
            boxShadow: "none",
        },
        "&:active": {
            boxShadow: "none",
            backgroundColor: "#ff4d4f",
            borderColor: "#005cbf",
        },
    },
})(Button);

class BulkUpdateController extends Component {
    state = {};

    str = (values) => this.props.intl.formatMessage(values);

    getSelectedOrders = () => {
        return Array.isArray(_.get(this.props, "state.selectedOrders"))
            ? _.get(this.props, "state.selectedOrders")
            : [];
    };

    getOrderList = () => {
        return Array.isArray(_.get(this.props, "state.orderList")) ? _.get(this.props, "state.orderList") : [];
    };

    getOrderStore = (order) => {
        const orderGid = _.get(order, "g_id", "");
        const storesData = _.get(this.props, "storesData", []);
        return getStoreWithGid(orderGid, storesData);
    };

    onAcceptClick = () => {
        const selectedOrders = this.getSelectedOrders();
        if (_.isEmpty(selectedOrders)) return;
        this.setState({ showProccess: true });
    };

    onCompleteClick = () => {
        const selectedOrders = this.getSelectedOrders();
        if (_.isEmpty(selectedOrders)) return;
        const updateLiveOrder = () => this.updateOrders(ORDER_DETAIL_ACTIONS.to_complete);
        Modal.confirm({
            title: this.str({ id: "warning" }),
            content: this.str({ id: "mark_as_complete_message" }),
            okText: <b>{this.str({ id: "ok" })}</b>,
            cancelText: <b>{this.str({ id: "cancel" })}</b>,
            onOk() {
                updateLiveOrder();
            },
        });
    };

    onRejectClick = () => {
        const selectedOrders = this.getSelectedOrders();
        if (_.isEmpty(selectedOrders)) return;
        const updateLiveOrder = () => this.updateOrders(ORDER_DETAIL_ACTIONS.to_cancel);
        Modal.confirm({
            title: this.str({ id: "warning" }),
            content: this.str({ id: "cancel_order_message" }),
            okText: <b>{this.str({ id: "ok" })}</b>,
            cancelText: <b>{this.str({ id: "cancel" })}</b>,
            onOk() {
                updateLiveOrder();
            },
        });
    };

    updateOrders = (action, post = {}) => {
        this.setState({
            showProcessingDialog: true,
            updateStatus: BULK_UPDATE_STATUES.updating,
            postData: {
                action: action,
                ...post,
            },
        });
    };

    renderCheckBox = () => {
        const bulkUpdateIds = this.getOrderList().reduce((acc, order) => {
            if (allowBulkUpdate(order)) acc.push(order);
            return acc;
        }, []);
        const checked = bulkUpdateIds.length && this.getSelectedOrders().length === bulkUpdateIds.length;
        const onChange = () => {
            const result = checked ? [] : bulkUpdateIds;
            this.props.setState({
                selectedOrders: result,
            });
        };
        return (
            <div className="order-list-bulk-update-check-box">
                <Checkbox disabled={!bulkUpdateIds.length} onChange={() => onChange()} checked={checked}>
                    <span className="order-list-bulk-update-text">
                        <FormattedMessage id="all" />
                    </span>
                    <span className="order-list-bulk-update-text">({bulkUpdateIds.length})</span>
                </Checkbox>
            </div>
        );
    };

    renderDivider = () => {
        return <div className="order-list-bulk-update-vertical-divider" />;
    };

    renderAcceptButton = () => {
        //disable when a accepted order is clicked
        const disabled = !_.isEmpty(this.getSelectedOrders().find((order) => _.get(order, "est_dt")));
        return (
            <StyledButton1
                onClick={() => this.onAcceptClick()}
                variant="outlined"
                className="order-list-bulk-update-button"
                color="secondary"
                disabled={disabled}
            >
                <FormattedMessage id="accept" />
            </StyledButton1>
        );
    };

    renderCompleteButton = () => {
        return (
            <StyledButton2
                onClick={() => this.onCompleteClick()}
                variant="outlined"
                className="order-list-bulk-update-button"
                color="secondary"
            >
                <FormattedMessage id="complete" />
            </StyledButton2>
        );
    };

    renderRejectButton = () => {
        return (
            <StyledButton3
                onClick={() => this.onRejectClick()}
                variant="outlined"
                className="order-list-bulk-update-button"
                color="secondary"
            >
                <FormattedMessage id="reject" />
            </StyledButton3>
        );
    };

    renderSelectedSection = () => {
        return (
            <div className="order-list-bulk-update-text">
                <FormattedMessage id="select_x_items" values={{ x: this.getSelectedOrders().length }} />
            </div>
        );
    };

    renderDoneUpdating = () => {
        return (
            <StyledButton1
                variant="outlined"
                className="order-list-bulk-done-update-button order-list-bulk-update-button"
                color="secondary"
                onClick={() => this.props.setState({ bulkUpdating: false, selectedOrders: [] })}
            >
                <FormattedMessage id="done" />
            </StyledButton1>
        );
    };

    renderProcessingModal = () => {
        const showModal = this.state.showProccess;
        const sortOrders = _.sortBy(this.getSelectedOrders(), (order) =>
            getDefaultETA(order, this.getOrderStore(order)).valueOf()
        );
        const pickedOrder = _.get(sortOrders, 0, {});
        const estimatedDeliveryFee = this.props?.store?.doordashDeliveryDetails?.estDeliveryFee;
        return (
            <ProcessingModal
                onCloseClick={() => {
                    this.setState({ showProccess: false });
                }}
                estimatedDeliveryFee={estimatedDeliveryFee}
                bulkUpdate={true}
                displayModal={showModal}
                orderStore={this.getOrderStore(pickedOrder)}
                order={pickedOrder}
                onSubmitClick={(values) => {
                    this.updateOrders(ORDER_DETAIL_ACTIONS.to_processing, values);
                }}
            />
        );
    };

    renderBulkProcssingModal = () => {
        return <BulkProcessingModal state={this.state} setState={(values) => this.setState(values)} />;
    };

    render() {
        return (
            <>
                <div className="order-list-bulk-update-controller">
                    <div className="order-list-bulk-update-controller-start-section">
                        {this.renderCheckBox()}
                        {this.renderDivider()}
                        {this.renderAcceptButton()}
                        {this.renderCompleteButton()}
                        {this.renderRejectButton()}
                        {this.renderSelectedSection()}
                    </div>
                    {this.renderDoneUpdating()}
                </div>
                {this.renderProcessingModal()}
                {this.renderBulkProcssingModal()}
            </>
        );
    }
}

const mapStateToProps = (state) => ({
    lan: _.get(state, "settings.lan", "en"),
    state: _.get(state, "orders-page", {}),
    storesData: _.get(state, "managedStores.activeStores", []),
});

const mapDispatchToProps = {
    setState: actions.setState,
    updateLiveOrder: liveOrderActions.updateOrder,
};

export default connect(mapStateToProps, mapDispatchToProps)(injectIntl(BulkUpdateController));
