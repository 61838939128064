import { Button, withStyles } from "@material-ui/core";
import { Button as AntdButton, DatePicker, Modal, TimePicker, Spin } from "antd";
import _ from "lodash";
import moment from "moment";
import { getCommuteTime, getDefaultETA } from "pages/order-history/helper";
import { Component } from "react";
import { FiRefreshCcw } from "react-icons/fi";
import { IoIosRemove, IoMdAdd } from "react-icons/io";
import { VscCalendar } from "react-icons/vsc";
import { FormattedMessage, injectIntl } from "react-intl";
import { connect } from "react-redux";
import _actions from "slices/live-order";
import helper from "util/helper";
import { setDoordashDeliveryDetails } from "../../../../../src/slices/store";
import {
    createDoordashDelivery,
    fetchDeliveryFeeEstimate,
    createDoordashDeliveryOnAcceptedOrder,
} from "../../../../services/store";
import "./_delivery-modal.scss";
import { HALF_SECOND_IN_MS } from "app/constants";

const DATE_FORMAT = "YYYY-MM-DD";
const DIPLAY_DATE_FORMAT = "ddd MMM DD";
const TIME_FORMAT = "h:mm A";
const DATE_TIME_FORMAT = "YYYY-MM-DD HH:mm:ss";

const ETA_DEFAULT_ADD_MINS = 15;
const ETA_ADD_MINUS_STEP = 5;

const DELIVERY_TIME_FORMAT = "h:mm A, ddd MMM DD";

const StyledButton = withStyles({
    root: {
        lineHeight: 1.3,
        fontSize: 17,
        paddingLeft: 10,
        paddingRight: 10,
        textTransform: "initial",
        border: "1px #2196f3 solid",
        minWidth: "initial",
        maxWidth: "initial",
        color: "#2196f3",
        "&:hover": {
            backgroundColor: "#1769aa",
            borderColor: "#1769aa",
            boxShadow: "none",
            color: "white",
        },
        "&:active": {
            boxShadow: "none",
            backgroundColor: "#1769aa",
            borderColor: "#1769aa",
            color: "white",
        },
    },
})(Button);

const StyledActiveButton = withStyles({
    root: {
        lineHeight: 1.3,
        fontSize: 17,
        textTransform: "initial",
        borderColor: "#2196f3",
        color: "white",
        backgroundColor: "#2196f3",
        "&:hover": {
            backgroundColor: "#1769aa",
            borderColor: "#1769aa",
            boxShadow: "none",
            color: "white",
        },
        "&:active": {
            boxShadow: "none",
            backgroundColor: "#1769aa",
            borderColor: "#1769aa",
            color: "white",
        },
    },
})(Button);

class DoordashModal extends Component {
    getOrderId = () => _.get(this.props.order, "ord_id", "");
    isDoordashEnabled = () => !!this.props.thirdPartyDelivery.doordash.is_enabled;

    componentDidMount() {
        this.props.setDoordashDeliveryDetails({ name: "doordashCreated", value: false });
    }

    componentDidUpdate(prevProps, prevState) {
        const isModalTurnOn = !prevProps.showModal && this.props.showModal === true;
        const isPickUpDateChanged = prevState.dateString !== this.state.dateString;
        const isPickUpTimeChanged = prevState.timeString !== this.state.timeString;

        if (isModalTurnOn || isPickUpDateChanged || isPickUpTimeChanged) {
            if (!this.state.fetchEstimatedDelivery) {
                this.setState({ fetchEstimatedDelivery: true });
                this.props.fetchDeliveryFeeEstimate({ est_dt: this.getETA(), order_id: this.props.order?.ord_id });
                setTimeout(() => this.unlock(), HALF_SECOND_IN_MS);
            }
        }
    }

    state = {
        openDatePicker: false,
    };

    str = (id, values) => this.props.intl.formatMessage({ id }, values);

    getDateString = () => {
        const orderDefaultString = getDefaultETA(this.props.order, this.props.orderStore).format(DATE_FORMAT);
        return this.state.dateString || orderDefaultString;
    };

    getTimeString = () => {
        const orderDefaultString = getDefaultETA(this.props.order, this.props.orderStore).format(TIME_FORMAT);
        return this.state.timeString || orderDefaultString;
    };

    getCommuteTime = () => {
        return this.state.commuteTime || getCommuteTime(this.props.order);
    };

    getETA = () => {
        const dateString = this.getDateString();
        const timeString = this.getTimeString();
        return moment(`${dateString} ${timeString}`, `${DATE_FORMAT} ${TIME_FORMAT}`).format(DATE_TIME_FORMAT);
    };

    onCloseClick = () => {
        if (this.props.onCloseClick) this.props.onCloseClick();
        this.props.setState({
            activeId: this.getOrderId(),
            modelController: "",
        });
        this.props.setDoordashDeliveryDetails({ name: "estDeliveryFee", value: -1 });
        this.props.setDoordashDeliveryDetails({ name: "showInsufficientFunds", value: false });
    };

    renderETADateTime = () => {
        const getETADisplay = () => {
            const formats = {
                nextDay: `[${this.str("tomorrow")},] ${DIPLAY_DATE_FORMAT}`,
                sameDay: `[${this.str("today")},] ${DIPLAY_DATE_FORMAT}`,
                lastDay: `[${this.str("yesterday")},] ${DIPLAY_DATE_FORMAT}`,
                nextWeek: DIPLAY_DATE_FORMAT,
                lastWeek: DIPLAY_DATE_FORMAT,
                sameElse: DIPLAY_DATE_FORMAT,
            };
            return moment(this.getDateString(), DATE_FORMAT).calendar(null, formats);
        };

        const renderTitle = () => {
            return (
                <div className="eta-date-title-wrapper">
                    <FormattedMessage id={"estimated_ready_pickup_time"} />
                </div>
            );
        };

        const renderDate = () => (
            <div className="eta-date-text-wrapper">
                <div className="eta-date-text">
                    <span
                        id="eta-date-display-text"
                        className="eta-date-display-text"
                        onClick={() => {
                            this.setState({ openDatePicker: true });
                        }}
                    >
                        <div className="eta-date-icon mr-2">
                            <VscCalendar />
                        </div>
                        {getETADisplay()}
                    </span>
                    <span className="eta-date-time-picker">
                        <DatePicker
                            allowClear={false}
                            open={this.state.openDatePicker}
                            onOpenChange={(open) => {
                                this.setState({ openDatePicker: open });
                            }}
                            value={moment(this.getDateString(), DATE_FORMAT)}
                            disabledDate={(current) => {
                                return current < moment().startOf("day");
                            }}
                            defaultValue={moment()}
                            onChange={(date, dateString) => this.setState({ dateString: dateString })}
                            format={DATE_FORMAT}
                        />
                    </span>
                </div>
                <div className="eta-date-empty"></div>
            </div>
        );

        const renderTime = () => {
            const currentTime = this.getTimeString();
            const onChange = (add = true) => {
                const newTime = moment(currentTime, TIME_FORMAT)
                    .add(add ? ETA_ADD_MINUS_STEP : -ETA_ADD_MINUS_STEP, "minutes")
                    .format(TIME_FORMAT);
                this.setState({ timeString: newTime });
            };
            return (
                <div className="eta-time-text-wrapper">
                    <div className="eta-selection-item-button eta-removeProduct" onClick={() => onChange(false)}>
                        <IoIosRemove />
                    </div>
                    <div className="eta-time-text">
                        <TimePicker
                            allowClear={false}
                            value={moment(this.getTimeString(), TIME_FORMAT)}
                            defaultValue={moment().add(ETA_DEFAULT_ADD_MINS, "minutes")}
                            use12Hours
                            format={TIME_FORMAT}
                            onChange={(time, timeString) => this.setState({ timeString: timeString })}
                        />
                    </div>
                    <div className="eta-selection-item-button eta-addProduct" onClick={() => onChange(true)}>
                        <IoMdAdd />
                    </div>
                </div>
            );
        };

        return (
            <div>
                {renderTitle()}
                {renderDate()}
                {renderTime()}
            </div>
        );
    };

    renderETAAddTimeButtonGroup = () => {
        //diff Mins
        const now = moment();
        const eta = moment(this.getTimeString(), TIME_FORMAT);
        const duration = moment.duration(eta.diff(now));
        const diffMins = parseInt(duration.asMinutes());

        const data = [
            {
                value: 0,
                label_time: "now",
            },
            {
                value: 15,
                label_time: "mins",
                label_value: 15,
            },
            {
                value: 30,
                label_time: "mins",
                label_value: 30,
            },
            {
                value: 45,
                label_time: "mins",
                label_value: 45,
            },
            {
                value: 60,
                label_time: "hours",
                label_value: 1,
            },
            {
                value: 90,
                label_time: "hours",
                label_value: 1.5,
            },
            {
                value: 120,
                label_time: "hours",
                label_value: 2,
            },
        ];

        const renderNowLabel = () => {
            return (
                <div className="eta-add-time-buttons-now-label">
                    <FormattedMessage id="now" />
                </div>
            );
        };

        const renderOtherTimeLabel = (button) => {
            return (
                <div className="eta-add-time-buttons-other-time-label">
                    <div className="eta-add-time-buttons-other-time-label-value">+{button.label_value}</div>
                    <div className="eta-add-time-buttons-other-time-label-time-text">
                        <FormattedMessage id={button.label_time} />
                    </div>
                </div>
            );
        };

        return (
            <div className="eta-add-time-buttons-group-wrapper">
                {data.map((button, i) => {
                    const isSelected = diffMins < button.value + 1 && button.value - 2 < diffMins;
                    const ButtonComponent = isSelected ? StyledActiveButton : StyledButton;
                    return (
                        <ButtonComponent
                            key={`eta-add-time-button-${button.value}-${i}`}
                            onClick={() => {
                                const newTime =
                                    button.label_time === "now"
                                        ? moment()
                                        : moment(
                                              `${this.getDateString()}${this.getTimeString()}`,
                                              `${DATE_FORMAT}${TIME_FORMAT}`
                                          ).add(button.value, "minutes");
                                this.setState({
                                    dateString: newTime.format(DATE_FORMAT),
                                    timeString: newTime.format(TIME_FORMAT),
                                });
                            }}
                            color="primary"
                        >
                            {button.label_time === "now" ? renderNowLabel() : renderOtherTimeLabel(button)}
                        </ButtonComponent>
                    );
                })}
            </div>
        );
    };

    renderDeliveryTime = () => {
        if (helper.isDelivery(this.props.shippingMethod))
            return (
                <div className="delivery-time-wrapper">
                    <div className="delivery-time-title ">
                        <FormattedMessage id="estimated_delivery_time" />
                    </div>
                    <div className="delivery-time-content">
                        {moment(this.getETA()).add(this.getCommuteTime(), "minutes").format(DELIVERY_TIME_FORMAT)}
                    </div>
                </div>
            );
    };

    renderConfirmationFooter = () => {
        return (
            <div className="footer-confirm-button">
                <AntdButton
                    size="large"
                    type="primary"
                    style={{ justifyContent: "center", width: "200px" }}
                    onClick={() => {
                        this.props.setDoordashDeliveryDetails({ name: "doordashCreated", value: false });
                        this.props.setDoordashDeliveryDetails({ name: "doordashCreatedFail", value: false });
                        this.props.setDoordashDeliveryDetails({ name: "showInsufficientFunds", value: false });
                    }}
                >
                    <b>
                        <FormattedMessage id="ok" />
                    </b>
                </AntdButton>
            </div>
        );
    };

    createDelivery = () => {
        const time = this.getETA();
        this.props.createDoordashDeliveryOnAcceptedOrder({ order_id: this.props.order.ord_id, est_dt: time });
        this.onCloseClick();
    };

    renderFooter = () => {
        return (
            <div className="eta-dialog-buttons">
                <div className="eta-dialog-button">
                    <AntdButton size="large" onClick={() => this.onCloseClick()}>
                        <b>
                            <FormattedMessage id="cancel" />
                        </b>
                    </AntdButton>
                </div>
                <div className="eta-dialog-button">
                    <AntdButton
                        size="large"
                        type="primary"
                        onClick={() => {
                            this.createDelivery();
                        }}
                    >
                        <b>
                            <FormattedMessage id="confirm" />
                        </b>
                    </AntdButton>
                </div>
            </div>
        );
    };

    unlock = () => {
        this.setState({ fetchEstimatedDelivery: false });
    };
    renderEstimatedDeliveryCode = () => {
        const fullDate = this.props.doordashDeliveryDetails?.estDeliveryTime;
        const dateString = fullDate?.slice?.(0, 10);
        const timeString = fullDate?.slice?.(11, 13) + ":" + fullDate?.slice?.(14, 16);
        const deliveryTime = moment(`${dateString} ${timeString}`, `${DATE_FORMAT} ${TIME_FORMAT}`).format(
            DELIVERY_TIME_FORMAT
        );
        return (
            <Spin spinning={this.props.loading}>
                <div className="whole-wrapper">
                    <div className="eta-date-title-wrapper">
                        <FormattedMessage id="estimated_delivery" />
                    </div>
                    {this.props.estimatedDeliveryFee !== -1 ? (
                        <div className="eta-date-tax-wrapper">
                            <FormattedMessage
                                id="estimated_delivery_fee"
                                values={{ price: this.props.estimatedDeliveryFee }}
                            />
                        </div>
                    ) : (
                        <div
                            className="eta-date-fail-tax-wrapper"
                            onClick={() => {
                                this.props.fetchDeliveryFeeEstimate({
                                    est_dt: this.getETA(),
                                    order_id: this.props.order?.ord_id,
                                });
                            }}
                        >
                            <FormattedMessage id="delivery_fee_try_again" />
                            <FiRefreshCcw style={{ marginLeft: "10px" }} />
                        </div>
                    )}
                    {deliveryTime != "Invalid date" && (
                        <div className="deliver-time-text">
                            <FormattedMessage
                                id="estimated_doordash_delivery_time"
                                values={{
                                    time: deliveryTime,
                                }}
                            />
                        </div>
                    )}
                </div>
            </Spin>
        );
    };
    renderInsufficientFundsModalBody = () => {
        return (
            <div>
                <FormattedMessage id="insufficient_fund_msg" />
            </div>
        );
    };
    render() {
        let { intl } = this.props;
        let { formatMessage } = intl;

        return (
            <>
                <Modal
                    width={400}
                    visible={this.props.doordashDeliveryDetails.showInsufficientFunds}
                    footer={this.renderConfirmationFooter(false)}
                    onCancel={() => {
                        this.onCloseClick();
                    }}
                    title={formatMessage({ id: "insufficient_fund_title" })}
                >
                    {this.renderInsufficientFundsModalBody()}
                </Modal>
                <Modal
                    width={400}
                    visible={this.props.doordashDeliveryDetails.doordashCreatedFail}
                    footer={this.renderConfirmationFooter(false)}
                    onCancel={() => {
                        this.onCloseClick();
                    }}
                    title={formatMessage({ id: "delivery_failed_title" })}
                >
                    <div>
                        <FormattedMessage id="create_delivery_request_failed" />
                    </div>
                </Modal>
                <Modal
                    width={400}
                    visible={
                        this.props.doordashDeliveryDetails.doordashCreated &&
                        helper.isDelivery(this.props.shippingMethod)
                    }
                    footer={this.renderConfirmationFooter(true)}
                    onCancel={() => {
                        this.onCloseClick();
                    }}
                    title={formatMessage({ id: "delivery_sent" })}
                >
                    <div>
                        <FormattedMessage id="doordash_delivery_confirmation" />
                    </div>
                </Modal>
                <Modal
                    width={400}
                    visible={this.props.showModal}
                    footer={this.renderFooter()}
                    title={formatMessage({ id: "delivery_request_title" })}
                    onCancel={() => {
                        this.onCloseClick();
                    }}
                >
                    <div className="delivery-modal-container">
                        {this.renderETADateTime()}
                        {this.renderETAAddTimeButtonGroup()}
                        {this.renderEstimatedDeliveryCode()}
                    </div>
                </Modal>
            </>
        );
    }
}

const mapStateToProps = (state, ownProps) => ({
    shippingMethod: _.get(ownProps, "order.shp_mtd", null),
    liveOrderState: _.get(state, "liveOrders", {}),
    thirdPartyDelivery: _.get(state, "store.storeThirdPartyDelivery", {}),
    doordashDeliveryDetails: _.get(state, "store.doordashDeliveryDetails", {}),
    loading: _.get(state, "store.doordashDeliveryDetails.loading", false),
});

const mapDispatchToProps = {
    setState: _actions.setState,
    createDoordashDelivery: createDoordashDelivery,
    setDoordashDeliveryDetails: setDoordashDeliveryDetails,
    fetchDeliveryFeeEstimate: fetchDeliveryFeeEstimate,
    createDoordashDeliveryOnAcceptedOrder,
};

export default connect(mapStateToProps, mapDispatchToProps)(injectIntl(DoordashModal));
