// @ts-nocheck
/* eslint-disable @typescript-eslint/no-unused-vars */
import { ASAP, DINE_IN, IN_STORE } from "app/constants";
import { SelectOption } from "components/form";
import config from "config";
import images from "images";
import locale, { getIntl } from "locale";
import _ from "lodash";
import moment from "moment";
import "moment/locale/zh-cn";
import "moment/locale/zh-hk";
import { isOnlinePay } from "pages/order-history/helper";
import qs from "querystring";
import React from "react";
import { IntlShape } from "react-intl";
import countryData from "../data/countryList.json";
import { store } from "app/store";

export const APP_LAN_MOMENT_LAN_MAP = {
    zh: "zh-cn",
    en: "en",
    "zh-Hant": "zh-hk",
    fr: "fr",
};

function getLocalStorage(key: string): boolean {
    let result;
    try {
        result = localStorage.getItem(key);
        return JSON.parse(result);
    } catch (e) {
        removeLocalStorage(key);
        return false;
    }
}

export function formatName(name: Record<string, any>): string {
    if (name) {
        if (isString(name)) {
            return name;
        } else if (typeof name === "object" && name !== null) {
            let reformmatedName = "";
            if (name.firstname) {
                reformmatedName += name.firstname;
            }
            if (name.lastname) {
                reformmatedName += " " + name.lastname;
            }
            return reformmatedName;
        }
    }
    return "";
}

function setLocalStorage(key: string, value: Record<string, any>): any {
    try {
        value = JSON.stringify(value);
        localStorage.setItem(key, value);

        //fire event to notified
        if (triggerEvent) triggerCloudStorageEvent(key, value);
        return true;
    } catch (e) {
        return false;
    }
}

function removeLocalStorage(key: string): string {
    try {
        localStorage.removeItem(key);
        triggerCloudStorageEvent(key, "");
        return true;
    } catch (e) {
        return false;
    }
}

export function utcToLocal(time: Record<string, any> | any, params = {}): string {
    const defaultParams = {
        type: "short",
        time: true,
        date: true,
        asap: false,
        local: true,
        seperator: ",",
        timeZone: "",
        lan: "",
    };
    const rParams = Object.assign({}, defaultParams, params);

    if (time && isString(time)) {
        if (rParams.asap) {
            if (time.includes("2000-01-01 00:00:00") || time.includes("2000-01-01 00:00")) {
                return ASAP;
            }
        }

        let revisedTime = time;
        if (rParams.local) {
            revisedTime = time.includes("UTC") ? time : `${time} UTC`;
            revisedTime = revisedTime.replace(/-/g, "/");
        }

        const todayDate = new Date().getDate();
        const todayMonth = new Date().getMonth();
        const todayYear = new Date().getFullYear();

        const parsedDate = new Date(revisedTime).getDate();
        const parsedMonth = new Date(revisedTime).getMonth();
        const parsedYear = new Date(revisedTime).getFullYear();

        const sameDate = todayDate === parsedDate && todayMonth === parsedMonth && todayYear === parsedYear;
        //let sameMonth = todayMonth === parsedMonth && todayYear === parsedYear
        const sameYear = todayYear === parsedYear;
        const momentLan = moment.locale();
        const appLan = Object.keys(APP_LAN_MOMENT_LAN_MAP).find(
            (key) => String(APP_LAN_MOMENT_LAN_MAP[key]) === momentLan
        );

        let dateFormat, DDFormat, cTime, string, timeFormat, formats, calenderResult;
        if (rParams.date) {
            switch (rParams.type) {
                case "short": {
                    dateFormat = sameYear ? "MMM D" : "MMM D YYYY";
                    break;
                }
                case "medium": {
                    dateFormat = "MMMM Do YYYY";
                    break;
                }
                case "long": {
                    dateFormat = "MMMM Do YYYY";
                    break;
                }
                case "shortest":
                    DDFormat = appLan === "en" ? " DD" : "Do";
                    dateFormat = sameDate ? "" : sameYear ? `MMM${DDFormat}` : `MMM${DDFormat} YYYY`;
                    break;
                case "shortest_no_year": {
                    DDFormat = appLan === "en" ? " DD" : "Do";
                    dateFormat = sameDate ? "" : `MMM${DDFormat}`;
                    break;
                }
                case "shortest_digit": {
                    dateFormat = sameDate ? "" : sameYear ? "MM/DD" : "MM/DD/YY";
                    break;
                }
                case "moment_format":
                    dateFormat = "MM/DD/YYYY";
                    break;
                case "calendar": {
                    if (rParams.lan) updateMoment(rParams.lan);
                    cTime = rParams.local
                        ? rParams.timeZone
                            ? moment(revisedTime).tz(rParams.timeZone)
                            : moment(revisedTime).local()
                        : moment(revisedTime);
                    string = (id) => locale.getIntlMessages(appLan)[id];
                    timeFormat = rParams.timeFormat ? rParams.timeFormat : "hh:mm A";
                    formats = {
                        nextDay: `[${string("tomorrow")} @] ${timeFormat}`,
                        sameDay: rParams.shouldOmitToday ? `${timeFormat}` : `[${string("today")} @] ${timeFormat}`,
                        lastDay: `[${string("yesterday")} @] ${timeFormat}`,
                        sameWeek: `MMM Do [@] ${timeFormat}`,
                        lastWeek: `MMM Do [@] ${timeFormat}`,
                        nextWeek: `MMM Do [@] ${timeFormat}`,
                        sameYear: `MMM Do [@] ${timeFormat}`,
                        sameElse: `MMM Do [@] ${timeFormat}`,
                    };
                    calenderResult = cTime.calendar(null, formats);
                    updateMoment(appLan);
                    return calenderResult;
                }
                default: {
                    dateFormat = rParams.type;
                }
            }
        }

        if (rParams.time) {
            const timeFormat = rParams.timeFormat ? rParams.timeFormat : "hh:mm A";
            if (dateFormat) {
                dateFormat += `${rParams.seperator} ${timeFormat}`;
            } else {
                dateFormat = timeFormat;
            }
        }

        const dateObj = new Date(revisedTime);
        if (rParams.lan) updateMoment(rParams.lan);
        const momentObj = rParams.local
            ? rParams.timeZone
                ? moment(dateObj, rParams.timeZone)
                : moment(dateObj).local()
            : moment(dateObj);
        const result = momentObj.format(dateFormat);
        updateMoment(appLan);
        return result;
    }

    return "";
}

export function getTransString(stringMap: Record<string, any> | any, lan = "en"): any {
    const intl = getIntl();
    let returnStr = "";
    if (!stringMap) {
        return returnStr;
    }
    if (_.isString(stringMap)) {
        returnStr = stringMap;
    }
    if (_.isObject(stringMap)) {
        if (stringMap[lan]) {
            returnStr = stringMap[lan];
        } else if (lan === "zh" && stringMap["zh-Hant"]) {
            returnStr = stringMap["zh-Hant"];
        } else if (lan === "zh-Hant" && stringMap["zh"]) {
            returnStr = stringMap["zh"];
        } else if (stringMap["en"]) {
            returnStr = stringMap["en"];
        } else if (stringMap["fr"]) {
            returnStr = stringMap["fr"];
        } else if (isString(stringMap)) {
            returnStr = stringMap;
        } else if (Object.keys(stringMap) && Object.keys(stringMap).length) {
            returnStr = stringMap[Object.keys(stringMap)[0]];
        } else {
            return "";
        }
    }
    if (returnStr) {
        return returnStr.trim();
    } else {
        return intl.formatMessage({ id: "not_set" });
    }
}

export const formatNumberInput = (val: Record<number, any> | any): any => String(val)?.replace?.(/[^0-9.]/g, "") ?? "";

export const formatNumberInputNegativeAllowed = (val: Record<string, any> | any): any =>
    String(val)?.replace?.(/[^-?0-9.]/g, "") ?? "";

export const getOptions = (config: Record<number, any>, exclude: any = []): any => {
    const intl = getIntl();
    const options: any = [];
    Object.keys(config).forEach((key: any) => {
        if (!exclude.includes(key)) {
            options.push({
                value: Number(key),
                label: intl.formatMessage({ id: config?.[key] ?? " " }),
            });
        }
    });
    return options;
};

export function formatCurrency(
    amount: number,
    currency = "CAD",
    prefix = "",
    withSymbol = true,
    displaySignAsPrefix = false
): string {
    const symbol = currency ? config.CURRENCY_SYMBOL[currency] : config.CURRENCY_SYMBOL["CAD"];
    const isNAN = _.isNaN(parseFloat(amount));
    if (!isNAN) {
        // regex adds comma every third number
        const number = parseFloat(Math.round(Math.abs(amount) * 100) / 100)
            .toFixed(2)
            .replace(/\d(?=(\d{3})+\.)/g, "$&,");
        return `${prefix}${withSymbol ? symbol : ""}${number}`;
    }

    return `${amount}`;
}

export function formatMinutes(value: number, intl: IntlShape): JSX.Element {
    return value ? `${value?.replace?.(/[^0-9.]/g, "") ?? value}` : " ";
}

export function formatDays(value: number, intl: IntlShape): JSX.Element {
    return value ? `${value?.replace?.(/[^0-9]/g, "") ?? value}` : " ";
}

export function formatKM(value: number, intl: IntlShape): JSX.Element {
    return value ? `${value?.replace?.(/[^0-9]/g, "") ?? value} ${intl.formatMessage({ id: "km" })}` : "";
}

export function parseKM(value: string, intl: IntlShape): JSX.Element {
    return value?.replace?.(" km", "");
}

export function formatMiles(value: number, intl: IntlShape): JSX.Element {
    return value ? `${value?.replace?.(/[^0-9]/g, "") ?? value} ${intl.formatMessage({ id: "miles" })}` : "";
}

export function parseMiles(value: string, intl: IntlShape): JSX.Element {
    return value?.replace?.(" miles", "");
}

export function formatMoney(value: Record<string, any>, intl: IntlShape): any {
    return value ? `$ ${value?.replace?.(/[^0-9.]/g, "") ?? value}` : " ";
}

export function parseMoney(value: Record<string, any>, intl: IntlShape): any {
    return value?.replace?.("$ ", "");
}

export function getValuesFromEnumAsArray(Enum = {}): any {
    const keys: string[] = Object.keys(Enum);
    return keys.map((k) => Enum[k]).map((v) => v);
}

export function formatPhone(phone: Record<number, any> | any): string {
    let phoneStr = "";
    if (phone && (typeof phone === "string" || phone instanceof String)) {
        phoneStr = phone;
        phone = phone.replace(/[^\d]/g, "");
        const country = countryData.records.find((country) => {
            const reg = new RegExp(country.phone_format);
            return phone.match(reg);
        });
        if (country) {
            if (country.country_code === "CA" || country.country_code === "US") {
                phoneStr = phone.replace(/(\d{3})(\d{3})(\d{4})/, "($1) $2-$3");
            } else if (country.country_code === "CN") {
                phoneStr = phone.replace(/(\d{3})(\d{4})(\d{4})/, "($1) $2-$3");
            } else {
                phoneStr = phone;
            }
        }
    }
    return phoneStr;
}

//check phone number with countries to confirm phone is valid
export function isValidPhone(phone: string): boolean {
    const valid = countryData.records.find((country) => {
        const reg = new RegExp(country.phone_format);
        return reg.test(phone);
    });
    return !_.isEmpty(valid);
}

function formatAddress(address = {}, params: Record<string, any>): any {
    const defaultParams = {
        size: 2,
        withCustInfo: false,
        compactWith: ", ",
        without: [],
        lan: "en",
    };
    let toReturn = [];
    const rParams = Object.assign({}, defaultParams, params);
    const addressObj = _.cloneDeep(address) || {};

    rParams.without.forEach((key) => {
        delete addressObj[key];
    });

    if (addressObj.unit) {
        addressObj["street"] = `${addressObj.unit}-${addressObj.street}`;
    }

    if (addressObj.buzz) {
        const buzzerStr = locale.getIntlMessages(rParams.lan)["buzzer"];
        if (addressObj["street"]) {
            addressObj["street"] = `${buzzerStr}:${addressObj.buzz} ${addressObj.street}`;
        } else {
            addressObj["street"] = `${buzzerStr}:${addressObj.buzz}`;
        }
    }

    const { city, street } = addressObj;
    const region = addressObj.region || addressObj.province;
    const zipcd = addressObj.zipcd || addressObj.post_code || addressObj.postal_code;
    const cntry = addressObj.cntry || addressObj.country_code;

    const compactWith = rParams.compactWith;
    if (Number(rParams.size) === 1) {
        toReturn = [_.compact([street, city, region, zipcd, cntry]).join(compactWith)];
    } else if (Number(rParams.size) === 2) {
        toReturn = [_.compact([street]).join(compactWith), _.compact([city, region, zipcd, cntry]).join(compactWith)];
    }
    if (rParams.withCustInfo) {
        toReturn.unshift([`${addressObj.fn ? `${addressObj.fn} ` : ""}${addressObj.ln || ""}`]);
    }

    return toReturn;
}

function roundToTwo(num: number): number {
    return (Math.round(num * 100) / 100).toFixed(2);
}

function addrToString(params = {}): any {
    const { buzz, unit, street, city, region, country_code, post_code } = params;
    const adrStr = _.compact([buzz, unit, street, city, region, country_code, post_code]).join(", ");
    return adrStr;
}

function generateId(): string {
    return "_" + Math.random().toString(36).substr(2, 9);
}

export function getKeyByValue(object = {}, value: Record<string, any> | any): any {
    return Object.keys(object).find((key) => object[key] === value);
}

function getCurrencyFormattedString(amount: number, currency = "CAD"): JSX.Element {
    const symbol = config.CURRENCY_SYMBOL[currency] ?? config.CURRENCY_SYMBOL["CAD"];
    const isNAN = Number.isNaN(parseFloat(amount));

    return !isNAN && amount
        ? symbol +
              parseFloat(Math.round(amount * 100) / 100)
                  .toFixed(2)
                  .replace(/\d(?=(\d{3})+\.)/g, "$&,")
        : symbol + "0.00";
}

// function adds comma every third number using regular expression
export function formatNumber(number: Record<number, any> | any): any {
    return number && number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
}

function getTaxNumber(vat: boolean, country: string, province: string): string {
    let taxPrefix = "";
    if (vat) {
        if (String(country) === "CA") {
            switch (province) {
                case "ON":
                case "NB":
                case "NL":
                case "NS":
                case "PE":
                    taxPrefix = "HST";
                    break;
                default:
                    taxPrefix = "GST";
                    break;
            }
        } else if (String(country) === "US") {
            taxPrefix = "VAT";
        }
    }
    return vat ? taxPrefix + " #" + vat : "";
}

function catToTree(catList: Record<string, any>): JSX.Element {
    let maxLevel = config.CATEGORY_LEVELS.root;

    catList.forEach((cat) => {
        if (parseInt(cat.level) > maxLevel) {
            maxLevel = parseInt(cat.level);
        }
    });

    const sortedList = {};

    for (let currLevel = 1; currLevel <= maxLevel; currLevel++) {
        const rList = [];
        catList.forEach((cat) => {
            if (Number(cat.level) === currLevel) {
                rList.push(cat);
            }
        });
        sortedList[currLevel] = rList;
    }
    for (let currLevel = maxLevel; currLevel >= 1; currLevel--) {
        const prevLevel = currLevel - 1;
        if (prevLevel >= 1) {
            sortedList[prevLevel].forEach((prevCat) => {
                prevCat.children = [];
                sortedList[currLevel].forEach((currCat) => {
                    if (String(currCat.parent_id) === String(prevCat.category_id)) {
                        prevCat.children.push(currCat);
                    }
                });
            });
        }
    }

    const tree = _.cloneDeep(sortedList[1]);
    return tree;
}

function reviseDeliveryMethod(storeType: string, deliveryMethod: Record<string, any>): string {
    const restaurant = config.CATEGORIES_MAPPING[config.CATEGORY_MAPPING_TO_NUMBER.restaurant];
    const restaurantCategory = getKeyByValue(config.CATEGORIES_MAPPING, restaurant);

    if (String(deliveryMethod) === config.SHIPPING_MAPPING_TO_TEXT[String(config.SHIPPING_MAPPING_TO_NUMERIC.eatin)]) {
        if (storeType == restaurantCategory) {
            return DINE_IN;
        } else {
            return IN_STORE;
        }
    } else {
        return deliveryMethod;
    }
}

export function reviseDeliveryMethodWithStore(store: string, deliveryMethod: Record<string, any>): string {
    const storeFlg = _.get(store, "store_flg", "");
    const storeType = String(getRealStoreType(storeFlg));
    return reviseDeliveryMethod(storeType, deliveryMethod);
}

function getStoreFlagWithType(flag: string, type: string): string {
    if (isString(flag)) {
        const flagArr = flag.split(",");
        //https://wiki.goopter.com/index.php?title=Goopter_Store_Config_Model#Store_Flag_Structure
        const typeIndex = _.get(config.STORE_FLAG_INDEX_MAP, type, -1);

        if (Number(typeIndex) !== -1) {
            return flagArr[typeIndex];
        }
    }
}

function getRealStoreType(flag: string): string {
    const storeType = getStoreFlagWithType(flag, "category_ids");
    if (isString(storeType) && storeType.includes(":")) {
        const types = storeType.split(":");
        return types[0];
    }
    return storeType;
}

function getFullDate(startDate: string, endDate: string, params = {}): string {
    let fullDate = "";

    if (startDate && startDate.includes("2000-01-01 00:00")) {
        return ASAP;
    }

    let revisedStart = moment(startDate, "YYYY-MM-DD HH:mm").format("MMMM D, YYYY/hh:mm");
    let revisedEnd = moment(endDate, "YYYY-MM-DD HH:mm").format("MMMM D, YYYY/hh:mm");

    if (revisedStart) {
        revisedStart = revisedStart.split("/");
    }
    if (revisedEnd) {
        revisedEnd = revisedEnd.split("/");
    }
    fullDate += utcToLocal(
        startDate,
        Object.assign(
            {
                type: "calendar",
                asap: true,
                local: false,
            },
            params
        )
    );
    if (revisedEnd && revisedEnd[1] && revisedStart[1] !== revisedEnd[1]) {
        fullDate += `-${utcToLocal(
            endDate,
            Object.assign(
                {
                    type: "calendar",
                    asap: true,
                    local: false,
                    date: false,
                },
                params
            )
        )}`;
    }
    return fullDate;
}

export function isString(str: Record<string, any>): string {
    return (str && typeof str === "string") || str instanceof String;
}

function isFunction(v: Record<string, any> | any): string {
    return typeof v === "function";
}

function isObject(v: Record<string, any> | any): string {
    return typeof v === "object" && v !== null;
}

//https://stackoverflow.com/questions/18515254/recursively-remove-null-values-from-javascript-object
//however I wanted to remove not only null values
// but also undefined, NaN, empty String, empty array and empty object values,
//recursively, by inspecting nested objects and also nested arrays.
function clean(obj: Record<string, any>): any {
    return (function prune(current) {
        _.forOwn(current, function (value, key) {
            if (
                _.isUndefined(value) ||
                _.isNull(value) ||
                _.isNaN(value) ||
                (_.isString(value) && _.isEmpty(value)) ||
                (_.isObject(value) && _.isEmpty(prune(value)))
            ) {
                delete current[key];
            }
        });
        // remove any leftover undefined values from the delete
        // operation on an array
        if (_.isArray(current)) _.pull(current, undefined);

        return current;
    })(_.cloneDeep(obj));
}

export const getDiffProperties = (a: Record<string, any> | any, b: Record<string, any> | any): any => {
    return _.reduce(
        a,
        function (result, value, key) {
            return _.isEqual(value, b[key]) ? result : result.concat(key);
        },
        []
    );
};

export const updateMoment = (result: string): any => {
    const locale = APP_LAN_MOMENT_LAN_MAP[result] ? APP_LAN_MOMENT_LAN_MAP[result] : "en";
    moment.locale(locale);
};

export const debounce = (fn: F, wait: number): ((this: ThisParameterType<F>, ...args: Parameters<F>) => void) => {
    let timer: ReturnType<typeof setTimeout> | null;

    // eslint-disable-next-line func-names
    return function (this: ThisParameterType<F>, ...args: Parameters<F>) {
        if (timer !== null) {
            clearTimeout(timer);
            timer = null;
        }
        timer = setTimeout(() => fn.apply(this, args), wait);
    };
};

function tConvert(time: Record<string, any>): string {
    // Check correct time format and split into components
    if (!isString(time) || time.length > 6) {
        time = moment(time).format("HH:mm");
    }
    time = time.toString().match(/^([0-9]{1,2})(:)([0-5]\d)(:[0-5]\d)?$/) || [time];
    if (time.length > 1) {
        // If time format correct
        time = time.slice(1); // Remove full string match value
        time[5] = +time[0] < 12 ? "AM" : "PM"; // Set AM/PM
        time[0] = +time[0] % 12 || 12; // Adjust hours
    }
    return time.join(""); // return adjusted time or original string
}

function formatDayAndHours(availability: string): string {
    const intl = getIntl();
    if (availability && availability["available_time"]) {
        return availability["available_time"]
            .map(function (el: any) {
                const days = el["days"]
                    .map(function (day: any) {
                        if (day == "fr") day = "frd";
                        return intl.formatMessage({ id: day });
                    })
                    .join(", ");
                const hours = el["hours"]
                    .map(function (hourElement: any) {
                        return tConvert(hourElement["open"]) + "-" + tConvert(hourElement["close"]);
                    })
                    .join(", ");
                return days + " : " + hours + "<br>";
            })
            .join(" ");
    }
    return " ";
}

export const getValidTimezone = (timezone: string): string => {
    return timezone && !!moment.tz.zone(timezone) ? timezone : moment.tz.guess();
};

export const isBool = (num: Record<string, any> | any): boolean =>
    num === "1" || num === true || num === 1 ? true : false;
export const isNum = (bool: Record<boolean, any> | any): string => (bool === true || bool === "1" ? "1" : "0");
export const switchNum = (num: number): any => parseInt(isNum(!isBool(num)));

export const getImage = (url: string): string => {
    return config.IMG_PREFIX + url;
};

const getStoreWithGid = (gid: string, storesData: string): string => {
    const isArray = Array.isArray(storesData);
    if (isArray && storesData.length > 1) {
        return storesData.find((store) => {
            return String(gid) === String(_.get(store, "general_info.g_id", ""));
        });
    } else if (isArray && Number(storesData.length) === 1) {
        return storesData[0];
    } else {
        return storesData;
    }
};

export const isRestaurant = (store: string): string => {
    const cidsFromStore = _.get(store, "general_info.cids", []);
    const cids = Array.isArray(cidsFromStore) ? cidsFromStore : [];
    return cids.includes(1); //resturant
};

export const isRestaurantFromFlags = (storeFlags = ""): any => {
    if (storeFlags.length > 0) {
        let index = 0;
        for (let i = 0; i < config.STORE_FLAG_INDEX_MAP.category_ids; i++) {
            index = storeFlags.indexOf(",", index + 1);
        }
        const categoryFlag = storeFlags.substring(index + 1, storeFlags.indexOf(",", index + 1));
        return categoryFlag.includes(String(config.CATEGORY_MAPPING_TO_NUMBER.restaurant));
    }
    return false;
};

export const getDefaultImage = (t: string): any => {
    const type = Number(t?.split?.(":")[0]);

    // type 1 = restaurant
    return type === 1 ? images[`i_restaurant_small_grey`]?.default : images[`i_shopping_small_grey`]?.default;
};

export function sprintf(...theArgs: Record<string, any>): any {
    const args = theArgs;
    const string = args[0];
    let i = 1;
    return string?.replace?.(/%((%)|s|d)/g, function (m) {
        // m is the matched format, e.g. %s, %d
        let val = null;
        if (m[2]) {
            val = m[2];
        } else {
            val = args[i];
            // A switch statement so that the formatter can be extended. Default is %s
            switch (m) {
                case "%d":
                    val = parseFloat(val);
                    if (isNaN(val)) {
                        val = 0;
                    }
                    break;
            }
            i++;
        }
        return val;
    });
}

function getImageUrl(image: string, extraUrl = ""): string {
    return config.IMG_PREFIX + `f_auto,fl_lossy,q_auto,w_350,h_350,c_limit,c_fit${extraUrl}/` + image;
}

function getImageUrl1024(image: string): string {
    return config.IMG_PREFIX + "f_auto,fl_lossy,q_auto,w_1024,h_256,c_limit,c_fit/" + image;
}

function getImageUrlOriginal(image: string): string {
    return config.IMG_PREFIX + image;
}

export const getPrice = (product: Record<string, any>): string => {
    const specialPrice = product?.spc || product?.special_price;
    if (!_.isNil(specialPrice) && specialPrice > 0) {
        const format = "YYYY-MM-DD HH:mm:ss";
        const now = moment();
        const start = moment.utc(product?.sdt || product?.special_from_date, format).local();
        const end = moment.utc(product?.edt || product?.special_to_date, format).local();
        if (now.isBetween(start.format(format), end.format(format))) {
            return specialPrice;
        }
    }
    return product?.pc || product?.price;
};

const getCategoryString = (c_id: string): string => {
    return config["CATEGORIES_MAPPING"]["" + c_id] ?? config["CATEGORIES_MAPPING"]["1"];
};

/**
 * Check if the role is a Super User.
 *
 * @param role - User's role.
 * @returns True if the user's role is Super User.
 */
export const isSuperUserRole = (role: Record<string, any>): boolean => {
    return Number(role) === config.ROLE_ID.SUPER_USER;
};

/**
 * Determine if the user is is a Super User.
 *
 * @param user - user who is determined.
 * @returns True if the user is a Super User.
 */
export const isSuperUser = (user: Record<string, any> | any): any => {
    return user?.roles?.includes(config.ROLE_ID.SUPER_USER);
};

export const DEFAULT_ROLES_NUM = 4;

export type options = { [key: string]: string };

const UTENSIL_OPTIONS_MAP: options = {
    do_not_show_option: "0",
    show_utensil_option_not_required: "1",
    show_utensil_option_required: "2",
};

const UTENSIL_PRINT_OPTIONS: options = {
    do_not_print: "0",
    always_print: "1",
    only_print_utensil_request: "2",
    only_print_utensil_no_request: "3",
};

export function formatNumberWithCommas(x: string): string {
    return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
}

export function isNumber(num: number): string {
    return typeof num === "number";
}

export const metersToMiles = (meter: string): number => {
    const meterInNumber = Number(meter) ? Number(meter) : 0;
    return meterInNumber * 0.000621371;
};

export const metersToKm = (meter: string): number => {
    const meterInNumber = Number(meter) ? Number(meter) : 0;
    return meterInNumber * 0.001;
};

export const num2Binary = (num: number, width = 0): string => {
    const binaryStr = parseInt(num, 10).toString(2);
    let resultStr = binaryStr;
    for (let i = 0; i < width - binaryStr.length; i++) {
        resultStr = "0" + resultStr;
    }
    return resultStr;
};

export const bin2Number = (numbers: Record<string, any> | any): string => {
    const nums = numbers.map((el: boolean) => isNum(el));
    const a = nums.join("");
    return parseInt(a.split("").join(""), 2).toString();
};

export const strToCharArray = (str: string): any => {
    if (isString(str)) return str.split("");
    return [];
};

export const isCharAscii = (char: Record<string, any> | any): any => {
    const charCode = char.charCodeAt(0);
    // ascii character code range from 0 to 127
    return charCode <= 127;
};

export const isValidPostal = (postal: Record<string, any> | any, code = "CA"): any => {
    let result = "";
    countryData.records.forEach((country) => {
        if (country.country_code === code) {
            result = country.zipcode_format;
        }
    });
    const reg = new RegExp(result);
    return reg.test(postal);
};

enum MENU_ACTION {
    SET = 2,
    CONFIRM = 3,
}

export const to24Hours = (str: string): string => {
    str = String(str).toLowerCase().replace(/\s/g, "");
    const has_am = str.indexOf("am") >= 0;
    const has_pm = str.indexOf("pm") >= 0;
    // first strip off the am/pm, leave it either hour or hour:minute
    str = str.replace("am", "").replace("pm", "");
    // if hour, convert to hour:00
    if (str.indexOf(":") < 0) str = str + ":00";
    // now it's hour:minute
    // we add am/pm back if striped out before
    if (has_am) str += " am";
    if (has_pm) str += " pm";
    // now its either hour:minute, or hour:minute am/pm
    // put it in a date object, it will convert to 24 hours format for us
    const d = new Date("1/1/2011 " + str);
    // make hours and minutes double digits
    const doubleDigits = function (n) {
        return parseInt(n) < 10 ? "0" + n : String(n);
    };
    return doubleDigits(d.getHours()) + ":" + doubleDigits(d.getMinutes());
};

const str = (id = " ", values = {}) => {
    const intl = getIntl();
    return intl.formatMessage({ id: id || " " }, values);
};

/**
 * Returns true or false, if it is allowed shipping method by store flags
 * Store flag info {@link https://wiki.goopter.com/index.php?title=Goopter_Store_Config_Model#Store_Flag_Structure}
 *
 * @param shippingMethod - candidate of shipping method
 * @returns if it is allowed, return true else false
 */
const isAllowedShippingMethod = (shippingMethod: any, flagArr: any) => {
    if (isDelivery(shippingMethod)) {
        return Number(flagArr[config.STORE_FLAG_INDEX_MAP.allow_delivery]) === 1;
    } else if (isEatIn(shippingMethod)) {
        return Number(flagArr[config.STORE_FLAG_INDEX_MAP.allow_eatin]) === 1;
    } else if (isPickup(shippingMethod)) {
        return Number(flagArr[config.STORE_FLAG_INDEX_MAP.allow_takeout]) === 1;
    } else {
        return true;
    }
};

/**
 * Returns a list of filtered shipping methods by store config flag.
 * Store flag info {@link https://wiki.goopter.com/index.php?title=Goopter_Store_Config_Model#Store_Flag_Structure}
 *
 * @param shippingMethods - candidate of shipping methods
 * @returns a list of allowed shipping methods
 */
export const getFilteredShippingMethods = (shippingMethods: string, store: string): any => {
    const storeFlags = _.get(store, "store_flg", "");

    let filteredShippingMethods = [];
    if (isString(storeFlags)) {
        const flagArr = storeFlags.split(",");
        filteredShippingMethods = _.filter(shippingMethods, (shippingMethod: any) => {
            return isAllowedShippingMethod(shippingMethod, flagArr);
        });
    }
    return filteredShippingMethods;
};

/**
 * Returns a list of shipping options which is filtered by store flag.
 * Store flag info {@link https://wiki.goopter.com/index.php?title=Goopter_Store_Config_Model#Store_Flag_Structure}
 *
 * @returns a list of shipping options
 */
export const getShippingOptions = (store: Record<string, any> | any): any => {
    const INCLUDES_METHODS = [
        config.SHIPPING_MAPPING_TO_NUMERIC.eatin,
        config.SHIPPING_MAPPING_TO_NUMERIC.pickup,
        config.SHIPPING_MAPPING_TO_NUMERIC.delivery,
        config.SHIPPING_MAPPING_TO_NUMERIC.quick_pay,
    ];

    const shippingMethods = config.SHIPPING_MAPPING_TO_NUMERIC;
    const filteredShippingMethods = getFilteredShippingMethods(INCLUDES_METHODS, store);

    const shippingOptions: SelectOption[] = [];

    filteredShippingMethods.map((shippingMethod: any) => {
        const label = getKeyByValue(shippingMethods, shippingMethod);
        shippingOptions.push({
            label: str(reviseDeliveryMethodWithStore(store, label)),
            value: shippingMethod,
        });
    });
    return shippingOptions;
};

//@ts-nocheck
export const getValueOfStoreFlag = (store: Record<string, any>, flagIndex: number): any => {
    const storeFlags = _.get(store, "store_flg", "") || _.get(store, "records.store_flg", "");
    const flags = storeFlags.split(",");

    if (flagIndex >= 0 && flagIndex < flags.length) {
        return Number(flags[flagIndex]);
    }
};

export const getDeliveryServiceProvider = (store: Record<string, any> | any): any => {
    return (
        getValueOfStoreFlag(store, config.STORE_FLAG_INDEX_MAP.preferred_delivery_method) ||
        config.PREFERRED_DELIVERY_METHODS_TO_NUMERIC.default
    );
};

/**
 * Get the back route of current window location
 *
 * @returns a string of back route
 */
const getBackRoute = () => {
    const { back } = qs.parse(window.location.search.substr(1));
    return back;
};

/**
 * Push a back route to history
 * it makes to redirect to the back route page
 *
 * @param history - current history object
 */
export const goBackPreviousPage = (history: Record<string, any> | any): any => {
    const back = getBackRoute();
    if (back) {
        history.push(`/${back}`);
    }
};

/**
 * Go to create-order page
 * It makes to redirect to the create-order page
 *
 * @param window - current window object
 * @param history - current history object
 */
export const goToCreateOrderPage = (window: Record<string, any> | any, history: Record<string, any> | any): any => {
    history.push(`/create_order?back=${window.location.pathname.substr(1)}`);
};

export const buildQuery = (params?: Record<string, any> | any): any => {
    if (!params) {
        return "";
    }

    const keys = Object.keys(params);
    let queryString = "";
    for (let i = 0; i < keys.length; i++) {
        if (params[keys[i]] === undefined) {
            continue;
        }

        queryString += keys[i] + "=" + params[keys[i]];
        if (i !== keys.length - 1 && params[keys[i + 1]] !== undefined) {
            queryString += "&";
        }
    }
    return queryString;
};

export const isDelivery = (shippingMethod: number): any =>
    shippingMethod === config.SHIPPING_MAPPING_TO_NUMERIC.delivery ||
    shippingMethod === config.SHIPPING_MAPPING_TO_NUMERIC.free_shipping;

export const isPickup = (shippingMethod: number): any => shippingMethod === config.SHIPPING_MAPPING_TO_NUMERIC.pickup;

export const isEatIn = (shippingMethod: number): any => shippingMethod === config.SHIPPING_MAPPING_TO_NUMERIC.eatin;

export const isInstantCheckout = (shippingMethod: number): any =>
    shippingMethod === config.SHIPPING_MAPPING_TO_NUMERIC.quick_pay;

export const saveTextToFile = (text: string, fileName: string): any => {
    const element = document.createElement("a");
    const data = new Blob([text]);
    element.href = URL.createObjectURL(data);
    element.download = fileName;
    document.body.appendChild(element);
    element.click();
    document.body.removeChild(element);
};

export const readTextFileFromInput = (
    event: React.ChangeEvent<HTMLInputElement>,
    onLoad: (e: ProgressEvent<FileReader>) => void
): any => {
    const file = event.target?.files?.[0];
    if (!file) {
        return;
    }

    const fileReader = new FileReader();
    fileReader.readAsText(file);
    fileReader.onload = onLoad;
};

export const getAlternativeLanguage = (language: string): any => {
    switch (language) {
        case config.LANGUAGE_CODES["zh"]: {
            return config.LANGUAGE_CODES["zh-Hant"];
        }
        case config.LANGUAGE_CODES["zh-Hant"]: {
            return config.LANGUAGE_CODES["zh"];
        }
        default: {
            return config.LANGUAGE_CODES["en"];
        }
    }
};

export const hasOrderBeenAccepted = (orderDeliveryStatus: number): any => {
    return orderDeliveryStatus >= config.ORDER_DELIVERY_STATUS_MAPPING_TO_NUMERIC.store_accepted;
};

export const isOrderLive = (orderStatus: string): any => {
    const ORDER_STATUS_MAP = config.ORDER_STATUS_MAPPING_TO_NUMERIC;
    return (
        orderStatus !== ORDER_STATUS_MAP.canceled &&
        orderStatus !== ORDER_STATUS_MAP.closed &&
        orderStatus !== ORDER_STATUS_MAP.completed
    );
};

export const showMarkAsPaidOrUnpaid = (order: Record<string, any> | any): any => {
    return !isOnlinePay(order) && hasOrderBeenAccepted(order?.delivery_status) && isOrderLive(order?.ord_st);
};

export const isEtransferAllowed = (store: Record<string, any> | any): any => {
    const allowEtransferValue = getValueOfStoreFlag(store, config.STORE_FLAG_INDEX_MAP.allow_etransfer);
    return allowEtransferValue === 1;
};

export const getNumberFromLocalStorage = (key: string): any => {
    const value = localStorage.getItem(key);
    return !_.isNil(value) && _.isFinite(Number(value)) ? Number(value) : null;
};

export const hasSpecialPrice = (product: Record<string, any> | any): any => {
    const specialPrice = product?.spc || product?.special_price;
    const startSpecialPrice = product?.sdt || product?.special_from_date;
    const endSpecialPrice = product?.edt || product?.special_to_date;
    const noSpecialStartEndDateSet = _.isNil(startSpecialPrice) && _.isNil(endSpecialPrice);

    if (!_.isNil(specialPrice) && specialPrice > 0) {
        const format = "YYYY-MM-DD HH:mm:ss";
        const now = moment();
        // arbitrarily descreasing/increasing the start/end date by 1 respectively here if the dates do not exist
        // because empty start date is treated as the any time in the past and empty end date is treated as any time in the future
        const start = startSpecialPrice ? moment.utc(startSpecialPrice, format).local() : moment().subtract(1, "days");
        const end = endSpecialPrice ? moment.utc(endSpecialPrice, format).local() : moment().add(1, "days");
        return now.isBetween(start.format(format), end.format(format)) || noSpecialStartEndDateSet;
    }
    return false;
};

export const getPaymentStatusString = (paymentStatus: number): string => {
    let paymentStatusString = "";
    switch (paymentStatus) {
        case config.PAY_STATUS_TO_NUMERIC.default:
            paymentStatusString = "not_paid";
            break;
        case config.PAY_STATUS_TO_NUMERIC.pending:
            paymentStatusString = "authorized";
            break;
        case config.PAY_STATUS_TO_NUMERIC.paid:
            paymentStatusString = "paid";
            break;
        case config.PAY_STATUS_TO_NUMERIC.cancelled:
            paymentStatusString = "canceled";
            break;
        case config.PAY_STATUS_TO_NUMERIC.failed:
            paymentStatusString = "failed";
            break;
        case config.PAY_STATUS_TO_NUMERIC.partially_refunded:
            paymentStatusString = "partially_refunded";
            break;
        case config.PAY_STATUS_TO_NUMERIC.fully_refunded:
            paymentStatusString = "fully_refunded";
            break;
        case config.PAY_STATUS_TO_NUMERIC.voided:
            paymentStatusString = "voided";
            break;
        default:
            paymentStatusString = "";
            break;
    }
    return paymentStatusString;
};

export const buildTranslatedSelectOption = (label: string, value: Record<string, any> | any): any => {
    const intl = getIntl();
    return {
        label: intl.formatMessage({ id: label }),
        value: value,
    };
};

export const getGSaleURL = (productId: string | number, lan: string): any => {
    return `${config.H5_URL}gsale/${productId}?lan=${lan}`;
};

export const getProductURL = (productId: string | number, lan: string): any => {
    return `${config.H5_URL}product/${productId}?lan=${lan}`;
};

export const getP2VRatio = (store: Record<string, any> | any): any => {
    return getValueOfStoreFlag(store, config.STORE_FLAG_INDEX_MAP.p2v);
};

export const getV2PRatio = (store: Record<string, any> | any): any => {
    return getValueOfStoreFlag(store, config.STORE_FLAG_INDEX_MAP.v2p);
};

export const getMinPointsRedemptionValueAmount = (store: Record<string, any> | any): any => {
    return getValueOfStoreFlag(store, config.STORE_FLAG_INDEX_MAP.tips_min_points_redemption_value_amount);
};

export const getMaxPointsRedemptionType = (store: Record<string, any> | any): any => {
    return getValueOfStoreFlag(store, config.STORE_FLAG_INDEX_MAP.tips_max_points_redemption_type) ?? 0;
};

export const getMaxPointsRedemptionValuePerOrder = (store: Record<string, any> | any): any => {
    return getValueOfStoreFlag(store, config.STORE_FLAG_INDEX_MAP.tips_max_points_redemption_value_per_order) ?? 0;
};

export const convertInitialValuesToBool = (initialValues: Record<string, number | string>): any => {
    const newValues: any = {};
    Object.entries(initialValues).forEach(([key, value]) => {
        if (value == 0 && key.includes("allow")) {
            newValues[key] = Boolean(Number(value));
        } else if (value == 1 && key.includes("allow")) {
            newValues[key] = Boolean(Number(value));
        }
    });
    return { ...initialValues, ...newValues };
};
/**
 * @desc
 * assign new object to target object
 * filter out all the undefined value
 *
 * @param {
 *  Object <T>
 * } target
 * @param {
 *  Object: {
 *   string: any
 *  }
 * } values
 *
 * @returns {
 *  Object <T>
 * }
 */
export const assignDefinedObject = (target: T, ...values: any | { [key: string]: string | undefined | number }): T => {
    for (const [valuesKey, valuesValue] of values) {
        if (target[valuesKey] === undefined) {
            target[valuesKey] = valuesValue;
        }
    }
    return target;
};

export const isPPCP3dsOrder = (order: Record<string, any>): boolean => {
    const paymentMethod = String(_.get(order, "pay_mtd", 0));
    const paymentMethodStr = config.PAYMENT_METHOD_MAPPING[paymentMethod]
        ? config.PAYMENT_METHOD_MAPPING[paymentMethod]
        : " ";
    return paymentMethodStr == "paypal_complete_payments_paypal_with_3ds";
};

interface CreditCard {
    type?: string;
    num?: string;
}

/**
 * Retrieves a localized printer string and replaces placeholders with provided values.
 *
 * This function fetches a localized string for a given key (`name`) from the "printer-page" locale.
 * It attempts to retrieve the string in the specified language (`lan`). If the string is not found
 * in the desired language, it falls back to English ("en"). If the string is still not found,
 * it returns the `name` itself as a default.
 *
 * After retrieving the localized string, the function processes it to replace any placeholders
 * enclosed in curly braces (`{}`) with corresponding values from the `values` object. This allows
 * dynamic insertion of variables into the localized string.
 *
 * **Example Usage:**
 * ```typescript
 * const localizedStr = getPrinterTransString('fr', 'printer_label', { count: 5 });
 * // If "printer_label" in French locale is "Imprimante ({count})", the result will be "Imprimante (5)"
 * ```
 *
 * @param lan - The language code (e.g., "en", "fr") used to fetch the appropriate localization messages.
 * @param name - The key identifying the specific string to retrieve from the localization messages.
 * @param values - An optional object containing key-value pairs used to replace placeholders within the localized string.
 *                 Keys in this object should correspond to placeholder names in the localized string.
 *                 For example, `{ count: 5 }` will replace `{count}` in the string with `5`.
 * @returns A localized string with placeholders replaced by the provided values.
 *          If the string corresponding to `name` is not found in the specified language or English,
 *          the function returns the `name` itself.
 */
export const getPrinterTransString = (lan: string, name: string, values = {}): string => {
    const transObj = locale.getIntlMessages(lan, "printer-page");

    const updateStrWithValues = (str) => {
        if (isString(str)) {
            const splitedStrs = {};
            let currentStr = "";
            for (const c of str) {
                if (String(c) === "{" || String(c) === "}") {
                    splitedStrs[currentStr] = currentStr;
                    currentStr = "";
                } else {
                    currentStr += c;
                }
            }
            if (currentStr) splitedStrs[currentStr] = currentStr;
            Object.keys(values).forEach((key) => {
                splitedStrs[key] = values[key];
            });
            return Object.values(splitedStrs).join("");
        }
        return str;
    };

    let result = "";
    if (transObj && transObj[name]) {
        result = transObj[name];
    } else {
        const enTransObj = locale.getIntlMessages("en", "printer-page");
        if (enTransObj && enTransObj[name]) {
            result = enTransObj[name];
        } else {
            result = name;
        }
    }
    return updateStrWithValues(result);
};

/**
 * Generates a payment string that includes the payment method and credit card information.
 *
 * This function constructs a localized payment string based on the provided credit card details
 * and the associated payment method and printing language.
 *
 * @param creditCard - The credit card information to include in the payment string.
 * @param paymentMethod - The numeric identifier representing the payment method.
 * @returns A localized string combining the payment method and credit card details.
 * @param printLan - (Optional) The printing language which is used to print order.
 *          Returns an empty string if no relevant information is available.
 */
export const getPaymentStringWithCreditCardInfo = (
    creditCard: CreditCard,
    paymentMethod: number,
    printLan?: string
): string => {
    // Get the internationalization function
    const state = store.getState();
    const lan = state?.settings?.lan ?? "en";
    const str = (id: string): string => locale.getIntlMessages(lan)[id];

    // Retrieve the payment method string based on the payment method ID
    const paymentMethodValue = config.PAYMENT_METHOD_MAPPING[paymentMethod] ?? "";
    const paymentMethodStr = printLan ? getPrinterTransString(printLan, paymentMethodValue) : str(paymentMethodValue);
    // Retrieve credit card type string and credit card last 4 digits
    const creditCardTypeValue = config.CREDIT_CARD_TYPE_MAP[creditCard?.type];
    const creditCardNum = creditCard?.num;

    // Initialize the result with the payment method string if available
    let result = `${paymentMethodStr}`;

    // Proceed only if both credit card type and number are available
    if (creditCardTypeValue && creditCardNum) {
        // Construct the credit card information string with masking
        const creditCardTypeStr = printLan
            ? getPrinterTransString(printLan, creditCardTypeValue)
            : str(creditCardTypeValue);
        // This is for the receipt only with 3ds payment method example) Visa - 3DS #1234
        if (printLan && paymentMethodValue === "paypal_complete_payments_paypal_with_3ds") {
            result = `${creditCardTypeStr} - 3DS #${creditCardNum}`;
            // This is for general credit card payment method example) Visa #1234
        } else if (
            paymentMethodValue.includes("credit_card") ||
            paymentMethodValue === "Credit Card" ||
            (printLan && paymentMethodValue === "paypal_complete_payments") ||
            paymentMethodValue === "paypal_complete_payments_paypal_with_3ds"
        ) {
            result = `${creditCardTypeStr} #${creditCardNum}`;
        } else {
            result = `${paymentMethodStr} (${creditCardTypeStr} #${creditCardNum})`;
        }
    }
    return result;
};

export default {
    num2Binary,
    bin2Number,
    tConvert,
    getLocalStorage,
    setLocalStorage,
    removeLocalStorage,
    utcToLocal,
    getTransString,
    roundToTwo,
    addrToString,
    generateId,
    getKeyByValue,
    getStoreFlagWithType,
    getCurrencyFormattedString,
    getTaxNumber,
    catToTree,
    reviseDeliveryMethod,
    getRealStoreType,
    getFullDate,
    formatMinutes,
    formatKM,
    parseKM,
    formatMiles,
    parseMiles,
    formatMoney,
    parseMoney,
    formatDays,
    formatPhone,
    formatNumber,
    formatAddress,
    formatCurrency,
    isString,
    isFunction,
    isObject,
    clean,
    isNum,
    getDiffProperties,
    updateMoment,
    getStoreWithGid,
    isRestaurant,
    formatDayAndHours,
    isSuperUserRole,
    isSuperUser,
    DEFAULT_ROLES_NUM,
    getImage,
    getImageUrl,
    getImageUrl1024,
    getImageUrlOriginal,
    getCategoryString,
    isBool,
    isRestaurantFromFlags,
    UTENSIL_OPTIONS_MAP,
    UTENSIL_PRINT_OPTIONS,
    MENU_ACTION,
    goBackPreviousPage,
    goToCreateOrderPage,
    isDelivery,
    isPickup,
    isEatIn,
    isInstantCheckout,
    getAlternativeLanguage,
    hasSpecialPrice,
    getPrice,
    isPPCP3dsOrder,
    getPrinterTransString,
    getPaymentStringWithCreditCardInfo,
};
