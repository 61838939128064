export default {
    "": "",
    " ": " ",
    yes: "Yes",
    no: "No",
    confirm: "Confirm",
    delete: "Delete",
    delete_n_close: "Delete & Close",
    ok: "OK",
    saved: "Saved",
    close: "Close",
    done: "Done",
    language: "Language",
    filter: "Filter",
    product: "Product",
    store: "Store",
    loading: "Loading...",
    manage_product: "Manage Products",
    manage_order: "Order History",
    sales_report: "Sales Report",
    setting: "Settings",
    back: "Back",
    type_here: "Type here...",
    lan: "Language",
    dashboard: "Dashboard",
    printer_setting: "Printer Settings",
    app_setting: "App Settings",
    store_availability_setting: "Store Availability Settings",
    notification: "Notifications",
    warning: "Warning",
    submit: "Submit",
    cancel: "Cancel",
    comment: "Comment",
    special_request: "Special request",
    tel: "Tel",
    addr: "Address",
    addr_short: "Delivery Address",
    street: "Street",
    unit: "Unit",
    buzzer: "Buzz",
    city: "City",
    province: "Province",
    country: "Country",
    not_set: "Not set",
    name: "Name",
    name_date: "Date\\Name",
    phone_number: "Phone Number",
    email: "Email",
    update: "Update",
    skip: "Skip This Version",
    customer: "Customer",
    admin: "Admin",
    system: "System",
    app_version: "Version",
    current: "Current",
    newest: "Newest",
    Skipped: "Skipped",
    app_info: "App Information",
    no_new_noti: "There are no new notifications",
    clear_all: "Clear All",
    select_date: "Select date",
    select_time: "Select time",
    select_all: "Select All",
    from: "From",
    to: "To",
    all: "All",
    uncategorized_items: "Uncategorized Items",
    left_margin: "Left Margin",
    right_margin: "Right Margin",
    top_margin: "Top Margin",
    bottom_margin: "Bottom Margin",
    save: "Save",
    save_n_close: "Save & Close",
    save_n_continue: "Save & Continue",
    localized: "Localized",
    primary: "Primary",
    en: "English",
    zh: "简体中文",
    "zh-Hant": "繁體中文",
    kr: "한국어",
    de: "Deutsch",
    fr: "French",
    disabled: "Disabled",
    margin: "Margin",
    top: "Top",
    bottom: "Bottom",
    left: "Left",
    right: "Right",
    general: "General",
    general_settings: "General Settings",
    qty: "Qty",
    unable_to_load_any_data: "Unable to load data",
    status: "Status",
    new: "new",
    reload: "Reload",
    selected: "Selected",
    items: "Items",
    date: "Date",
    //shipping method
    delivery: "Delivery",
    pickup: "Pick Up",
    eatin: "Dine-in",
    dine_in: "Dine-in",
    in_store: "In Store",
    flatrate: "Flat rate",
    free_shipping: "Digital Asset",
    quick_pay: "Quick pay",
    //payment method
    pay_later: "Card Payment (in person)",
    cash: "Cash",
    credit_card: "Credit card",
    giftcard: "Gift card",
    points: "Points",
    points_and_giftcard: "Points & Gift card",
    wechat_pay: "Wechat Pay",
    paypal_express: "PayPal",
    alipay: "Alipay",
    union_pay: "Union Pay",
    union_pay_express: "Union Pay Express",
    braintree_apple: "Apple Pay",
    braintree_credit_card: "Credit Card",
    braintree_paypal: "PayPal",
    braintree_google_pay: "Google Pay",
    braintree_google_merchant_id: "Google Merchant ID",
    visa: "Visa",
    master: "Master",
    american_express: "American Express",
    discovery: "Discovery",
    jcb: "JCB",
    maestro: "Maestro",
    etransfer: "E-transfer",
    card_present: "Card Present",
    payment_type_kiosk: "Kiosk payment",
    //status
    pending: "Pending",
    processing: "Processing",
    payment_review: "Payment Review",
    completed: "Completed",
    canceled: "Cancelled",
    closed: "Closed",
    pending_payment: "Pending Payment",
    holded: "Holded",
    logout: "Logout",
    logout_confirm_message: "Are you sure you want to logout?",
    force_logout_message:
        "Sorry, your account has been logged in from another device or has timed out. Please login again.",
    mark_as_paid: "Mark as Paid",
    mark_as_unpaid: "Mark as Unpaid",
    //detail
    subtotal: "Subtotal",
    item_total: "Item(s) Total",
    discount: "Discount",
    store_discount: "Store Discount",
    coupon_discount: "Coupon Discount",
    shipping_and_handling: "Shipping & Handling",
    shipping_and_handling_with_distance: "Shipping & Handling ({distance})",
    total_before_tax: "Total Before Tax",
    tax: "Tax",
    order_totals: "Order Totals",
    order_total: "Order Total",
    summary: "Summary",
    gift_card_payment: "Gift card Payment",
    points_payment: "Points Payment",
    "points_payment_with_points": "Payment - Points({points})",
    "giftcard_payment_with_giftcard": "Payment - GiftCard({card})",
    total_paid: "Total Paid",
    total_due: "Total Due",
    tips: "Tip",
    delivery_fee_with_distance: "Delivery Fee ({distance})",
    dlv_fee: "D.fee",
    CD: "CD",
    store_data_error_message: "Sorry, store data could not be loaded, please try again later.",
    //version control
    up_to_date: "Up-to-date",
    no_update_availabe: "No Update Available",
    //app closing
    quit_confirm_message: "Are you sure you want to quit GoopterBiz?",
    incorrect_password: "Incorrect Password",
    server_error: "Server Error",
    //filter
    clear_fliter: "Clear Filter",
    search: "Search",
    first_name: "First Name",
    last_name: "Last Name",
    max_amount: "Max Amount",
    min_amount: "Min Amount",
    today: "Today",
    tomorrow: "Tomorrow",
    yesterday: "Yesterday",
    this_week: "This week",
    last_week: "Last week",
    last_two_weeks: "Last 2 weeks",
    this_month: "This Month",
    last_month: "Last Month",
    this_year: "This Year",
    last_year: "Last Year",
    last_seven_days: "Last 7 Days",
    last_fourteen_days: "Last 14 Days",
    last_thirty_days: "Last 30 Days",
    last_365_days: "Last 365 Days",
    enter_period: "Enter Period",
    last: "Last",
    now: "Now",
    mins: "mins",
    add_x_mins: "+ {x} Mins",
    add_x_hrs: "+ {x} Hrs",
    set_exact_time: "Set Exact Time",
    or: "Or",
    //confirm_go_back
    confirm_go_back_message: "Are you sure you want to abort all the changes and exit this page?",
    confirm_remove_changes: "Confirm Remove Changes",
    //no internet
    no_internet_connection: "No internet connection",
    enter_password: "Enter password",
    unlock: "Unlock",
    test_print_title: "Printing In progress",
    test_print_message:
        "If the test page is not printed, there could be a printer error. Please check your printer and try again.",
    unstable_network: "Slow or unstable network, please check your network connection",
    //error handling
    incorrect_username_or_password: "Incorrect Username or Password",
    account_locked: "Your account is locked, please contact Goopter support to unlock your account first.",
    login_failed_try_later: "Login failed, please try again later.",
    invalid_request_body: "Invalid request",
    unknow_host: "Unknown host",
    over_refund: "Refund amount should not be greater than the total paid amount minus the refunded amount",
    partial_request_not_allowed: "Partial refund is not available for this order",
    braintree_partial_refund_not_allowed: "Les remboursements partiels pour les paiements Braintree ne sont pas pris en charge. Veuillez effectuer un remboursement complet à la place",
    unexpect_error: "An unexpected error occurred",
    update_order_failed: "Update failed, please try again later.",
    refund_not_settled:
        "The payment transaction has been submitted for settlement and not completed yet, please try refund 3 hours later.",
    braintree_error_dialog: "Failed to capture the payment for this order",
    live_order: "Live Order",
    comments: "Comments",
    sales_summary: "Sales Summary",
    show_more: "Show More",
    show_less: "Show Less",
    menu: "Menu",
    insufficient_permissions: "Your account does not have access to this page.",
    save_changes: "Save Changes",
    rate_and_review: "Rate & Review",
    rate_first_review_next: "Rate first review next",
    tell_us_about_our_service: "Tell us about our service",
    click_to_select: "Click to select",
    validate: "Validate",
    unbind: "Unbind",
    welcome: "Welcome",
    home: "Home",
    change: "Change",
    changes: "Changes",
    submitted: "Submitted",
    submit_for_review: "Submit for Review",
    submit_application: "Submit Application",
    pending_for_review: "Pending for Review",
    remove_from_review: "Remove from Review",
    new_store_pending_review: "New Store Application - Pending for review",
    submit_review_success_msg:
        "Thanks for submitting store application. We will review your application and get back to you in 2 business days.",
    edit: "Edit",
    and: "And",
    mon: "Monday",
    tue: "Tuesday",
    wed: "Wednesday",
    thu: "Thursday",
    fri: "Friday",
    sat: "Saturday",
    sun: "Sunday",
    mo: "Mon",
    tu: "Tue",
    we: "Wed",
    th: "Thu",
    frd: "Fri",
    sa: "Sat",
    su: "Sun",
    choose: "Choose",
    add: "Add",
    action: "Action",
    enable: "Enable",
    enabled: "Enabled",
    disable: "Disable",
    view: "View",
    attention: "Attention",
    error: "Error",
    first: "First",
    prev: "Previous",
    next: "Next",
    reset: "Reset",
    alert_are_you_sure: "Are You Sure?",
    alert_leaving_without_save:
        "You have unsaved changes on this page. Do you want to leave this page and discard your changes?",
    franchise_manager: "Franchise Manager",
    franchise_assistant: "Franchise Assistant",
    franchise_cashier: "Franchise Cashier",
    store_manager: "Store Manager",
    store_assistant: "Store Assistant",
    store_cashier: "Store Cashier",
    delivery_manager: "Delivery Manager",
    order_manager: "Order Manager",
    delivery_staff: "Delivery Staff",
    super_user: "Super User",
    super_admin: "Super Admin",
    group_sale_manager: "Group Sale Manager",
    group_sale_product_owner: "Product Owner",
    alert_no_result_found: "No Result Found",
    alert_load_failed: "Failed to load record, please try again later",
    copyright: "Copyright",
    all_rights_reserved: "© 2015-2019 Goopter Holdings Ltd. All rights reserved.",
    on: "On",
    off: "Off",
    coupon: "Coupon",
    groupsale: " Groupsale",
    products: "Products",
    coupons: "Coupons",
    giftcards: "Gift Cards",
    groupsales: "Groupsales",
    product_detail: "Product Detail",
    coupon_detail: "Coupon Detail",
    giftcard_detail: "Gift Card Detail",
    groupsale_detail: "Groupsale Detail",
    product_information: "Product Information",
    coupon_information: "Coupon Information",
    giftcard_information: "Gift Card Information",
    reward_coupon_code: "Reward Coupon Code",
    coupon_hint_message: "Text message with coupon info template",
    coupon_expire_dt: "Coupon Expiration date",
    groupsale_informaton: "Groupsale inforamtion",
    product_name: "Product Name",
    coupon_name: "Coupon Name",
    giftcard_name: "Gift Card Name",
    groupsale_name: "Groupsale Name",
    product_type: "Product Type",
    coupon_type: "Coupon Type",
    giftcard_type: "Gift Card Type",
    groupsale_type: "Groupsale Type",
    ko: "Korean",
    hi: "Hindi",
    ja: "Japanese",
    order_type: "Order Type",
    type: "Type", // Note usage of this "Type" for chinese corresponds to "Order Type"
    payment_method: "Payment Method",
    paylater: "Pay Later With Card",
    free_payment: "Free Payment",
    unionpay_debit: "Unionpay Debit Card",
    unionpay_credit: "Unionpay Credit Card",
    previous: "Previous",
    forget_password: "Forget Password",
    phone_code_canada: "Canada +1",
    phone_code_united_states: "United States +1",
    password_empty_feedback:
        "Create a strong password using uncommon words and try not to use passwords from other sites!",
    c2c_setting: "Country to country setting",
    store_front: "Store Front",
    store_front_shared: "Store Front (Shared)",
    store_front_independent: "Store Front (Independent)",
    sign_out: "Sign Out",
    due_time: "Due Time",
    quick_add: "Quick Add",
    pos_mode: "POS Mode",
    total: "Total",
    dasher_info: "Dasher Info",
    all_categories: "All Categories",
    copy_to_new_product: "Copy to New Product",
    view_edit: "View/Edit",
    product_copied: "Product Copied",
    product_copied_content: "Product copied successfully. Make any necessary changes and save to reflect changes.",
    update_price: "Update Price",
    update_stock: "Update Stock",
    update_availability: "Update Availability",
    update_tax: "Update Tax",
    set_enabled: "Set Enabled",
    set_disabled: "Set Disabled",
    products_deleted: "Deleted {count} products.",
    products_updated: "Updated {count} products.",
    products_deleted_message: "The following products have been deleted successfully: {success}.",
    products_updated_message: "The following products have been updated successfully: {success}.",
    increase_or_decrease_message: "Increase or decrease the price of the selected products.",
    enter_the_value: "Enter the value",
    select_to_round: "Select to round the price of the product.",
    round_the_price: "Round the price",
    choose_percentage_amount: "Choose whether you want to update the price by percentage or amount.",
    by_percentage: "By percentage",
    by_amount: "By amount",
    no_rounding: "Don't round",
    round_to_dollar: "Nearest dollar",
    round_to_one_decimal: "Nearest 10 cents",
    round_to_two_decimals: "Nearest cent",
    set_amount: "Set amount",
    increase: "Increase",
    decrease: "Decrease",
    confirm_delete_products: "Delete Products",
    confirm_delete_products_content: "Are you sure you want to delete the selected {count} products?",
    confirm_enable_products: "Enable Products",
    confirm_enable_products_content: "Are you sure you want to enable the selected {count} products?",
    confirm_disable_products: "Disable Products",
    confirm_disable_products_content: "Are you sure you want to disable the selected {count} products?",
    size: "Size",
    number: "Number",
    color: "Color",
    material: "Material",
    length: "Length",
    width: "Width",
    scent: "Scent",
    flavor: "Flavor",
    model: "Model",
    format: "Format",
    platform: "Platform",
    addon: "Add on",
    edition: "Edition",
    presets: "Presets",
    cooking_style: "Cooking Style",
    option: "Option",
    item_title_missing_language_content:
        "The item does not contain a(n) {changedLanguagesFullNames} title. The {languagesFullNames} title, {values}, will be used as the item's {changedLanguagesFullNames} title. Are you sure you want to proceed with these changes?",
    item_title_language_content:
        "The Item name has been changed to {changed} in {changedLanguagesFullNames}, but still remains as {values} in {languagesFullNames}. Are you sure you want to proceed with these changes?",
    option_title_language_content:
        "The Option name has been changed to {changed} in {changedLanguagesFullNames}, but still remains as {values} in {languagesFullNames}. Are you sure you want to proceed with these changes?",
    select_details_to_copy: "Select details to copy",
    message_disabled_warning:
        "Your notification setting is turned off; thus, you will not receive new or update order notifications. Please be sure to enable the notification setting to get notified.",
    dismiss: "Dismiss",
    discard: "Discard",
    images: "Images",
    show_images: "Show Images",
    move_category: "Move Category",
    move_category_confirm: "Are you sure you want to move this catergory?",
    upsell_products: "Up-Sell Products",
    recommend_a_good_restaurant: "Recommended you a good store",
    recommend_a_good_merchat: "Recommended you a good merchant",
    recommend_a_good_product: "Recommended you a good product",
    guest_user: "Guest User",
    image_quality: "Image Quality",
    low: "Low",
    medium: "Medium",
    high: "High",
    order_from: "Order From",
    os: "OS",
    browser: "Browser",
    ip_address: "IP",
    copy_what: "Copy {what}",
    copied_to_clipboard_success: "{what} is copied",
    copied_to_clipboard_failed: "Failed to copy {what}",
    success: "Success",
    failed: "Failed",
    no_record: "No records found",
    try_other_filters: "Try changing the filters or search term.",
    view_all_orders: "View all orders",
    read_only_message: "Your current permission is read-only for this page and not allowed to make a change.",
    online: "Online",
    offline: "Offline",
    no_authorization: "You are not authorized to access this page",
    only_jpg_png: "Only JPG and PNG files may be uploaded",
    must_be_lt_2m: "The file size must not exceed 2MB",
    hide_disabled_products: "Hide disabled products",
    show_hidden_products: "Show hidden products",
    enabled_status: "Enabled Status",
    enabled_products: "Enabled Products",
    disabled_products: "Disabled Products",
    product_visibility: "Product Visibility",
    visible_products: "Visible Products",
    hidden_products: "Hidden Products",
    load_more: "Load More",

    sign_in_welcome: "Welcome to Goopter",
    sign_in_head_hint: "Enter your credentials to login.",
    login: "Login",
    username: "Username",
    password: "Password",
    signup: "Sign Up",
    forgot_password: "Forgot Password",
    by_email: "By Email",
    by_phone: "By Text Message",
    signup_instead: "Sign Up Instead",
    signin_instead: "Sign In Instead",
    self_serve_signup: "Self-serve Sign Up",
    assisted_signup: "Assisted Sign Up",
    self_serve_signup_desc:
        "Set up your online store by yourself. Input your business information and product information on your own.",
    assisted_signup_desc:
        "Provide basic information about your business and let us contact you to assist you with your online store set up.",
    store_address: "Store Address",
    floor_suite_optional: "Floor/Suite (Optional)",
    business_type: "Business Type",
    go_back: "Go Back",
    verification_email_sent: "Verification Email Sent",
    verification_email_sent_desc:
        "An email with a verification link has been sent, please follow the directions in the email to confirm your account. ",
    store_sign_up: "Store Sign Up",
    price_plan_contact_details: "Price Plan & Contact Details",
    store_type_categories: "Store Type & Categories",
    contact_name: "Contact Name",
    contact_number: "Contact Number",
    number_of_locations: "Number of Locations",
    current_revenue_optional: "Current Revenue (Optional)",
    store_categories: "Store Categories",
    does_delivery: "Does Delivery",
    assisted_signup_complete: "Assisted Signup Complete",
    assisted_signup_complete_desc: "We will contact you to help you setup your store.",
    cashondelivery: "Cash",
    paypal_pro: "PayPalPro",
    braintree: "Braintree",
    ccsave: "Card",
    online_card: "Credit Card (Online)",
    giftcard_ponts: "GiftCard|Points",
    wechatpay: "WeChat",
    goopterexpress: "PayPal",
    free: "No Charge",
    unionpay: "UnionPay",
    unionpayexpress: "UnionPay",
    printer: "Printer",
    staff: "Staff",
    operation: "Operation",
    hourly: "Hourly",
    attendance: "Attendance",
    export_type: "Export Type",
    includes_order_detail: "Includes Order Detail",
    show_report: "Show Report",
    print_report: "Print Report",
    export_report: "Export Report",
    report_ppcp_footnote: "* PPCP: PayPal Complete Payments",
    tippings: "Tippings",
    staff_report: "Staff Report",
    total_cnt: "Count",
    total_amt: "Amt",
    total_tips: "Tips",
    time: "Time",
    amt: "Amt",
    daily_summary: "Daily total",
    period_summary: "Total",
    please_select_a_printer: "Please select a printer",
    report_type: "Report Type",
    normal_staff_report: "Staff Sales Detail",
    staff_summary: "Staff Sales Summary",
    customer_summary: "Self Order Sales Summary ",
    sales: "Sales",
    update_categories: "Update Categories",
    update_category_ids: "Update Categories",
    store_switch: "Store Switch",
    store_switch_successful: "Store Switch Successful",
    switched_to_store: "Switched to store {name}.",
    switch_approved: "Switch Approved",
    switch_wip: "Switch WIP",
    wip_store: "Work in Progress Stores",
    approved_store: "Approved Stores",
    business_name: "Business Name",
    business_bio: "Business Bio",
    business_address: "Business Address",
    address: "Address",
    address_line_1: "Address Line 1",
    address_line_2: "Address Line 2",
    postal_code: "Postal Code",
    contact_information: "Contact Information",
    phone: "Phone",
    twitter: "Twitter",
    instagram: "Instagram",
    facebook: "Facebook",
    website: "Website",
    store_type_and_categories: "Store Type and Categories",
    restaurant: "Restaurant",
    shopping: "Shopping",
    service: "Service",
    time_zone: "Time Zone",
    latitude: "Latitude",
    longitude: "Longitude",
    store_information: "Store Information",
    store_information_description:
        "Your company's name and contact information establishes your store's professional presence and will appear on customer receipts.",
    hour: "Business Hours",
    store_images: "Store Images",
    price_plan_and_currency: "Price Plan, Tax ID, Currency Setting",
    owner: "Contact Person",
    store_name: "Store Name",
    seo_title: "Search Engine Optimization",
    seo: "SEO",
    seo_description:
        "Specify URL key and meta data fields used by search engines to index the product. The best practice is to incorporate high-value keywords in both meta data and meta description.",
    currency_vat_number: "Currency and VAT Number",
    currency_vat_number_description:
        "Value Added Tax number used for showing VAT number on the invoice, e.g. GST number.",
    price_plan: "Price Plan",
    price_plan_description: "Price plan determines the service features and fees for your online store.",
    meta_title: "Meta Title",
    meta_keywords: "Meta Keywords",
    meta_description: "Meta Description",
    url_key: "Url Key",
    search_engine_optimization: "Search Engine Optimization",
    currency: "Currency",
    vat: "VAT Number",
    details: "Details",
    store_pa: "Store Announcement",
    store_pa_en_placeholder: "Type here...",
    public_announcement_description: "Enter a brief announcement you want customers to see on the Home Page.",
    branding: "Branding",
    branding_description:
        "Your brand information applies to the look and feel of your receipts, invoices, appointment bookings, and marketing.",
    logo: "JPG Logo",
    banner_desktop: "Banner (Desktop)",
    banner_desktop_description:
        "This image appeared in the header section of the home page on the desktop browser. The recommended size is 1234 x 345px.",
    banner_mobile: "Banner (Mobile)",
    banner_mobile_description:
        "This image appeared in the header section of the home page on the mobile browser. The recommended size is 678 x 167px.",
    store_images_description: "Add images to showcase your store. You can view the image by clicking on it.",
    display_options: "Display Options",
    product_display_format: "Product Display Format",
    product_detail_display_format: "Product Detail Display Format",
    product_detail_display_format0: "Open pop up",
    product_detail_display_format1: "Open full details page in a new tab",
    product_detail_display_format2: "Open full detail page on the same window with details",
    product_detail_display_format3: "Click pic to open pop up, click title to open full detail page on new tab",
    product_detail_display_format4: "Click pic to open pop up, click title to open full detail page on same page",
    product_detail_display_format5: "Click title to open pop up, click pic to open full detail page on new tab",
    product_detail_desc:
        "Specify how the product detail page will be displayed when opened when a user clicks the product cell on the product list page.",
    store_qty_display: "Stock Quantity Display",
    store_qty_display_amount: "Stock Quantity Display Threshold",
    store_qty_display_tip: "Determine when to show remaining quantity.",
    store_qty_display_amount_tip: "Show remaining quantity when stock is less than:",
    sold_qty_display: "Product Sold Quantity Display",
    sold_qty_display_amount: "Sold Quantity Display Threshold",
    sold_qty_display_tip: "Determine when to show the quantity each product has sold.",
    sold_qty_display_amount_tip:
        "Show the sold quantity when the sold quantity is greater than or equal to this value.",
    dont_show_stock_quantity: "Don't show stock quantity",
    always_show_stock_quantity: "Always show stock quantity",
    dont_show_sold_quantity: "Don't show sold quantity",
    always_show_sold_quantity: "Always show sold quantity",
    show_stock_quantity_when_under_certain_amount: "Show stock quantity when it's less than certain amount",
    show_sold_quantity_when_over_certain_amount: "Show sold quantity when it's greater than certain amount",
    regular: "Regular Style",
    super_market_style: "Large Image Style",
    one_page_store: "One Page Store",
    regular_style_hidden_cat: "Regular With Hidden Category",
    horizontal_category_display: "Horizontal Category Display",
    no_store_info_and_review_tab: "No store info and review tabs",
    with_store_info_tab_no_review_tab: "With store info tab, no review tab",
    with_store_info_and_review: "With store info and review tabs",
    product_display_languages: "Product Display Languages",
    default_language: "Default Language",
    store_display_style: "Store Display Style",
    product_display_format_tip:
        "On mobile browsers, the Supermarket Style allows switching between grid view (default) and list view. Regular Style only shows the product list in list view.",
    pagination: "Pagination",
    pagination_tip:
        "Pagination is normally used to support more than 150 products. When enabled, products will be loaded based on the selected categories and limited to 20 products per page; When disabled, then all products will be loaded at once.",
    show_product_place_holder_image: "Show Product Placeholder Image.",
    show_product_place_holder_image_tip: "Display a placeholder image for each product if an image is not provided",
    hot_category_enabled: 'Enable "Hot Sale" Category.',
    onsale_category_enabled: 'Enable "On Sale" Category.',
    hot_category_enabled_tip:
        "The hot sale category shows the top 10 most popular items from your online store based on order volume",
    onsale_category_enabled_tip:
        "The on sale category shows the top 10 most discounted items from your online store based on their discount amounts",
    use_land: "Use landing page",
    use_land_tip:
        "Add a landing page to capture your visitors' attention. Please follow the landing page syntax or contact support@goopter.com or call 778-379-7918 for help.",
    payment_options: "Payment Options",
    accept_credit_card: "Accept Credit Card",
    accept_cash: "Accept Cash",
    allow_pay_later: "Allow Pay Later With Card",
    allow_qr_checkout: "Virtual POS Terminal",
    setup_fee: "Setup Fee",
    monthly_fee: "Monthly Fee",
    maximum_languages: "Maximum Languages",
    price_range: "Price Range",
    quantity_range: "Quantity Range",
    under_10: "Under $10",
    "10_30": "$10-30",
    "30_60": "$30-60",
    "60_99": "$60-99",
    above_100: "Above $100",
    value_to_points_ratio: "Order amount to points ratio",
    points_to_value_ratio: "Points to currency unit ratio",
    points_to_value_ratio_tip:
        "Points to currency unit ($, ¥, €, etc.) ratio indicates how many points is equivalent to one currency unit. If the value of the field is set to 10 then every 10 reward points will equal 1 currency unit. (i.e. 10 points = $1)",
    value_to_points_ratio_tip:
        "Order amount (product total) to points ratio indicates how many points the customer will receive based on how much he/she spends. It should be a number between 0 and 999. If the value of the field is set to 10 and the customer’s order amount is $50, he/she will receive 500 points when the order is completed.",
    value_to_points_ratio_alert_integer: "Value To Points Ratio must be an integer",
    value_to_points_ratio_alert_range: "Value To Points Ratio should be a number between 0 and 999",
    allow_online_order: "Allow Online Order",
    allow_online_order_tip: "Allow Online Order Tip",
    allow_reward_points_tip:
        "The Reward Points helps increase customer loyalty and attract new customers. To enable Reward Points, you need to make a deposit at Goopter, please call support 778-379-7918 to turn on the Reward Points feature for your store",
    allow_order_when_store_closed: "Allow Pre-order When Store Closed",
    allow_preorder_time: "Pre-order x Minutes Before Store Opens",
    anytime: "Anytime",
    not_allowed: "Do not allow pre-order",
    tips_settings: "Tips Settings",
    enable_tips: "Enable Tips",
    no_tips: "No Tip",
    order_limit: "Order limit for triggering tips rule",
    hint_order_limit:
        "If the item total exceeds this limit, the consumer side will show tips options as percentages. Otherwise, tips options show as dollar amounts.",
    default_percentage_values: "Default percentage values",
    hint_default_percentage_values:
        "The default percentage values when the order amount is over the specified limit (order type sequence as  delivery, in store and pick-up)",
    default_amount_values: "Default amount values",
    hint_default_amount_values:
        "The default amount values when the order amount is less than the specified limit (order type sequence as  delivery, in store and pick-up)",
    tips_in_percentage: "Tips in percentage option",
    hint_tips_in_percentage:
        "The percentage options when the order amount is over the specified limit (default values are 0, 10, 12, 15, 20)",
    tips_in_dollar_values: "Tips in dollar values",
    hint_tips_in_dollar_values:
        "The dollar amount values when the order amount is less than the specified limit (default values are 0, 2, 3, 4, 5)",
    settings_overview: "Settings Overview",
    nav_dashboard: "Dashboard",
    business_features: "Business Features",
    restaurant_extras: "Restaurant Extras",
    store_configuration: "Store Configuration",
    allow_order_online_description:
        "You can toggle online ordering on or off. When set to off, items will still be on your online store but will not be available for ordering.",
    unpaid_order_cancellation: "Order cancelation time for unpaid online payment type",
    unpaid_order_cancellation_desc:
        'After a set amount of time, the system will automatically cancel an order where the "Online Payment" type was selected but has still not been paid for.',
    outdoor_seats: "Has Outdoor Seats",
    accept_qr_checkout: "Accept QR Checkout",
    accept_qr_tip:
        "The QR Checkout is to allow your customers scan a provided QR code to make payment through their mobile phone, time saving for your cashier.",
    eatin_area_setting: "Eat-in Area Settings",
    good_for_business: "Good for business",
    estimate_preparation_time: "Estimated Preparation Time (Minutes)",
    estimate_preparation_tip: "",
    delivery_setting: "Delivery Settings",
    delivery_time_delta: "Delivery Time Range (Minutes)",
    delivery_time_delta_tip:
        "The selectable time range for an order to be delivered to its destination. e.g. Setting this to 10 will display time slots as 10:00-10:10, 10:10-10:20…",
    allow_pick_up: "Allow Pick Up",
    allow_out_call: "Allow Out Call",
    pickup_time_delta: "Pick-up Time Range (Minutes)",
    outcall_time_delta: "Outcall Time Range (Minutes)",
    pickup_time_delta_tip:
        "The pick-up time range setting is the time delta (in minutes) for pick-up. for example, 10 can result in available time slots like 10:00-10:10, 10:10-10:20 ... 15 can result in available time slots like: 10:00-10:15, 10:15-10:30.. etc.",
    outcall_time_delta_tip:
        "The outcall time range setting is the time delta (in minutes) for outcall. for example, 10 can result in available time slots like 10:00-10:10, 10:10-10:20 ... 15 can result in available time slots like: 10:00-10:15, 10:15-10:30.. etc.",
    allow_up_sale: "Allow Up-selling",
    up_sale_tip:
        "Up-selling is a common strategy for businesses to boost their revenue & profits, normally choose the related products that buyers are likely to purchase as the up-sell products, so buyer can possibly add these items into the cart before they checkout.",
    min_delivery_amount: "Minimum Delivery Amount",
    min_pickup_amt: "Minimum Pick-up Amount",
    store_features: "Store Features",
    nagative_number_alert: "Should be a positive number",
    "3rd_party_local_delivery_setting": "3rd PARTY LOCAL DELIVERY SETTINGS:",
    prepare_order_threshold_desc:
        "How much time in advance your store will typically begin preparing a scheduled order. A reminder will show if the order is not set to “start preparing” on time.",
    surcharge_label_name: "Surcharge name",
    surcharge_order_type_any: "Any Orders",
    surcharge_amount: "Surcharge amount",
    surcharge_type: "Surcharge type",
    surcharge_min: "Surcharge min",
    surcharge_max: "Surcharge max",
    surcharge_tax_class: "Surcharge tax class",
    surcharge_order_type: "Surcharge Order Type",
    language_tooltip_surcharge: "You can find the languages that you have localized in this menu",
    language_tip:
        "Please click the corresponding language to view/edit the language specific fields, the following fields support multi-language values:  Store name, Store Description, Store announcement, Store discount",
    notice_infomation: "Notice Infomation",
    last_publish_time: "Last Publish Time:",
    meta_title_tip:
        "The Meta Title is the text that appears at the top of the browser window. You can use the default, which is based on the Product Name, or change it as needed",
    meta_title_tip_content_pages:
        "The meta title is the text that appears at the top of the browser window. You can use the page name as the default value, or change it as needed",
    meta_keywords_tip:
        "The Meta Keywords are used by some search engines more than others. Enter a few high-value key words to help the product gain more visibility",
    meta_keywords_tip_content_pages:
        "The Meta Keywords are used by some search engines more than others. Enter a few high-value key words to help the page gain more visibility",
    meta_description_tip:
        "The Meta Description is the text that appears in search results listings. For best results, enter a description that is between 150-160 characters in length",
    republish_tip:
        "Your store can only be viewed by consumer after the publish, to hide the public view, you may do un-publish",
    store_menu_tip:
        "If you have multiple menus in your store, you may select the corresponding menus and allow your customer to switch between different menus for ordering",
    store_type_categoreis_tip: "You may choose up to three categories of your store",
    vat_number_tip: "Value Added Tax number used for showing VAT number on the invoice, e.g. GST number",
    search_engine_optimization_tip:
        "The Search Engine Optimization section specifies the URL Key and meta data fields that are used by search engines to index the product. The current SEO best practice is to incorporate high-value keywords in both the meta title and meta description.",
    c2c_shipping_product_tip:
        "The Country to Country shipping setting on the product level will overwrite the setting on the store level, if the product shipping rate is the same as the store level, then you don't need to set shipping rate on the product level.",
    alert_time_overlapping: "Input time frame is overlapping",
    alert_update_failed: "Update Failed! Please check your input!",
    alert_success_publish: "Publish All Succeed!",
    alert_success_publish_no_delivery:
        "Your store has been successfully published. However, we have turned off your delivery services because delivery hours have not been defined. If delivery is wanted, please turn delivery on in the settings and then add delivery hours.",
    alert_failed_publish: "Publish All Failed!",
    alert_success_unpublish: "Unpublish All Succeeded!",
    alert_failed_unpublish: "Unpublish All Failed!",
    alert_select_store_type: "Please choose at least one store type to proceed!",
    alert_success_submit_for_review: "Submitted for Review!",
    alert_success_unsubmit: "Unsubmitted!",
    alert_failed_submit_for_review_failed: "Failed to Submit for Review!",
    alert_failed_unsubmit: "Failed to Unsubmit",
    apply_new_branch: "Apply New Branch",
    new_store: "New",
    store_pending: "Pending",
    store_rejected: "Rejected",
    store_approved: "Approved",
    store_prepare_for_submission: "Prepare for Submission",
    store_pending_approval: "Pending Approval",
    store_info: "Store Information",
    publishing: "Publishing",
    publish: "Publish",
    unpublish: "Un-publish",
    republish: "Re-publish",
    unsubmit: "Unsubmit",
    store_status: "Store Status: ",
    general_information: "General Information",
    store_type: "Store Type",
    store_menu: "Store Menu",
    store_image: "Store Images",
    open_hour: "Open Hours",
    delivery_hour: "Delivery Hour",
    service_information: "Service Settings",
    order_notification: "Order Notification",
    payment_setting: "Payment Setting",
    discount_public_notice: "Public Notice",
    account_information: "Account Information",
    store_contact_number: "Store Contact Number",
    store_website_url: "Store Website URL",
    store_website_url_tip:
        "The website URL is tied with your domain name, please contact support@goopter.com or call 778-379-7918 if you need to make a change.",
    store_h5_url: "Store Mobile Website URL",
    store_mobile_website_url_tip: "The url of the mobile version of the website",
    contact_person: "Contact Person",
    currency_and_vat_number: "Currency And VAT Number",
    select_a_currency: "Select A Currency",
    vat_number: "VAT Number",
    store_type_subtype: "Store Type & Categories",
    no_image: "No Image",
    image_hint_do_not: "This product doesn't have any images",
    open_24_hours: "Open 24/7",
    open: "Open",
    opens_at: "Opens at",
    closes_at: "Closes at",
    add_hours: "Add hours",
    delivery_24_hours: "Delivery 24/7",
    street_address: "Street Address",
    buzz: "Buzz #",
    timezone: "Timezone",
    post_code: "Postal Code",
    basic_setting: "Basic Settings",
    is_hold_ordering: "Pause Online Orders",
    stop_order_minutes_before_close: "Stop Orders X Minutes Before Store Closes",
    order_expiration_limit:
        "Waiting time for automatic cancellation of unpaid orders (limited to Alipay, WeChat, UnionPay payment methods)",
    above_60: "above $60",
    allow_reward_points: "Allow Reward Points",
    ranking: "Ranking",
    require_billing_address: "Require Full Billing Address",
    require_billing_address_tip:
        "Merchants are advised to request a full billing address to enable strict billing address verification. When the full billing address is not required, the customer only needs to provide the zip code of the billing address. Please note that when full billing address is not required, there's a higher risk for fraud and merchants needs to take their own responsibility for the fraudulent transaction.",
    accept_wechat_pay: "Accept Wechat Pay",
    online_status: "Online status",
    allow_apple_pay: "Allow Apple Pay",
    allow_take_out: "Allow Take-out",
    allow_in_store_purchase: "Allow In-store Purchase",
    allow_in_store_service: "Allow In-store Service",
    allow_eat_in: "Allow Eat-in",
    require_table_number: "Require Table Number For Eat In Order",
    allow_delivery: "Allow Delivery",
    delivery_distance: "Flat Fee Delivery Distance",
    delivery_fee: "Base Delivery Fee",
    delivery_fee_tip: "Base delivery fee is the amount charged for delivery within the flat fee delivery distance.",
    extra_dollar_amount: "Extra Dollar Amount Per Kilometer",
    estimate_delivery_time: "Estimate Delivery Time (Minutes)",
    max_delivery_distance: "Max Delivery Distance",
    allow_3rd_party: "Use 3rd party delivery",
    minutes: "Minutes",
    free_services: "FREE SERVICES:",
    tv_available: "TV available",
    wifi: "Wifi",
    alcohol: "Alcohol",
    no_such_info: "No such info",
    beer_wine_only: "Beer & Wine Only",
    full_bar: "Full Bar",
    noise_level: "Noise Level",
    quiet: "Quiet",
    average: "Average",
    loud: "Loud",
    very_loud: "Very Loud",
    environment: "Environment",
    intimate: "Intimate",
    hipster: "Hipster",
    classy: "Classy",
    upscale: "Upscale",
    parking: "Parking",
    free_lot: "Free Lot",
    paid: "Paid",
    allow_waitlist: "Allow Waitlist",
    allow_office_delivery: "Allow Fixed Location Delivery",
    waiter_service: "Waiter Service",
    take_reservation: "Takes Reservation",
    bike_parking: "Bike Parking",
    good_for_group: "Good For Group",
    good_for_kids: "Good For Kids",
    casual_attire: "Attire",
    casual: "Casual",
    dressy: "Dressy",
    notify_by_text_message: "Notify by text message",
    receive_order_by_sms: "Receive order notification by regular SMS text message.",
    receive_sms_to: "Receive SMS notifications to:",
    notify_by_email: "Notify by email",
    notify_by_email_tip: "In order to turn on email notification, please provide a valid email in the profile first",
    receive_order_by_email: "Receive order notification by Email",
    receive_email_to: "Receive Email notifications to:",
    notify_by_push: "Notify by Push notification",
    notify_by_push_tip:
        "In order to enable the push notification feature, you need to download and install the Goopter biz app, and login with your admin account",
    receive_order_by_phone: "Receive order notifications to your iPhone or Android device via push notification.",
    order_printing: "Order Printing",
    smart_printer_setting: "Smart Printer Setting",
    auto_print_printer: "Auto print from All-in-1 printer",
    auto_print_browser: "Auto print order from browser",
    printer_language: "Printer Language",
    primary_language: "Primary Language",
    secondary_language: "Secondary Language",
    select_a_language: "Select a Language",
    printer_selection: "Printer Selection",
    select_a_model: "Select a model",
    gt6000sw: "GT6000SW",
    fcs10w: "FCS10W",
    printer_model: "Printer Model",
    printer_access: "Printer Access",
    no_flex_tip: "Flex Plan is needed to access this feature",
    "customer_order_notification_by_text_message": "Customer Order notification by Text Message",
    "order_notification_confirmation": "Order Notification Validation",
    "enable_notification_validation": "Enable Notification Recipient Validation",
    "enable_notification_validation_tip":
        "When this is enabled, the system verifies that order notifications have been received by online users. If the verification still fails after three attempts, an email and text message reminder will be sent to admin users.",
    flex: "Flex",
    use_3rd_party_tip:
        "3rd party shipping is to use the shipping service from 3rd parties, e.g. FedEx, UPS etc. When you turn on the 3rd party delivery, you need to setup the shipping account in Settings --> Shipping",
    logo_resolution_tip:
        "The logo image will be displayed on top of your online store. You may only have one logo uploaded. Saving a new logo will replace the existing one. We recommend a square shape with a resolution of 256px x 256px with jpg or png file types.",
    header_resolution_tip:
        "This banner image will be displayed on the top of your computer browser version online store. You may only have one banner uploaded. Saving a new banner will replace the existing one. A default banner is provided if none are uploaded. We recommended a resolution of 1920px x 190px with jpg or png file types",
    header_resolution_mobile_tip:
        "This banner image will be displayed on the top of your mobile version online store(touch screen smart phone or tablet). You may only have one banner uploaded. Saving a new banner will replace the existing one. We recommended a resolution of 1024px x 256px with jpg or png file types",
    store_images_info:
        "Add photos to showcase your store. You can move the ordering by clicking on an image and dragging it.",
    is_hold_ordering_tip: "Turn on this switch to temporarily pause online orders",
    add_replace_image: "Add/Replace Image",
    add_image: "Add Image",
    uploading: "Uploading...",
    store_logo: "JPG format Store Logo",
    store_logo_hint:
        "The jpg format of the logo is displayed on your customer website, please make sure it’s square and best with a circled border. Recommended size is 500px * 500px.",
    qr_logo: "PNG Logo",
    qr_logo_hint:
        "The png format of the logo is used as part of the store QR code, it should match the jpg format of the logo. Please make sure it has transparent background outside the circle border.",
    store_header: "Store Banner (Desktop)",
    store_header_mobile: "Store Banner (Mobile)",
    store_photos: "Store Photos",
    alert_close_after: "The closing time must be earlier than next opening time",
    alert_close_before_4am: "The overnight closing time must be before 4am",
    alert_open_after: "The opening time must be earlier than the closing time",
    alert_empty_time: "The time field can not be empty or '__:__'",
    alert_min_working_time: "The close time should be at least 30 minutes later than open time",
    price_plan_id_26: "Flex Plan",
    price_plan_id_22: "Comprehensive",
    price_plan_id_21: "Basic Plan - 50 product",
    price_plan_id_20: "Free Trial",
    price_plan_id_0: "One-page business info Free Listing",
    landing_page: "Landing Page",
    landing_page_desktop: "Desktop Landing Page",
    landing_page_mobile: "Mobile Landing Page",
    goopter_delivery: "Goopter Delivery",
    store_id: "Store ID",
    auto_create_task: "Auto create delivery task",
    _placeholder: "type here...",
    store_information_saved: "Store Information updated successfully. ",
    general_setting: "General",
    taxes_setting: "Taxes",
    item_setting: "Item",
    business_hours: "Business Hours",
    shipping_setting: "Shipping",
    permission_setting: "Permissions",
    pickup_delivery_setting: "Pick-up and Delivery Settings",
    menu_setting: "Menu Settings",
    self_serve_ordering_setting: "On-Premise Self-Serve Ordering",
    availability_setting: "Availability Code/Time Mapping",
    managed_stores_setting: "Managed Stores",
    notifications_setting: "Notification Settings",
    public_notice_setting: "Public Notice",
    other_setting: "Other",
    general_setting_description:
        "Customize and edit information like store address, and contact details. Add a brief bio about your business.",
    taxes_setting_description: "",
    item_setting_description:
        "Manage your product inventory, allow customers to add extra features to their orders. Update stock settings, item status, etc.",
    business_hours_description: "Let your clients know when you are open. Choose when to provide delivery services.",
    shipping_setting_description:
        "Enable third party shipping services like FedEX, UPS, and more. Set delivery fee rates and the minimum order amount for free delivery.",
    permission_setting_description: "Manage permissions for different types of admin users.",
    pickup_delivery_setting_description:
        "Customize your pick-up and delivery settings to suit your business and customer needs.",
    menu_setting_description: "Create multiple menus, update menu categories, select order type for each menu.",
    self_serve_ordering_setting_description:
        "Reduce in-person contact for self-serve customers. Easily create and manage QR codes for each station.",
    availability_setting_description:
        "Let customers know when a certain type of menu is served. Specify availability days and hours for different menus.",
    managed_stores_setting_description:
        "A franchise manager can manage orders from multiple stores. Choose the stores that you want to manage.",
    notifications_setting_description:
        "Allow push notifications if you want to receive an update on order statuses, and any other changes.",
    public_notice_setting_description: "Enter a brief announcement you want customers to see on the Home Page.",
    select_order_types: "Select order types",
    other_setting_description: "",
    payment_options_description:
        "Give your customers more options and increase sales. Enable payment options that are applicable at your store.",
    display_options_description:
        "Customize display settings of your store including a default language, store display style, product display format, pagination and other.",
    tips_settings_description:
        "Predefine the default tip amount in percentage and dollar value for delivery, pick up and in-store orders.",
    availability_settings: "Availability Settings",
    availability_description:
        "Availability Code provides a unique code that represents the available time for each day of the week. If your business has products only available for ordering at a specific day or time, you may define an Availability Code and assign it to the corresponding product or category.",
    actions: "Actions",
    code: "Code",
    hours: "Hours",
    business_hours_description_short: "Let your clients know when you're open.",
    delivery_hours: "Delivery Hours",
    delivery_hours_description: "Select the hours when your business provides delivery.",
    store_description: "Store Description",
    includes_tips: "Includes Tips",
    "show_tax_details": "Show Tax Details",
    "tax_details": "[TAX Details]",
    "tax_total": "TAX Total",
    entire_sales_summary: "Entire Sales Summary",
    entire_store_sales_detail: "Entire Store Sales Detail",
    staff_sales_detail: "Staff Sales Detail",
    self_order_sales_summary: "Self Order Sales Summary",
    allow_takeout: "Allow Pick-up",
    specify_pickup: "Allow Multiple Pick-up Locations",
    specify_pickup_desc:
        "Enable this setting to specify additional times and locations for pickup other than your default store address.",
    location_name: "Area Name",
    days: "Days",
    delete_availability_code: "Delete Availability Code",
    availability: "Availability",
    add_pickup_availability: "Add Pick-up Time",
    pickup_time_range: "Pick-up Time Range",
    pickup_time_range_desc:
        "The pick-up time range setting is the time delta (in minutes) for delivery. For example, 10 can result in available time slots like 10:00-10:10, 10:10-10:20.",
    min_pickup_amount: "Minimum Pick-up Amount",
    estimated_preparation_time: "Estimated Preparation Time",
    estimated_preparation_time_desc: "The average time it takes the store to prepare an order.",
    specify_delivery: "Area Based Flat Delivery Fee",
    specify_delivery_desc:
        "If your delivery time and the fee are based on the delivery zone, please turn on this switch and define the delivery zone, delivery fee, and time for each zone.",
    zone_name: "Delivery Zone Name",
    add_delivery_availability: "Add Delivery Availability",
    flat_delivery_fee_distance: "Flat Delivery Fee Distance",
    base_delivery_fee: "Base Delivery Fee",
    extra_dollar_per_km: "Extra Dollars Per Kilometer",
    delivery_time_range: "Delivery Time Range",
    delivery_time_range_desc:
        "The selectable time range for an order to be delivered to its destination. e.g. Setting this to 10 will display time slots as 10:00-10:10, 10:10-10:20…",
    fee_delivery_amount: "Minimum Delivery Amount",
    free_delivery_amount: "Free Delivery Amount",
    edit_zone: "Edit Zone",
    add_time_slot: "Add Time Slot",
    are_you_sure_delete_slot: "Are you sure you want to delete this time slot?",
    days_placeholder: "Choose days",
    all_day: "All Day",
    add_new_boundary: "Add New Boundary",
    remove_selected_boundary: "Remove Selected Boundary",
    edit_boundary_data: "Edit Boundary Data",
    backup: "Backup",
    restore: "Restore",
    restore_options: "Restore Options",
    restore_options_message_delivery: "Please choose a method to restore your delivery zone and availability settings.",
    restore_options_message_pickup:
        "Please choose a method to restore your pick-up location and availability settings.",
    overwrite_existing_records: "Overwrite existing records",
    append_to_existing_records: "Append to the existing records",
    choose_file: "Choose File",
    add_availability_code: "Add Availability Code",
    create_new_code: "Create New Code",
    enter_new_code: "Enter New Code",
    enter_new_code_name: "Enter New Code Name",
    empty_code_input: "Please Input Availability Code",
    empty_code_name_input: "Please Input Availability Name",
    code_already_exists: "Duplicate Availability Code",
    use_a_new_code: "The availability code already exists. Please input another code.",
    unsaved_changes: "You have unsaved changes",
    sure_to_exit: "Are you sure you want to exit?",
    end_before_start_time: "End time must be after start time",
    please_change_time: "Please change your time settings",
    edit_availability: "Edit Availability Code Setting",
    missing_fields: "Missing Fields",
    missing_fields_message: "The following fields are missing: {missing}",
    missing_time_ranges: "Time input can not be empty.",
    please_input_address: "Please Input Address..",
    self_serve_ordering: "Self-Serve Ordering",
    display_self_order: "Display self order instructions",
    generate_qr: "Generate QR Code",
    accept_self_serve: "Accept self-serve orders",
    require_table_num: "Require table number for self-serve orders",
    order_confirmation: "Order Confirmation",
    require_due_time_for_in_store: "Require Due time for In-Store order",
    require_due_time_for_in_store_description:
        "When this is enabled, the merchant must set a due time for each In-Store order upon accepting the order.",
    dine_in_confirmation: "Require Confirmation for In-Store order",
    dine_in_confirmation_description:
        "When this switch is off, the merchant will not need to confirm on accepting or rejecting an In-Store order. i.e., it will be one click to accept or reject an In-Store order.",
    allow_self_serve: "Allow Self-Serve Ordering",
    allow_self_serve_description:
        "Allow customers to place orders straight from mobile devices for in-store orders. Generate a QR code for each station to enable scan-to-order.",
    print_your_qr: "Station Number Setting",
    print_your_qr_description:
        "Set the number of stations and label your cards, such as table, booth, or counter seat. We'll create a unique QR code for each one. Just print the code and place it on each station.",
    exclude_station_desc: "Exclude Station Number",
    num_stations: "Number of Stations",
    station_label: "Station Label",
    discount_label: "Discount Label",
    custom_text_1: "Custom Label Line 1",
    custom_text_2: "Custom Label Line 2",
    start_number: "Start Number",
    station_label_placeholder: "Eg. Table",
    custom_text_placeholder: "Eg. Free Wi-Fi:  Goopter-Wi-Fi",
    custom_text_placeholder_2: "Eg. Wi-Fi Password: 12345678",
    card_example: "Card Example",
    card_example_title: "Your Business name",
    card_example_quote: "Order from your device and we'll deliver it to you",
    card_example_instructions: "Point our phone camera to scan the code and open menu",
    card_example_table: "Table 42",
    order_from_phone: "Order from your phone",
    no_app_required: "No app required",
    open_phone_camera: "Open phone camera",
    scan_qr_code: "Scan QR code to see menu",
    order_and_we_deliver: "Order & we'll deliver to you",
    order_again_qr: "To order again, scan the QR code",
    sync_cart_with_server: "Sync Cart With Server",
    sync_cart_with_server_description:
        "Sync items in customer carts with the server automatically. This feature ensures that Guest users do not lose any items that are already in their cart.",
    do_not_sync: "Disable Sync",
    sync_always: "Sync Always",
    sync_every_x_sec: "Sync Every X Sec",
    categories: "Categories",
    category_ids: "Categories",
    store_switcher: "Store Switcher",
    publish_manager: "Publish Manager",
    last_published: "Last Publish Date",
    last_updated: "Last Updated",
    published: "Published",
    out_sync: "Out of Sync",
    in_sync: "In Sync",
    current_status: "Current Status",
    not_published: "Not Published",
    publish_confirm:
        "The online store record is currently out of sync between backend and consumer side, do you wish to do sync between the two sides now?",
    jan: "Jan",
    feb: "Feb",
    mar: "Mar",
    apr: "Apr",
    may: "May",
    jun: "Jun",
    jul: "Jul",
    aug: "Aug",
    sep: "Sep",
    oct: "Oct",
    nov: "Nov",
    dec: "Dec",
    assign_items: "Assign Items",
    categories_desc:
        "Categories help you arrange and organize your items, report on item sales, and route items to specific printers.",
    add_category: "Add Category",
    assign_items_to: "Assign Items to {category}",
    pickup_delivery: "Pick-up / Local Delivery Settings",
    assign_items_desc:
        "Items currently in other categories will be reassigned to this one. {count} items will be assigned to the {category} category.",
    type_to_search: "Type to search...",
    rename: "Rename",
    confirm_delete: "Confirm Delete",
    are_you_sure_delete_category: "Are you sure you want to delete the {category} category?",
    renaming_category: "Renaming {category}",
    surcharge_settings: "Surcharge Settings",
    surcharge_settings_description:
        "The system can automatically apply a predefined surcharge amount to each order on checkout. The surcharge is normally used to cover the overhead for order fulfillment.",
    no_tax_goods: "No Tax Goods",
    food: "Food",
    gst_only: "GST-Only",
    GST: "GST",
    shipping: "Shipping",
    taxable_goods: "Taxable Goods",
    category_title: "Category Name",
    time_code_setting: "Available Time Setting",
    add_edit_category: "Add/Edit Category",
    edit_category: "Edit Category",
    parent_category: "Parent Category",
    paypal_account_setting: "PayPal Payments Pro",
    paypal_payment_pro_tip:
        "Accept credit/debit cards in your online store with Paypal Payment Pro. No setup or monthly fees and your customers never leave your store to complete the purchase.",
    paypal_express_checkout: "PayPal Express Checkout",
    paypal_express_checkout_tip:
        "Add 'Paypal Express Checkout' payment button to your existing checkout for the millions of shoppers who prefer paying with PayPal.",
    stripe_account_setting: "Stripe Payment",
    stripe_account_setting_tip:
        "Accept credit/debit cards in your online store with Stripe Payment. No setup or monthly fees and your customers never leave your store to complete the purchase.",
    alipay_account_setting: "Alipay",
    braintree_setting: "BrainTree Payment",
    braintree_setting_tip:
        "Reach more buyers and drive higher conversion with the only payments platform that delivers PayPal, Venmo (in the US), credit and debit cards, and popular digital wallets like Apple Pay in a single, seamless integration.",
    emt: "E-transfer/Email Money Transfer",
    emt_desc: "Allow your customers to place an order first and send you payment via bank transfer or EMT.",
    emt_account: "E-transfer/EMT Account",
    emt_password: "EMT Password (Optional)",
    emt_password_hint:
        "If your EMT account is not enabled for automatic deposits, please define a password so that our system can remind your customers to use this as the password for their EMT payment.",
    card_present_setting: "Card Present",
    card_present_setting_tip:
        "A card present transaction is one in which the customer physically interacts with payment machinery using his or her card.",
    vendor: "Vendor",
    integration_type: "Integration Type",
    access_token: "Access Token",
    device_ids: "Device ID(s)",
    pos_id: "POS ID",
    customer_ids: "Customer ID(s)",
    debug: "Debug",
    clover: "Clover",
    global_payment: "Global Payment",
    moneris: "Moneris",
    rest_pay_api: "REST Pay API",
    remote_pay_sdk: "Remote Pay SDK",
    public_key: "Public Key",
    private_key: "Private Key",
    merchant_name: "Merchant name",
    merchant_contact_number: "Merchant contact number",
    partner: "Partner",
    merchant_login: "Merchant login",
    api_username: "API Username",
    api_password: "API Password",
    payment_action: "Payment Action",
    authorization: "Authorization",
    allowed_credit_card_types: "Allowed Credit Card Types",
    mastercard: "MasterCard",
    discover: "Discover",
    switch_maestro: "Switch/Maestro",
    solo: "Solo",
    enable_this_pay_option: "Enable this Payment Option",
    title: "Title",
    api_key: "API Key",
    merchant_id: "Merchant Code",
    credential: "Credential Code",
    alipay_exclusive_account: "Alipay Platform Exclusive Account",
    authorize_only: "Authorize Only",
    authorize_and_capture: "Authorize and Capture",
    wechat_account_setting: "Wechat Account Setting",
    app_id: "App ID",
    discount_information: "Discount Information",
    store_announcement: "Store Announcement",
    store_account_information: "Store Price Plan:",
    onepage: "Onepage",
    free_trial: "Free Trial",
    basic: "Basic",
    bronze: "Bronze",
    silver: "Silver",
    gold: "Gold",
    platinum: "Platinum",
    credit_card_tip:
        "Allow customers to make credit card payments. To support real time credit card payment, apply for a PayPal Pro account and enable the service.",
    require_bill_address: "Require Bill Address",
    require_bill_address_tip: "Enable this to require a billing address for credit card payments.",
    pay_later_tip: "Enable this to allow customers to place an order first and pay by card later in person.",
    cash_tip: "Enable this to allow customers to place an order first then pay by cash later in person.",
    virtual_terminal_tip:
        "Enable this to allow customers to scan a provided QR code to make payments through their mobile phones.",
    alipay_account_tip:
        "AliPay is the No. 1 mobile payment service platform and the second largest worldwide. To support AliPay online payment for your online store, please apply for an online payment account, fill in the credentials, and enable it.",
    wechat_account_desc:
        "WeChat is the largest social media and messaging platform in the world. To support WeChat Pay online payment for your online store, please apply for an online payment account, fill in the credentials, and enable it.",
    unionpay_credit_desc:
        "UnionPay is China’s top merchant card service provider. To support UnionPay credit card online payment for your online store, please apply for an online payment account, fill in the credentials, and enable it. Please note, no password is required for the customer to make payment with a UnionPay credit card.",
    unionpay_debit_desc:
        "UnionPay debit card online payment requires user to input password for the payment. To support UnionPay debit card online payment for your online store, please apply for an online payment account, fill in the credentials, and enable it.",
    time_code_setting_desc:
        "The time setting defines when the product will be available for order; the time setting on product level overrides the time setting on category; if you don't need to have a particular time for the product, leave is as A",
    show_time_table: "Show Time Table",
    show_items: "Show Items",
    hide_items: "Hide Items",
    content_language: "Content Language",
    language_select_tip: "You can find the languages that you have localized in this menu.",
    hide_time_table: "Hide Time Table",
    discount_options: "Discount Settings",
    off_l: "off",
    code_exists: "That code is already in use",
    use_unique_code: "Please use a unique code",
    min_purchase: "Minimum purchase of",
    entire_cart: "entire cart",
    select_products: "certain products",
    any_product: "all products",
    select_product_categories: "certain product categories",
    use_per_cust: "use(s) per customer",
    duplicate: "Duplicate",
    share_facebook: "Share via Facebook",
    share_twitter: "Share via Twitter",
    share_email: "Share via Email",
    share_wechat: "Share via WeChat",
    search_dot: "Search...",
    create_disc_code: "Create Discount Code",
    discount_codes: "Discount Codes",
    auto_discounts: "Automatic Discounts",
    free_shipping_rule: "Free Shipping Rule",
    filters: "Filters",
    filter_discount_codes: "Filter Discount Codes",
    sort: "Sort",
    sort_by: "Sort by",
    ascending: "ascending",
    descending: "descending",
    newest_first: "newest first",
    oldest_first: "oldest first",
    low_to_high: "low to high",
    high_to_low: "high to low",
    a_to_z: "A-Z",
    z_to_a: "Z-A",
    item_name: "Item Name",
    discount_to: "Maximum Discount",
    discount_from: "Minimum Discount",
    discount_type: "Discount Type",
    discount_type_tip:
        "Discounts item by subtracting a percentage from the original price. The discount applies to each qualifying item in the cart. For example: Enter 10 in Discount Amount for an updated price that is 10% less than the original price. Discounts item by subtracting a fixed amount from original price. The discount applies to each qualifying item in the cart. For example: Enter 10 in Discount Amount for an updated price that is $10 less than the original price. Discounts the entire cart by subtracting a percentage from the cart total. For example: Enter 10 in Discount Amount to subtract 10% from the cart total",
    by_percent: "Percentage of product price discount",
    by_fixed: "Fixed amount discount for each product",
    cart_fixed: "Fixed amount discount for whole cart",
    discount_range: "Discount Range",
    date_start: "Start Date",
    date_expire: "Expiry Date",
    apply_filters: "Apply Filters",
    discount_name: "Discount Name",
    create_discount_code: "Create a New Discount Code",
    discount_code: "Discount Code",
    created_at: "Created At",
    updated_at: "Last Update",
    discount_info: "Discount Info",
    publicly_available: "Publicly Available",
    enable_product: "Enable Product",
    uses_per_customer: "Uses Per Customer",
    use_per_cust_tip:
        "Determines how many times the coupon code can be used by the same registered customer who belongs to any of the selected customer groups. The setting does not apply to guest shoppers who are members of the NOT LOGGED IN customer group, or to customers who shop without logging in to their accounts. If there is no limit, leave the field blank",
    uses_per_coupon: "Uses Per Coupon",
    use_per_coup_tip:
        "Determines how many times the coupon code can be used. If there is no limit, leave the field blank",
    min_order_amount: "Minimum Order Amount Limit",
    min_amount_tip: "The minimun order amount limit to use this coupon.",
    discount_amount: "Discount Value",
    discount_rule: "Discount Rule",
    apply_to_all: "Apply to all",
    by_products: "By Products",
    by_categories: "By Categories",
    save_discount_code: "Save Discount Code",
    apply_to_categories: "Apply to Selected Categories",
    apply_to_products: "Apply to Selected Products",
    choose_products: "Choose Products",
    please_ensure_fill_all_required: "Please ensure to fill out all required fields",
    delete_discount_code: "Delete Discount Code",
    are_you_sure: "Are you sure?",
    delete_draft_confirmation: "Are you sure you want to delete the saved draft order?",
    edit_discount_code: "Edit Discount Code",
    when_exceeding_distance_limit: "When Exceeding the Distance Limit:",
    free_shipping_within_x: "Free shipping within {x}km; charge delivery fee for the distance over {x}km",
    charge_full_delivery_fee: "Charge full delivery fee",
    transaction_type: "Transaction Type",
    sku: "SKU",
    sku_long: "SKU (Stock Keeping Unit)",
    category: "Category",
    price: "Price",
    item_list: "Item List",
    bulk_edit: "Bulk Edit",
    create_item: "Create Item",
    available: "Available",
    sold_out_today: "Sold Out Today",
    sold_out_indefinitely: "Sold Out Indefinitely",
    normal_product: "Regular Product",
    gift_card: "Gift Card",
    self_input: "Self Input",
    call_vendor: "Call Vendor",
    group_purchase_product: "Group Purchase",
    items_filter: "Items Filter",
    item: "Item",
    item_s: "Item(s)",
    price_from: "Price From",
    price_to: "Price To",
    quantity_from: "Quantity From",
    quantity_to: "Quantity To",
    apply: "Apply",
    reset_filters: "Reset Filters",
    item_images: "Item Images",
    item_images_desc:
        "Click on a thumbnail to view the image. Drag and drop any image to the far left to make it the front cover image.",
    short_description: "Short Description",
    description: "Description",
    item_type: "Item Type",
    item_title: "Item Title",
    show_store_link: "Show Store Link",
    store_link_tip:
        "Turn on the switch to display your store’s link on the item details page. Toggle off to remove your store’s link from the page.",
    hide_on_frontend: "Hide on frontend",
    is_hidden_tip:
        "Turn on the switch to hide this item from being seen on the frontend directly. Hidden items can still be seen inside group purchase products.",
    max_sale_qty: "Purchase limit per order",
    max_sale_qty_tip:
        "Maximum quantity customer can purchase in one order. (A value of 0 means this product has no purchasing limit)",
    min_sale_qty: "Minimum order quantity",
    min_sale_qty_tip: "Minimum quantity customers must order when purchasing this product.",
    special_price: "Special Price",
    special_from_date: "Sale Start Date",
    special_to_date: "Sale End Date",
    price_margin: "Margin",
    price_cost: "Cost per item",
    price_profit: "Profit",
    barcode: "Barcode (ISBN, UPC, GTIN, etc.)",
    barcode_short: "Barcode",
    barcode_length_invalid: "Barcode length must be between {minlen} and {maxlen} characters long",
    barcode_already_in_use: "Barcode is already in use",
    barcode_not_unique: "This barcode is in use by another product",
    click_to_validate_barcode: "Click to validate barcode",
    validated: "Validated",
    tax_class: "Tax Class",
    stock: "Stock",
    weight: "Weight",
    start_date: "Start Date",
    end_date: "End Date",
    created_date: "Created Date",
    available_time: "Available Time",
    item_info: "Item Info",
    options: "Options",
    preview_video: "Preview Video",
    video: "Video",
    drop_down: "Dropdown",
    radio: "Radio",
    checkbox: "Checkbox",
    multiple: "Multiple",
    option_title: "Option Title",
    option_type: "Option Type",
    min_select: "Minimum Selection",
    max_select: "Maximum Selection",
    quantity_input: "Allow Option Value Quantity Input",
    add_edit_option: "Add/Edit Option",
    add_option: "Add option value",
    save_option: "Save Option",
    modify_options: "Modify Options",
    remove_from_item: "Remove from Item",
    related_upsell_products: "Related / Up-sell Products",
    fullfilment: "Fullfilment",
    related_products: "Related Products",
    related_products_desc: "Customers seeing this product will also see the related products list.",
    up_sell_products: "Up-Sell Products",
    up_sell_products_desc:
        "Up-Selling is a common strategy for businesses to boost their revenue & profits. Normally you choose related products that cutomers are likely to purchase as up-sell products, so customers can add these items into their cart before they checkout.",
    delivery_discount: "Delivery Discount",
    pickup_discount: "Pick-up Discount",
    new_user_discount: "New User Discount",
    minimum_amount: "Minimum Amount",
    discount_condition: "Discount Condition",
    exclude: "Exclude",
    delete_delivery_discount: "Delete Delivery Discount",
    add_rule: "Add Rule",
    exclude_selected_products: "Exclude Selected Products",
    exclude_selected_categories: "Exclude Selected Categories",
    apply_selected_products: "Apply to Selected Products",
    apply_selected_categories: "Apply to Selected Categories",
    include_selected_products: "Include Selected Products",
    include_selected_categories: "Include Selected Categories",
    percent: "Percent",
    amount: "Amount",
    create_automatic_delivery_discount: "Create an Automatic Delivery Discount",
    create_automatic_pickup_discount: "Create an Automatic Pick-up Discount",
    create_automatic_shipping_discount: "Create an Automatic Shipping Discount",
    create_automatic_user_discount: "Create an Automatic User Discount",
    save_auto_discount: "Save Automatic Discount",
    update_discount: "Update Rule",
    delivery_discounts: "Delivery Discounts",
    before_tax: "(before tax)",
    max_distance: "Maximum Distance",
    when_exceeding_max_distance: "When exceeding the distance limit",
    distance: "Distance",
    free_within: "Free shipping within",
    charge_over: "charge delivery fee for distances over",
    user_reward_points: "New User Reward Points",
    user_discount: "New User Discount",
    condition_type: "Condition Type",
    user_discounts: "User Discounts",
    shipping_discounts: "Shipping Discounts",
    apply_to: "Apply to",
    selected_products: "Selected Products",
    selected_categories: "Selected Categories",
    delete_user_account: "Delete User Account",
    include: "Include",
    delete_discount_rule: "Delete Discount Rule",
    store_qr_code: "Scan the QR Code to Order",
    would_share: "would like to offer you a",
    off_discount_coupon: "off discount coupon with code:",
    may_store: "You may use it to order",
    no_discount_codes: "No Discount Codes Found",
    create_new_code_option: "Press the 'Create Discount Code' button to create a new code",
    no_discount_rules: "No Discount Rules Found",
    create_new_rule: "Press the 'Add Rule' button to create a new rule",
    min_amount_missing: "Minimum Amount Field Missing",
    discount_missing: "Discount Field Missing",
    name_missing: "Name Field Missing",
    missing_from_date: "Start Date Field Missing",
    missing_to_date: "Expiry Date Field Missing",
    distance_missing: "Distance Field Missing",
    missing_min_order: "Min Order Field Missing",
    products_missing: "You Have No Products Selected",
    categories_missing: "You Have No Categories Selected",
    category_title_empty_warning: "Category name can not be empty.",
    used: "Used",
    pickup_discounts: "Pick-up Discounts",
    required_fields_warn: "Fields with a '*' are required",
    field_required: "This field is required",
    invalid_discount_between:
        "Invalid discount value. The discount value should be greater than {dollar}{gt}{percent} and less than {dollar}{lt}{percent}.",
    invalid_discount_low: "Invalid discount value. The discount value should be greater than {dollar}{gt}{percent}.",
    invalid_discount_high: "Invalid discount value. The discount value should be less than {dollar}{lt}{percent}.",
    discount_options_description:
        "Discount codes provide discounts to designated users at checkout. Automatic discounts apply to all customers as long as their order meets the defined discount rules.",
    order_ahead_days: "Allowed Pre-Order Days",
    order_ahead_days_tip:
        "If your delivery time and the fee are based on the delivery zone, please turn on this switch and define the delivery zone, delivery fee, and time for each zone.",
    notification_settings: "Notification Setttings",
    menu_desc:
        "If you have multiple menus in your store, you may select the corresponding menus and allow your customer to switch between different menus for ordering.",
    add_new: "Add New",
    available_time_setting: "Availability Time Setting",
    edit_menu: "Edit Menu",
    create_menu: "Create Menu",
    save_menu: "Save Menu",
    delete_menu: "Delete Menu",
    please_fill_all_fields: "Please fill out all fields",
    missing_name: "Missing Name",
    managed_stores: "Multiple Store Order Management ",
    managed_stores_desc:
        "A franchise manager can manage orders from multiple stores. Select the stores you would like to manage.",
    managed_stores_description:
        "If you want to manage orders from multiple branches at the same time, you can select the branches you want to manage. Only admin users who have access to multiple branches can access this page.",
    arrange_products: "Change product display sequence",
    arranging_category: "Update product display sequence in {category}",
    drag_drop_products: "Drag and drop products to change the order in which they are shown.",
    add_edit_item: "Add/Edit Item",
    option_title_desc: "This is the title for the option.",
    option_type_desc: "This is how your options will appear on your app or website.",
    quantity_input_desc:
        "When enabled, customer will be allowed to specify the number of option values on the store front. ",
    min_select_desc:
        "Set to 0 if selections are optional, the number indicates the minimum quantity that the customer must choose.",
    option_table_header_price_desc:
        "Set the price relative to the item price (e.g. 2 = $2 more, -4 = $4 less, 0 = same price as the item price)",
    category_deleted: "Category deleted successfully",
    product_updated: "Product info has been updated; to reflect it on your online store, please do a re-publish.",
    product_created: "New product has been created, to display it on your online store, please do a re-publish.",
    nm_placeholder: "Enter name",
    share: "Share",
    share_product: "Share Product",
    product_url_copied: "Product has been copied to clipboard.",
    admin_users: "Admin Users",
    admin_users_desc:
        "You can set up staff accounts and adjust permissions according to each member, so they only see what is required.",
    active: "Active",
    inactive: "Inactive",
    admin_role: "Admin Role",
    user_since: "User Since",
    id: "ID",
    add_admin: "Add Admin",
    personal_information: "Personal Information",
    country_code: "Country Code",
    promo_code: "Promo Code",
    registration_time: "Registration Time",
    wechat_bind: "Wechat Bind",
    permission_set: "Permission Set",
    reset_password: "Reset Password",
    please_enter_new_password: "Please, enter new password",
    password_length_check: "Password must be at least 6 characters long",
    binded_user_id: "(Binded User ID)",
    permissions: "Permissions",
    permission_role: "Permissions / Role",
    current_store: "Current Store",
    contact_details: "Contact Details",
    contact_details_description:
        "All Authorized Representatives will need to verify their identity using an authentication code which will be sent using either the phone number or email provided below",
    change_password: "Change Password",
    role: "Role",
    ordering_food: "Ordering Food",
    edit_order: "Edit Order",
    payment: "Payment",
    report: "Report",
    edit_personal_information: "Edit Personal Information",
    edit_permissions: "Edit Permissions",
    deactivate_account: "Deactivate Account",
    cannot_delete_user: "Cannot Delete User",
    cannot_delete_own_account: "You cannot delete your own account",
    delete_user: "Delete User",
    edit_account: "Edit Account",
    add_admin_user: "Add Admin User",
    activate_account: "Activate Account",
    read_write: "Read / Write",
    read_only: "Read Only",
    wechat_step_one: "Step 1. Follow Goopter Wechat Official account",
    wechat_step_two:
        "Step 2. Scan the following QR code using your Wechat to subscribe to the Wechat order notification service",
    cannot_access: "Cannot Access",
    no_inactive_users: "No Inactive Users",
    common: "Common",
    settings: "Settings",
    user_profile: "User Profile",
    public_notice: "Public Notice",
    products_categories: "Products / Categories",
    order_management: "Order Management",
    marketing: "Marketing",
    store_info_review: "Store Info / Reviews",
    setting_general_info: "General Info Settings",
    product_review: "Product Reviews",
    store_review: "Store Reviews",
    customer_review: "Customer Reviews",
    create_store: "Create Store",
    setting_pickup_delivery: "Pick-up / Local Delivery Settings",
    setting_self_serve: "Self Serve Settings",
    setting_hours: "Hours Settings",
    setting_managed_stores: "Managed Stores Settings",
    setting_order_notifications: "Order Notification Settings",
    setting_payments: "Payment Settings",
    setting_surcharge: "Surcharge Settings",
    setting_item_availability: "Item Availability Settings",
    setting_switch_store: "Switch Store Settings",
    setting_menu: "Menu Settings",
    display_settings: "Display Settings",
    roles_permission_settings: "Role / Permissions Settings",
    order_comments: "Order Comments",
    no_comments_message: "No comments available",
    added_order_comment: "Added Order Comment",
    order_history: "Order History",
    order_export: "Order Export",
    product_availability: "Product Availability",
    store_availability: "Store Availability",
    category_management: "Category Management",
    product_management: "Product Management",
    giftcard_management: "Giftcard Management",
    coupon_management: "Coupon Management",
    setting_discount: "Discount Settings",
    referral_page: "Referral Statistics/Management",
    product_duplicated: "Product Duplicated",
    product_duplicated_message:
        "The new product has been created, please review the product, make necessary changes and save.",
    share_qrcode: "QR Code",
    share_qrcode_instant: "QR Code (Instant Checkout)",
    regular_product_qr: "Regular product QR code",
    instant_co_qr: "Instant Checkout QR Code",
    user_input: "User Input",
    ignore_conf_on_checkout: "Ignore Confirmation on Checkout",
    poster: "Poster",
    download_poster: "Download Poster",
    customers: "Customers",
    canada_phone: "Canada +1",
    usa_phone: "United States +1",
    full_name: "Full Name",
    number_of_orders: "Order Count",
    points_balance: "Points Balance",
    total_order_amount: "Order Amount",
    last_order_date_range: "Last Order Date Range",
    customer_phone: "Customer Phone",
    customer_email: "Customer Email",
    total_spent: "Total Spent",
    total_number_orders: "Total Number of Orders",
    customer_filter: "Customer Filter",
    send_message: "Send Message",
    view_orders: "View Orders",
    send_coupon: "Send Coupon",
    view_giftcard: "View Giftcard",
    send_message_to_customers: "Send a Message to your Customers",
    send_to: "Send To",
    message_type: "Message Type",
    coupon_promotion: "Coupon Promotion",
    store_promotion: "Store Promotion",
    product_promotion: "Product Promotion",
    body: "Body",
    choose_product: "Choose Product",
    message_sent: "Message Sent Successfully!",
    amt_to: "Order Amount To",
    amt_from: "Order Amount From",
    qty_to: "Order Quantity To",
    qty_from: "Order Quantity From",
    date_range: "Date Range",
    select_type: "Select Type",
    any_date: "Any Date",
    custom_range: "Custom Range",
    last_order_date_from: "Last Order Date From",
    last_order_date_to: "Last Order Date To",
    last_order_date: "Last Order Time",
    copy_product_url: "Copy Product URL",
    copy_store_url: "Copy Store URL",
    open_product_webpage: "Open Product Webpage",
    admin_user: "Admin User",
    no_customers: "No Customers",
    see_selected: "See Selected",
    hide_selected: "Hide Selected",
    select_coupon: "Select Coupon",
    choose_product_desc: "Please choose a promotional product to send to your customers.",
    missing_x: "Missing {x}",
    reviews: "Reviews",
    show_selected: "Show Selected",
    count: "Count",
    balance: "Balance",
    reply: "Reply",
    user_didnt_leave_rating: "The user didn't write a review and has left just a rating.",
    anonymous: "Anonymous",
    send: "Send",
    reply_sent: "Reply Sent Successfully!",
    approved: "Approved",
    unapproved: "Unapproved",
    approve: "Approve",
    unapprove: "Unapprove",
    review_approval_updated: "Review Approval Updated Successfully!",
    customer_id: "Customer ID",
    product_id: "Product ID",
    review_date: "Review Date",
    review_update_date: "Review Update Date",
    no_reviews: "No Reviews",
    search_by_name: "Search by customer name...",
    dt_start: "Date Start",
    dt_end: "Date End",
    update_dt_start: "Update Date Start",
    update_dt_end: "Update Date End",
    error_email_taken: "That email is already taken, please try with another",
    error_username_taken: "That username is already taken, please try with another",
    error_please_try_again: "An error occurred, please try again",
    local_delivery: "Local Delivery",
    order_review: "Order Reviews",
    change_phone: "Change Phone",
    select_option: "Select Option",
    send_verification_code: "Send Verification Code",
    enter_verification_code: "Enter Verification Code",
    submit_verification_code: "Submit Verification Code",
    phone_number_changed: "Phone Number Updated Successfully!",
    phone_number_required: "Phone number required",
    phone_number_length_invalid: "Phone number must be {minlen} to {maxlen} digits long",
    add_item: "Add Item",
    edit_item: "Edit Item",
    website_url: "Domain name for desktop web",
    mobile_url: "Domain name for mobile web",
    website_information: "Domain name",
    download_QR: "Download QR",
    error_message_product: 'Following error occurred when saving this product: "{message}"',
    use_category_time_setting: "Use category time setting",
    store_url_copied: "Store URL Copied Successfully!",
    verification_code_sent: "A verification code has successfully been sent!",
    password_changed: "Password updated successfully!",
    phone_not_registered: "An error occurred, that phone is not registered yet",
    vc_expired: "An error occurred, that verification code has expired",
    too_many_vc_requests: "An error occurred, there have been too many verification requests or checks",
    customer_aready_exists: "An error occurred, that customer already exists",
    an_error_occurred: "An error occurred, please try again.",
    third_party_courier_restaurant_error_message:
        'Failed to update store type. Please update the delivery method in setting -> Pick-up/delivery to not use "3rd party courier" before changing the store type to restaurant.',
    no_items: "No Items",
    no_active_users: "No Active Users",
    min_placeholder: "Minimum",
    max_placeholder: "Maximum",
    table_rate: "Table Rate",
    flat_rate: "Flat Rate",
    ups: "UPS",
    fedex: "Fedex",
    c2c: "C2C",
    Dashboard: "Dashboard",
    third_party_local_delivery_service: "3rd Party Local Delivery Service",
    admin_profile: "Admin Profile",
    admin_user_delete_error: "An error occurred while deleting an Admin User",
    admin_user_delete_perm_error: "You do not have the permissions neccessary to delete this Admin User.",
    ensure_password_six: "Password must be at least 6 characters long",
    admin_already_exists: "You cannot use this phone number, as another user already using this number.",
    referral_settings: "Referral Settings",
    referral_settings_description:
        "The referral program is a growth marketing tactic that encourages existing customers to recommend your store or product to their friends, family, and colleagues.",
    allow_cust_refs: "Allow Customer Referrals",
    allow_cust_refs_desc:
        "Referees must complete an order using the referred product/website for the referrer to get reward points. This is a one time reward.",
    allow_order_refs: "Allow Product/Merchant Referrals",
    allow_order_refs_desc:
        "Reward points will be given to the customer who refers someone to place an order with a link containing the referral code, reward points is given after the order is completed.",
    batch_process_referral_rewards: "Calculate referral rewards at the end of the day in a batch process",
    batch_process_referral_rewards_desc:
        "When enabled, referral reward points are only calculated and allocated to each referrer at the end of the day using batch processing. When disabled, referral reward points are allocated when each order is completed. Batch processing reduces the chance of withdrawing points when order refunds occur during the day.",
    ref_expiry_days: "Referral Expiry Days",
    ref_expiry_days_desc: "The number of days the referral code remains on the referee’s device.",
    cust_val_points: "Customer referral reward points ratio",
    cust_val_points_desc:
        "Indicates value to points ratio for customer referrals; e.g., if this value is 10, then for each dollar the referrer spends, the referee will receive 10 reward points.",
    ord_val_points: "Product/website referral reward points ratio ",
    ord_val_points_desc:
        "Indicates value to points ratio for product or website referrals; e.g., if this value is 10, then for each dollar the referrer spends, the referee will receive 10 reward points. ",
    auto_generate_referral_code: "Auto generate referral code",
    auto_generate_referral_code_desc:
        "When enabled, referral code for each newly registered user is automatically generated.",
    click_open_product_page: "Click to open the product page",
    generate_referral_code: "Generate Code",
    referral_code: "Referral Code",
    referral_code_enabled: "A referral code was enabled successfully!",
    referral_code_disabled: "A referral code was disabled successfully!",
    referral_code_generated: "A referral code was generated successfully!",
    referral_code_generated_error: "There was an error generating a referral code, please try again",
    referral_code_deleted: "The referral code was deleted successfully!",
    referral_code_cannot_delete:
        "You cannot delete this referral code because not all reward points have been redeemed yet.",
    pid: "Product ID",
    "24_hours": "24 Hours",
    bulk_actions: "Bulk Actions",
    notifications_settings_changed: "Notification settings have been updated successfully!",
    x_field_not_long_enough: "{x} is not long enough",
    please_ensure_field_x_long: "Please ensure it's {x} characters long",
    copy_referral_code: "Copy Referral Code",
    referral_code_copied: "Referral Code Copied Successfully!",
    generate_referrals: "Generate Referrals",
    enable_referrals: "Enable Referrals",
    disable_referrals: "Disable Referrals",
    delete_referrals: "Delete Referrals",
    live_mode: "Live Mode",
    auto_create_delivery_task: "Automatically create delivery task on accept order",
    service_provider: "Service Provider",
    test_connection: "Test Connection",
    delivery_connection_success: "Your third party local delivery service connection was successful!",
    delivery_connection_failure: "Unfortuantely, your third party local delivery service connection failed",
    third_party_delivery_settings: "Third Party Local Delivery Service Settings",
    need_atleast_address_for_multiple:
        'You need to have at least one pick-up location to turn on the "Multiple Pick-up Location" feature.',
    need_atleast_address_for_multiple_delivery:
        'You need to have at least one delivery location to turn on the "Area Based Flat Delivery Fee" feature.',
    ship_to: "Ship To",
    all_countries: "All Countries",
    specific_countries: "Specific Countries",
    allowed_countries: "Allowed Countries",
    handling_fee: "Handling Fee",
    region: "Region",
    postal: "Postal",
    no_rules: "No Rules",
    add_table_rate_rule: "Add Table Rate Rule",
    base_price: "Base Price",
    per_order: "Per Order",
    per_item: "Per Item",
    handle_type: "Handle Type",
    deleted_successfully: "Deletion Successful!",
    enter_address_to_autofill: "Enter Address Here to Autofill Address Form",
    calc_handling_fee: "Calculate Handling Fee",
    percentage: "Percentage",
    dollar: "Dollar",
    fixed: "Fixed",
    delete_rule: "Delete Rule",
    edit_rule: "Edit Rule",
    shipping_settings: "Shipping Settings",
    regular_shipping: "Regular Shipping",
    express_shipping: "Express Shipping",
    min_shipping_days: "Minimum Shipping Days ",
    max_shipping_days: "Maximum Shipping Days",
    user_id: "User ID",
    access_key: "Access Key",
    container: "Container",
    pickup_method: "Pick-up Method",
    apply_handling: "Apply Handling Fee",
    weight_unit: "Weight Unit",
    max_weight: "Maximum Weight",
    min_weight: "Minimum Weight",
    destination_type: "Destination Type",
    package_request_type: "Package Request Type",
    allowed_methods: "Allowed Methods",
    customer_packaging: "Customer Packaging",
    ups_letter_envelope: "UPS Letter Envelope",
    customer_supplied_package: "Customer Supplied Package",
    ups_tube: "UPS Tube",
    pak: "PAK",
    ups_express_box: "UPS Express Box",
    ups_worldwide_25: "UPS Worldwide 25 Kilo",
    ups_worldwide_10: "UPS Worldwide 10 Kilo",
    pallet: "Pallet",
    small_exp_box: "Small Express Box",
    medium_exp_box: "Medium Express Box",
    large_exp_box: "Large Express Box",
    regular_daily_pickup: "Regular Daily Pick-up",
    on_call_air: "On Call Air",
    one_time_pickup: "One Time Pick-up",
    letter_center: "Letter Center",
    customer_counter: "Customer Counter",
    ups_express: "UPS Express",
    ups_expedited: "UPS Expedited",
    ups_worldwide_express: "UPS Worldwide Express",
    ups_worldwide_expedited: "UPS Worldwide Expedited",
    ups_standard: "UPS Standard",
    ups_three_day_select: "UPS Three-Day Select",
    ups_early_am: "UPS Express Early A.M.",
    ups_saver: "UPS Saver",
    divide_equal: "Divide to Equal Weight (one request)",
    origin_weight: "Original Weight",
    account_key: "Account Key",
    packaging: "Packaging",
    dropoff: "Dropoff",
    is_residential_delivery: "Is Residential Delivery",
    meter_number: "Meter Number",
    fedex_envelope: "Fedex Envelope",
    fedex_pak: "Fedex Pak",
    fedex_box: "Fedex Box",
    fedex_tube: "Fedex Tube",
    fedex_10_box: "Fedex 10kg Box",
    fedex_25_box: "Fedex 25kg Box",
    your_packaging: "Your Packaging",
    regular_pickup: "Regular Pick-up",
    request_courier: "Request Courier",
    drop_box: "Drop Box",
    business_service_center: "Business Service Center",
    europe_national_priority: "Europe First Priority",
    fedex_1_freight: "1 Day Freight",
    fedex_2_freight: "2 Day Freight",
    fedex_2_day: "2 Day",
    fedex_2_day_am: "2 Day AM",
    fedex_3_day_freight: "3 Day Freight",
    fedex_express_saver: "Express Saver",
    fedex_ground: "Ground",
    first_overnight: "First Overnight",
    ground_home_delivery: "Home Delivery",
    international_economy: "International Economy",
    international_economy_freight: "Inernational Economy Freight",
    international_first: "International First",
    international_ground: "International Ground",
    international_priority: "International Priority",
    international_priority_freight: "International Priority Freight",
    priority_overnight: "Priority Overnight",
    smart_post: "Smart Post",
    standard_overnight: "Standard Overnight",
    fedex_freight: "Freight",
    fedex_national_freight: "National Freight",
    add_c2c_regular_rule: "Add Regular Country to Country Shipping Rule",
    edit_c2c_regular_rule: "Edit Regular Country to Country Shipping Rule",
    add_c2c_express_rule: "Add Express Country to Country Shipping Rule",
    edit_c2c_express_rule: "Edit Express Country to Country Shipping Rule",
    update_rule: "Update Rule",
    united_states: "United States",
    canada: "Canada",
    united_kingdom: "United Kingdom",
    china: "China",
    edit_table_rate_rule: "Edit Table Rate Rule",
    require_utensils: "Request Utensil Option",
    require_utensil_tip:
        'This setting determines whether your customer can see the "Request Utensil Option" on the checkout page.',
    print_request_utensil: "Print Request Utensil Option on Receipt",
    print_request_utensil_description:
        'This option determines whether the "Request Utensil" will show up on the printed receipt.',
    do_not_show_option: "Do not show this option",
    show_utensil_option_not_required: "Show this option and default to not require utensil",
    show_utensil_option_required: "Show this option and default to require utensil",
    do_not_print: "Do not print",
    always_print: "Always print",
    only_print_utensil_request: "Only print when the customer request utensils",
    only_print_utensil_no_request: "Only print when the customer does not request utensils",
    all_referrals_already_generated: "All of the selected customers already had generated referral codes!",
    all_referrals_were_already_enabled: "All of the selected customers' referral codes were already enabled!",
    all_referrals_were_already_disabled: "All of the selected customers' referral codes were already disabled!",
    km: "Kilometers",
    miles: "Miles",
    distance_unit: "Distance Unit",
    extra_dollar_per_mile: "Extra Dollars Per Mile",
    payment_option_disabled_unless_required:
        "This payment options will be automatically disabled if you leave this page. To keep it enabled, please fill in all the required fields.",
    instore_discount: "In-store Discount",
    create_automatic_instore_discount: "Create an Automatic In-store Discount",
    checkout_settings: "Offline Payment / Guest Checkout",
    checkout_settings_description:
        "The guest checkout allows customers to place an order without providing customer information. This is normally used for in-store self-ordering or Scan&Pay(virtual POS terminal) payment.",
    guest_checkout: "Guest Checkout",
    offline_payment: "Offline Payment",
    block_online_payment: "Block Online Payment",
    instant_pay: "Digital Asset(Gift Card etc.)",
    instant_checkout: "Instant Checkout",
    no_menus: "No Menus Defined",
    enter_address_here: "Enter Address Here",
    cut_off_time: "Cut Off Time",
    braintree_allow_paypal: "Allow PayPal Payment",
    with_min_purchase: "With a minumum purchase of",
    performance: "Performance",
    format_qr_codes: "Choose QR Code Style",
    format_qr_codes_description: "Format QR Codes as you would like them to appear.",
    scan_and_order: "Scan to Order",
    powered_by: "Powered by",
    download_pdf: "Download PDF",
    confirm_format: "Continue",
    require_scan_instore: "Require Scan for in Store Order",
    qr_code_style: "QR Code Style",
    qr_code_type: "QR Code Type",
    order_online_earn_points: "Order online to earn points",
    a3: "A3",
    a4: "A4",
    squares: "Squares",
    dots: "Dots",
    legal: "Legal",
    background_colour: "Background Colour",
    paper_size: "Paper Size",
    show_rewards_info: "Show Rewards Info",
    bleeding_margin: "Bleeding Margin (mm)",
    add_margin_between_qrs: "Add Margin Between QR Codes",
    add_custom_text: "Include Custom Label",
    table_numberz: "Table Number",
    parking_lot: "Parking Lot",
    kiosk_number: "Kiosk Number",
    reward_points: "Reward Points",
    reward_points_extra_customer: "The points balance will be updated on your account upon order completion.",
    reward_points_extra_admin: "The points balance will be updated on customer's account upon order completion.",
    default_cc_gateway: "Default Credit Card Gateway",
    offline_gateway: "Offline",
    paypal: "Paypal",
    stripe: "Stripe",
    not_publicly_available: "Not available to public",
    kgs: "kgs",
    kg: "kg",
    lbs: "lbs",
    lb: "lb",
    oz: "oz",
    grams: "g",
    support: "Support",
    generate_qr_setting: "QR Code Generator Tool",
    generate_qr_setting_description: "Create Store Poster with QR code or an arbitrary QR code.",
    qr_code_type_description: "Choose your QR Style ",
    store_poster: "Store Poster with QR Code",
    arbitrary_qr_code: "Arbitrary QR Code",
    variables_title: "Variables",
    discount_value: "Discount Value",
    order_types_label: "Order Types Label",
    bottom_label: "Bottom Label",
    style_settings: "Style Settings",
    url_label: "URL",
    payment_icons: "Payment Icons",
    qr_store_logo: "PNG format Store Logo",
    residential: "Residential",
    commercial: "Commercial",
    "customer_new": "New",
    "customer_returning": "Returning",
    "abandoned_carts": "Abandoned checkouts",
    "add_customer": "Add Customer",
    "new_customer": "New Customer",
    "customer_info": "Customer Information",
    "subscribe_message": "Customer agreed to receive email marketing",
    "note": "Note",
    "note_place_holder": "Add a note about this customer",
    "tags": "Tags",
    "map_search_placeholder": "Enter street address",
    "company": "Company",
    "please_input_valid_x": "Please input valid {x}",
    "customer_since": "Customer since {time}",
    "customer_note": "Customer Note",
    "no_note_added": "No note was added",
    "last_order": "Last Order",
    "recent_orders": "Recent Orders",
    "default_address": "Default Address",
    "edit_customer_info": "Edit Customer Info",
    "update_customer_success": "Successfully Updated Customer",
    "edit_address": "Edit Address",
    "default": "Default",
    "set_as_default": "Set As Default",
    "modify": "Modify",
    "add_address": "Add Address",
    "email_marketing": "Email Marketing",
    "edit_status": "Edit Status",
    "subscribed": "Subscribed",
    "not_subscribed": "Not subscribed",
    "edit_email_marketing_status": "Edit email marketing status",
    "edit_email_marketing_status_warning":
        "You should ask your customers for permission before you subscribe them to your marketing emails",
    "subscribed_on_x": "Subscribed on {time}",
    "view_all_tags": "View all tags",
    "add_tag": "Add tag",
    "reset_password_message": "Sent a link to customers's email for customer to reset password.",
    "sent_reset_link": "Sent reset link",
    "update_password": "Update Password",
    "password_confirmation_sent": "A confirmation email has been sent to the customer",
    "view_all_records": "View all records",
    "show_last_3_records": "Show last 3 records",
    "x_gift_card_found": "{x} gift card(s) found",
    "purchase_date": "Purchase Date",
    "purchase_date_giftcard": "Purchase Date",
    "gift_card_code": "Gift Card Code",
    "see_more_orders": "See more orders",
    "no_default_address_set": "No default address found",
    "no_orders_found": "No order history found",
    "customer_email_exists": "The provided email is already in use by another customer, please try another email.",
    "customer_phone_exists":
        "The provided phone number is already in use by another customer, please try another number.",
    "view_detail": "View detail",
    "preferred_delivery_method": "Delivery Service Provider",
    "self_local_delivery": "Merchant Self Local Delivery",
    "third_party_courier": "3rd Party Courier (UPS, Fedex, Canada Post etc.)",
    "third_party_local": "3rd Party Local Delivery",
    "shipping_fee": "Shipping Fee",
    auto_accept_order_settings: "Auto Accept Order Settings",
    auto_accept_order_settings_description:
        "Enable this setting for the system to automatically accept new orders instead of accepting manually. You may define conditions for auto-acceptance.",
    auto_accept_order_enabled: "Auto Accept Order",
    auto_accept_order_type_any: "Any orders",
    auto_accept_order_type_in_store: "In-Store orders",
    auto_accept_order_type_pickup: "Pick-up orders",
    auto_accept_order_type_delivery: "Delivery orders",
    auto_accept_order_type_instant_pay: "Instant pay/others",
    auto_accept_time_any_time: "Any time",
    auto_accept_time_store_hours_only: "Within store hours only",
    auto_accept_order_time: "Order Time",
    auto_accept_time_setting_any: "Any time",
    ASAP: "ASAP",
    auto_accept_time_setting_asap: "ASAP",
    auto_accept_time_setting_same_day: "Any time of the same day",
    auto_accept_time_setting_future_day: "Future Day Orders",
    auto_accept_time_setting_specify: "Specify Due Time",
    auto_accept_due_time_setting: "Due Time Setting",
    auto_accept_due_time_value: "Due time",
    auto_accept_due_time_within: "Within",
    auto_accept_due_time_hours: "hour(s)",
    auto_accept_due_time_minutes: "min(s)",
    auto_accept_due_time_future_day: "Future day",
    auto_accept_due_time_same_day: "Same day",
    auto_accept_order_status: "Default Accepted order status",
    auto_accept_payment_type: "Payment Type",
    auto_accept_payment_any: "Any",
    auto_accept_payment_online: "Online Payment",
    auto_accept_payment_offline: "Offline Payment",
    content_pages: "Custom Content Pages",
    content_pages_description: "Manage custom content pages for your online store.",
    add_content_page: "Add Custom Content Page",
    edit_content_page: "Edit Custom Content Page",
    content: "Content",
    updated_content_page_list_success:
        "The custom content page(s) have been updated successfully! To reflect it on your online store, please do a re-publish.",
    added_content_page_success:
        "The custom content page was created successfully! To reflect it on your online store, please do a re-publish.",
    updated_content_page_success:
        "The custom content page has been updated successfully! To reflect it on your online store, please do a re-publish.",
    deleted_content_page_success:
        "The custom content page was deleted successfully! To reflect it on your online store, please do a re-publish.",
    delete_content_page_unsuccessful: "Cannot delete custom content page.",
    delete_content_page_confirmation: "Are you sure you want to delete this custom page?",
    duplicate_url_key: "This URL has already been assigned to another page. Please try a different value.",
    discounted_upsell: "Discounted Up-sell",
    amount_type: "Amount Type",
    amount_type_tip: "Choose which order amount needs to be reached to apply discounted up-sell.",
    discounted_upsell_items: "Discounted Up-sell Items",
    add_discounted_upsell_rule: "Add Discounted Up-sell Rule",
    edit_discounted_upsell_rule: "Edit Discounted Up-sell Rule",
    delete_rule_confirmation: "Are you sure you want to delete this rule?",
    grand_total: "Grand Total",
    net_amount: "Item Net Amount",
    reward_points_settings: "Reward Points Settings",
    reward_points_settings_description:
        "Manage how reward points are earned and spent on your store. To enable Reward Points for your store, please call Goopter support at (778)379-7918.",
    min_points_redemption_amount: "Minimum points redemption value amount",
    min_points_redemption_amount_tip:
        "Minimum balance value required to redeem points. (i.e. when set to 5, customers need a minimum of $5 worth of points balance in order to redeem their points).",
    max_points_redemption_setting: "Maximum points redemption setting",
    max_points_redemption_type: "Maximum points redemption limit type",
    max_points_redemption_type_tip:
        "The method used to determine the maximum value a customer is allowed to redeem per order.",
    max_points_redemption_per_order: "Maximum redemption percentage/amount per order",
    max_redemption_percentage_per_order: "Maximum redemption percentage per order",
    max_redemption_amount_per_order: "Maximum redemption amount per order",
    max_redemption_percentage_per_order_tip:
        "The maximum percentage a customer is allowed to redeem per order. (i.e. when set to 10, customers can only redeem 10% of their order amount with points).",
    max_redemption_amount_per_order_tip:
        "The maximum amount the customer is allowed to redeem with points per order (i.e. when set to 10, customers can redeem a maximum of $10 in points per order).",
    no_limit: "No Limit",
    percentage_of_order: "Percentage of Order",
    dollar_value: "Amount Value",
    max_redemption_warn: "*Maximum redemption amount must be greater than the minimum points redemption value amount.",
    orders: "Orders",
    order_id: "Order ID",
    order_list: "Order List",
    ord_dt: "Order Time",
    exp_dt: "Due Time",
    ord_total: "Amount",
    s_method: "Order Type",
    p_method: "Payment Method",
    table_number_no_pound_sign: "Table",
    start_processing_message: "Are you sure you want to accept the order?",
    mark_as_complete_message: "Are you sure you want to mark order(s) as complete?",
    cancel_order_message: "Are you sure you want to cancel order(s)?",
    accept: "Accept",
    complete: "Complete",
    reject: "Reject",
    refund: "Refund",
    print: "Print",
    quick_print: "Quick Print",
    //refund
    refund_amount: "Amount",
    refund_all: "Refund All",
    refund_remark: "Remark",
    //dialog
    "edit-shipping-fee-message":
        "Update shipping fee will result in cancellation of previous order and create a new order with updated shipping cost, do you want to continue?",
    edit_shipping_info: "Edit Shipping address",
    additional_info: "Additional Information",
    party_size: "Party Size",
    exp_time: "Requested Delivery Time",
    estimatedDeliTime: "Estimated Delivery Time",
    delivery_time: "Delivery Time",
    pickup_time: "Pick-up Time",
    "create-new-order-message": "Are you sure? This order will be canceled and a new one will be created instead.",
    order_and_account_information: "Order & Accnt Info",
    address_info: "Address Information",
    billing_address: "Billing Address",
    shipping_address: "Shipping Address",
    delivery_address: "Delivery Address",
    pickup_location: "Pick-up Location",
    payment_and_shipping_method: "Payment & Shipping Method",
    payment_info: "Payment Information",
    shipping_handling_info: "Shipping Handling Information",
    item_order: "Items Ordered",
    raw_total: "Subtotal",
    notes_for_this_order: "Notes for this order",
    alert_on_notification: "Alert on Notification",
    sub_comment: "Submit Comment",
    modify_discount: "Modify Discount",
    add_discount: "Add Discount",
    modify_tips: "Update Tips",
    add_tips: "Add Tips",
    add_comment: "Add Comment",
    modify_shipping_fee: "Modify Shipping Fee",
    edit_payment: "Update Payment",
    edit_extra: "Update Info",
    edit_payment_method: "Update Payment",
    update_payment_method: "Update Payment Method",
    "select_receipt(s)_to_print": "Select Receipt(s) to print",
    change_payment_to: "Are you sure you want to change payment method to ",
    //filter
    order_filter: "Order Filter",
    search_order_with: "Search Order With...",
    reject_order: "Reject Order",
    estimated_ready_time: "Estimated Ready Time",
    estimated_delivery_time: "Estimated Delivery Time",
    enter_reason_for_rejection: "Enter reason for rejection",
    ETA: "ETA",
    estimated_delivery_short: "Est. Delivery",
    req_time_short: "Req. Time",
    exp_time_stort: "Exp. Time",
    surcharge: "Surcharge",
    store_surcharge: "Online Surcharge Fee",
    order_surcharge: "Order Surcharge",
    not_paid: "Not Paid",
    authorized: "Payment authorized",
    unpaid: "Unpaid",
    modify_surcharge: "Modify Surcharge",
    add_surcharge: "Add Surcharge",
    remove_surcharge: "Remove Surcharge",
    remove_surcharge_confirm_message: "Are you sure you want to remove surcharge from this order",
    remove_tips: "Remove Tips",
    remove_tips_confirm_message: "Are you sure you want to remove tips from this order",
    go_to_live_order: "Go to Live Order",
    pending_order_warning: "The following orders are pending for acceptance.",
    pending_print_order_warning: "The following orders are not printed.",
    handle_asap: "Please handle ASAP",
    order_reminder: "Order Reminder",
    comment_order_pending: "New order",
    comment_order_processing: "Order changed to processing",
    comment_order_complete: "Order completed",
    comment_order_canceled: "Order canceled",
    comment_order_closed: "Order closed",
    comment_order_accepted: "Order is accepted",
    comment_order_being_prepared: "Order is being prepared",
    comment_order_ready_for_pickup: "Order is ready for pick-up",
    comment_order_ready_for_driver: "Order is ready for delivery",
    comment_order_out_for_delivery: "Order is being delivered",
    comment_order_served: "Order is served",
    comment_doordash_delivery_created: "La livraison DoorDash a été créée",
    comment_doordash_courier_assigned: "Le coursier DoorDash a accepté la tâche",
    comment_doordash_courier_arrived_at_store: "Le coursier DoorDash est arrivé au magasin",
    comment_doordash_courier_collected_package: "Le coursier DoorDash a récupéré le colis",
    comment_doordash_courier_arrived_to_customer: "Le coursier DoorDash est arrivé chez le client",
    comment_doordash_courier_delivered_package: "Le coursier DoorDash a livré le colis",
    comment_doordash_delivery_cancelled_by_doordash: "La livraison DoorDash a été annulée par DoorDash",
    comment_doordash_delivery_cancelled_by_merchant: "La livraison DoorDash a été annulée par le commerçant",
    update_item: "Change Item",
    update_original_item: "Update",
    out_of_stock_replace: "Replace",
    out_of_stock_remove: "Remove",
    reverse_current_change: "Revert the Change",
    customer_request: "Customer request",
    out_of_stock_today: "Out of stock today",
    out_of_stock_indefinitely: "Out of stock Indefinitely",
    choose_a_reason: "Reason for the change:",
    choose_an_action: "Choose an action",
    choose_an_item: "Choose an Item",
    update_an_item: "Update an item",
    replace_an_item: "Replace an item",
    pick_one: "Required, pick 1",
    pick_amount: "Required, pick {min}",
    pick_range: " Pick {min}-{max}",
    pick_range_up_to: "Choose up to {max}",
    pick_range_required: "Required, pick {min}-{max}",
    pick_optional: "Optional",
    apply_changes: "Finalize",
    suggest_changes: "Send to Customer",
    add_new_item: "Add Item",
    reverse_all_changes: "Cancel",
    remove_x: "Removed an item",
    replace_x_with_y: "Replaced an item",
    update_x: "Updated an item",
    add_x: "Added an item",
    are_you_sure_reverse_all: "Canceling the change will result in returning to the original order.",
    are_you_sure_send_to_customer:
        "The system will send the order changes to the customer. Please wait for the customer to confirm before processing the order.",
    are_you_sure_finalize:
        "Are you sure you want to finalize the changes to the order? Please ensure that you get customer confirmation before doing so.",
    confirm_cancel_changes: "Confirm Cancel the Changes",
    are_you_sure_reject_all: "Are you sure you want to reject this order?",
    continue: "Continue",
    no_item: "No items added yet.",
    no_options_available: "No options available",
    confirm_ready_for_pick_up: "Are you sure you want to mark it as “Ready for pick-up?",
    confirm_ready_for_delivery: "Are you sure you want to mark it as “Ready for delivery?",
    confirm_start_delivery: "Are you sure you want to update the status",
    confirm_start_preparing: "Are you sure you want to start preparing for this order?",
    "confirm_merge":
        "Merge will create a new order and mark all the unpaid orders as canceled and paid orders as complete. Are you sure you want to continue?",
    out_of_stock_warning_message:
        "The item you choose is out of stock, please change the availability setting in product availability before choosing it.",
    pending_for_customer_confirmation: "Pending for Customer Confirmation",
    order_picked_up: "Order Picked Up",
    start_delivery: "Start Delivery",
    start_preparing: "Start Preparing",
    order_start_preparing: "Order Start Preparing",
    start_preparing_now: "Start Preparing Now",
    start_prapring_now_check_box_message: "This is a scheduled order, you may check the switch to start preparing it.",
    order_canceled: "Order Canceled",
    pending_for_store_confirmation: "Pending Store Confirmation",
    store_accepted: "Order Accepted",
    store_is_preparing: "Store Preparing",
    ready_for_pick_up: "Ready for Pick-up",
    ready_for_delivery: "Ready for Delivery",
    ready_for_courier_pick_up: "Ready for the Courier to Pick-up",
    rejected_by_courier: "Rejected by Courier",
    courier_assigned: "Courier Assigned",
    courier_accepted: "Courier Accept the Task",
    courier_heading_to_store: "Courier Heading to the Store",
    courier_arrive_at_the_store: "Courier Arrived at the Store",
    courier_collected_the_package: "Courier Collected the Order",
    courier_heading_to_customer: "Courier heading to customer",
    courier_arrive_at_customer: "Courier Heading to Customer",
    delivered_order: "Delivered Order",
    new_comment_for_order: "New Comment for order %s: %s",
    category_all: "Tous",
    category_new: "Nouveau / En attente",
    category_preparing: "En préparation",
    category_ready: "Prêt",
    category_delivering: "En livraison",
    category_accepted: "Accepté",
    category_scheduled: "Programmé",
    category_completed: "Terminé",
    category_canceled: "Annulé / Rejeté",
    category_closed: "Fermé",
    category_fraud_suspected: "Fraude suspectée",
    category_serve_restaurant: "Servi",
    category_serve_others: "Servi",
    category_picked_up_package: "Colis Ramassé",
    category_enroute_to_dropoff: "En Route Vers La Livraison",
    category_arrived_at_dropoff: "Arrivé Au Point De Livraison",
    status_new: "New",
    status_pending: "Pending",
    status_preparing: "Preparing",
    status_ready: "Ready",
    status_delivering: "Delivering",
    status_accepted: "Accepted",
    status_scheduled: "Scheduled",
    status_serve_restaurant: "Served",
    status_serve_others: "Served",
    status_picked_up_package: "Colis Ramassé",
    status_enroute_to_dropoff: "En Route Vers La Livraison",
    status_arrived_at_dropoff: "Arrivé Au Point De Livraison",  
    search_order: "Search by name or number",
    "search_order_live_order": "Search by name, phone or table number",
    view_order_history: "View Order History",
    no_order: "No Orders",
    no_selected_order: "No Selected Order",
    confirm_on_hold:
        "Turn off the online status will result in not placing an order on your website. Are you sure you want to continue?",
    total_item_count: "{total} Item(s)",
    over_due_x: "Over Due %s",
    delivery_location: "Zone",
    all_products: "All Products",
    sold_out_only: "Sold Out Only",
    available_only: "Available Only",
    advanced_mode: "Advanced Mode",
    assigning_items: "Assigning Items",
    are_you_sure_delete_option: "Are you sure you want to delete this option?",
    are_you_sure_delete_product: "Are you sure you want to delete this product?",
    are_you_sure_delete_image: "Are you sure you want to delete this image?",
    are_you_sure_delete_store: "Are you sure you want to delete this store?",
    delete_related_product_warning_message:
        "This product exists as a related product for the following item(s). Deleting this product will remove it from any related products list that includes it.{item_list}",
    delete_upsell_product_warning_message:
        "This product exists as an up-sell product for the following item(s). Deleting this product will remove it from any upsell products list that includes it.{item_list}",
    delete_discounted_upsell_product_warning_message:
        "This product is used in at least one discounted up-sell rule. Deleting this product will remove it from all rules.",
    confirm_online_status: "Warning",
    confirm_online_status_content:
        "Turning off the online status will pause online ordering on your website. Are you sure you want to continue?",
    export_orders: "Export Orders",
    update_order_success: "Update Order Success",
    order_status_updated: "Order Status Update",
    update_suggestions_success: "Update Suggestion Success",
    select_x_items: "Select {x} Item(s)",
    done_update: "Done Updating",
    last_x_days: "Last {x} days",
    custom: "Custom",
    clear: "Clear",
    item_lan: "Item Language",
    export_order_filter_title: "Which order(s) do you want to export?",
    filter_by_x: "Filter by {x}",
    select_group_purchase_product: "Select group purchase item",
    sku_prefix: "SKU prefix",
    order_detail: "Order detail",
    order_detail_per_item: "Order Detail (one record per item)",
    item_detail: "Order Item Detail",
    item_summary: "Order Item Summary",
    order_detail_per_order: "Order Detail (one record per order)",
    all_in_one_report_excel: "All-in-one Report in Excel Format",
    export: "Export",
    over_due: "Over Due",
    more_actions: "More Actions...",
    more: "More...",
    no_export_record_found: "No Available Export Records Found",
    inaccurate_alert_message:
        "The current statistics numbers might be incorrect, e.g., the number of total order/total amount. You may click the refresh icon to get accurate numbers.",
    refresh: "Refresh",
    order_status: "Order Status",
    bulk_update: "Bulk Update",
    order_time: "Order Time",
    share_store: "Share Store",
    show_available_only: "Show available items only",
    search_by_item: "Search by item",
    require_utensil: "Request utensils, etc.",
    no_require_utensil: "No utensils required",
    braintree_payment_error_title: "Failed to mark order as complete",
    braintree_payment_error_content:
        "Failed to capture the payment for this order. Please check the reason in the order comment and follow up with the customer for the payment.",
    keep_order_open: "Keep order open for now",
    mark_as_complete_and_paid: "Mark order as complete & paid",
    mark_as_closed: "Mark order as closed (if the order has been paid through another order)",
    payment_method_change: "Payment Method Change",
    cancel_order: "Cancel Order",
    bulk_update_none_closable_message:
        "The dialog can not be closed during the update. You may pause/stop the task or wait until all the orders has been processed.",
    processing_orders: "Batch updating orders to {status}…",
    resume: "Resume",
    pause: "Pause",
    stop: "Stop",
    no_change: "No change",
    task_complete: "Task Complete",
    task_canceled: "Task has been canceled",
    expected_ready_time: "Exp. Ready Time",
    commute_time: "Commute Time",
    esitmated_arrival: "Est. Arrival",
    estimated_ready_pickup_time: "Estimated Pick-up Ready Time",
    estimated_commute_time: "Estimated Commute Time",
    order_ready_time_setting: "Confirm Order",
    min: "Min",
    max: "Max",
    group_by_table: "Group by Table",
    serve_restaurant: "Served",
    serve_others: "Served",
    start_serving_message_restaurant: "Are you sure you want to mark it as “Served”",
    start_serving_message_other: "Are you sure you want to mark it as “Served”",
    merge: "Merge",
    group_by_items: "Group by Items",
    payment_transactions: "Payment Transactions",
    transaction_id_title: "TRANSACTION ID",
    transaction_type_title: "TRANSACTION TYPE",
    transaction_time_title: "TRANSACTION TIME",
    create_order: "Create Order",
    find_or_create_a_customer: "Find or create a customer",
    create_a_new_customer: "Create a new customer",
    delete_customer: "Delete Customer",
    delete_customer_warning: "Are you sure you want to delete this customer?",
    no_phone_number: "No Phone Number",
    no_email: "No Email",
    notes: "Notes",
    please_add_some_items: "Please Add Some Items",
    please_choose_a_customer: "Please choose a customer",
    please_input_shipping_method: "Please input shipping method",
    click_to_input_shipping_addresss: "Click to Input Shipping Address",
    click_to_select_payment_method: "Click to Select Payment Method",
    please_input_valid_credit_card: "Please input valid credit card",
    place_order_with_x: "Place Order - {x}",
    secure_connection: "Information is sent over a secure connection",
    are_you_sure_remove_gift_card: "Are you sure you want to remove this gift card from your payment?",
    set_giftcard: "Select Gift Card",
    expiry_date: "Expiry date",
    gift_cards_points_alert:
        "You are not allowed to use the gift card when you redeem points. To use a gift card, please remove the reward points first.",
    points_gift_cards_alert_message:
        "You are not allowed to use the reward points when you use gift card. To use reward points please remove the gift card first.",
    set_points: "Tap to redeem points",
    point_balance: "Balance: {balance}",
    redeem_points_dialog_title: "Redeem Points",
    redeem_x: "Redeem: {x}",
    please_input_pickup_time: "Please choose pick-up time",
    please_input_delivery_time: "Please choose delivery time",
    edit_discount: "Edit Discount",
    remove_discount: "Remove Discount",
    reason: "Reason",
    reason_helper: "Your customer can see this reason",
    invalid_input: "Invalid Input",
    item_oos: "Sorry, item(s) {oos_items} are out of stock.",
    add_items: "Add Items",
    select_category: "Select Category",
    "drafts": "Draft Orders",
    cloud_pos: "Cloud POS",
    "others": "Others",
    "deleted_quotes_successfully": "Quote(s) Deleted Successfully!",
    "confirm_delete_quotes": "Are you sure you want to delete these quotes?",
    "confirm_delete_quotes_content": "Are you sure you want to delete the selected {count} quotes?",
    "click_to_choose_a_customer": "Click to Choose a Customer",
    selected_item_s: "Selected Item(s)",
    export_select: "Export Selected",
    export_filter: "Export Filtered Orders",
    selected_order_ids: "Selected Order #",
    select_pickup_location: "Select Pick-up Location",
    select_shipping_options_description: "Please Choose Order Type",
    no_draft_order: "No Draft Order",
    add_gift_card: "Add Gift Card",
    bookvalue: "Book Value",
    book_value: "Book Value",
    date_created: "Created",
    name_slash_phone: "Name / Phone",
    confirm_delete_product: "Are you sure you want to delete this product?",
    no_gift_cards: "No giftcards.",
    add_edit_gift_card: "Add/Edit Gift Card",
    prepare_order_threshold: "Required time to start preparing an order",
    video_desc:
        'To support the video of the product, please upload the video to Youtube or Vimeo first. Open the web page on Youtube or Vimeo that contains the video, click Share, copy the video URL, and then paste the URL into the "Video URL" field.',
    giftcard_code: "Gift Card code",
    customer_name: "Customer Name",
    transaction_history: "Transaction History",
    view_transaction_history: "View Transaction History",
    view_points_history: "View Points History",
    add_credit: "Add Credit",
    transactions: "Transactions",
    credit: "Credit",
    charge: "Charge",
    order: "Order",
    delta: "Delta",
    gift_card_account: "Gift Card Accounts",
    successfully_added_credit: "Successfully added {value} to the gift card account.",
    successfully_charged_giftcard: "Successfully charged {value} on the gift card account.",
    insufficient_balance: "Insufficient Balance",
    insufficient_balance_message:
        "The amount that you are trying to charge is higher than the account's balance of {maxAmountRounded}.",
    hide_zero_balance_giftcard: "Hide zero balance gift card",
    sales_and_orders: "Sales and Orders",
    view_controls: "View Controls",
    net_sales: "Net Sales",
    total_sales: "Total Sales",
    total_orders: "Total Orders",
    average_order: "Average Order",
    customer_statistics: "Customer Statistics",
    total_customers: "Total Customers",
    new_customers: "New Customers",
    ret_customers: "Returning Customers",
    item_sales: "Item Sales",
    overview: "Overview",
    total_amount: "Total Amount",
    top_items_by_sale: "Top Sale Items",
    least_items_by_sale: "Least Sale Items",
    quantity: "Quantity",
    sold: "Sold",
    average_order_price: "Average Order Price",
    shipment_tracking_info: "Shipment Tracking Information",
    carrier: "Carrier",
    select_carrier: "Select a Carrier",
    track_number: "Tracking Number",
    delete_order_tracking_confirmation: "Are you sure you want to delete this order tracking record?",
    duplicate_order_tracking_message:
        "The order tracking information entered already exists, please try again with a different value.",
    notify_customer: "Notify Customer",
    add_tracking: "Add Tracking",
    add_printer: "Add Printer",
    print_test_page: "Print Test Page",
    receipt_delete_confirm_title: "Are you sure you want to delete this receipt ({receipt})",
    //print view
    table_number: "Table #",
    welcome_text1: "THANKS FOR YOUR BUSINESS",
    welcome_text2: "ORDER ONLINE",
    //Common Setting
    beep_on_new_order: "Beep On New Order",
    alert_on_new_order: "Alert On New Order",
    auto_print: "Auto Print",
    beep_on_print: "Beep On Print",
    sound_alert_on_printer_disconnect: "Sound Alert On Printer Disconnect",
    //individual
    receipt_name: "Receipt Name",
    printer_name: "Printer Name",
    enable_printing: "Enable Printing",
    autoPrint: "Auto Print",
    receipt_template: "Receipt Template",
    show_price: "Show Price",
    split_items: "Print one item per receipt",
    with_QR_code: "Print QR code on the bottom",
    print_selected_categories_only: "Print Selected Categories Only",
    add_receipt: "Add Receipt",
    delete_receipt: "Delete Receipt",
    please_select_printer: "Please select printer",
    receipt_name_missing: "Please add a receipt name",
    printer_missing: "Please add a printer",
    primary_lan_missing: "Please add a primary language",
    select_printer: "Select Printer",
    save_receipt: "Save Receipt",
    receipt_list: "Receipt List",
    "select-template-placeholder": "Select template",
    "select-first-language": "Select first language",
    "select-second-language": "Select second language",
    included_products: "Additional products",
    included_products_info: "Add the products you want to ensure show up on your receipt",
    excluded_products: "Excluded products",
    excluded_products_info: "Add the products you want to ensure do not show up on your receipt",
    categories_included: "Included Categories",
    categories_included_info: "Add the categories you want to show up on your receipt",
    categories_excluded: "Excluded Categories",
    categories_excluded_info: "Add the categories you do not want to show up on your receipt",
    auto_print_on_status: "Only auto print on orders with status",
    new_not_paid: "New orders(not paid)",
    new_paid: "New orders(paid)",
    order_accepted: "Order accepted/paid",
    order_update: "Order content updated",
    order_complete: "Order completed",
    order_cancel: "Order canceled",
    missing_auto_print_status: "Please select an auto print status",
    repeat_product: "Product was selected, please remove it from the previous selection first",
    repeat_category: "Cateogry was selected, please remove it from the previous selection first",
    missing_selected_categories_only:
        'Please choose categories or products in the "Print Selected Categories Only" option',
    total_included_tips: "Order total already includes tips",
    surcharge_paid: "Surcharge (Paid)",
    surcharge_unpaid: "Surcharge (Unpaid)",
    surcharege_paid_message: "The surcharge amount is not included in this order. Please refer to order #{id}",
    surcharge_paid_to_message: "This order is to pay for the surcharge of order #{id}",
    total_refunded: "Total Refunded",
    print_time: "Print Time",
    reprint: "Reprint",
    scan_qr_to_order: "Scan the QR code to order",
    referral: "Referral",
    referrals: "Referrals",
    total_referrals: "Total Referrals",
    new_referrals: "New Referrals",
    new_referral_orders: "New Referral Orders",
    average_referral_orders: "Average Referral Orders",
    referrals_count: "Referrals Count",
    reward_balance: "Reward Balance",
    add_reward_points: "Add Rewards Points",
    redeem_reward_points: "Redeem Rewards Points",
    add_rewards_points_title: "Add Rewards Points to {customer_name}",
    redeem_rewards_points_title: "Redeem Rewards Points from {customer_name}",
    points_history: "Points History",
    referral_order_history: "Referral Order History",
    customer_referrals: "Customer Referrals",
    date_time: "Date/Time",
    points_change: "Points Change",
    add_points: "Add Points",
    add_by_points: "Points",
    add_by_value: "Value",
    redeem_points: "Redeem Points",
    redeem: "Redeem",
    redeem_all: "Redeem All",
    view_referrals_list: "View Referrals List",
    referred_orders_amount: "Referred Orders",
    earned_points: "Earned Points",
    min_num_ord: "Minumum # of Orders",
    max_num_ord: "Maximum # of Orders",
    min_ref_ord_amt: "Minumum Referral Order Amount",
    max_ref_ord_amt: "Maximum Referral Order Amount",
    min_points_earned: "Minimum Points Earned",
    max_points_earned: "Maximum Points Earned",
    referral_points: "Referral Points",
    min_points_bal: "Minimum Points Balance",
    max_points_bal: "Maximum Points Balance",
    min_reward_pts: "Minimum Reward Points",
    max_reward_pts: "Maximum Reward Points",
    reward_action_admin: "",
    reward_action_register: "",
    reward_action_newsletter: "",
    reward_action_review: "",
    reward_action_tag: "",
    reward_action_order_extra: "Reward points from your order",
    reward_action_order: "Reward points redeemed for order payment",
    reward_action_invitation_customer: "Customer referral rewards",
    reward_action_invitation_order: "Order referral rewards",
    reward_action_creditmemo: "Received Reward Points from the merchant",
    reward_action_salesrule: "Reward points from your order",
    reward_action_revert: "Redeemed points",
    reward_action_creditmemo_void: "Refunded referral order",
    referral_range: "Referral Range",
    referral_balance_range: "Referral Balance Range",
    referral_order_range: "Referral Order Range",
    new_store_management: "Store Management",
    signup_id: "Signup ID",
    admin_id: "Admin ID",
    category_id: "Category ID",
    rejected: "Rejected",
    need_approval: "Need Approval",
    not_found_description: "Oops! Page Not Be Found",
    not_found_detail_description:
        "Sorry but the page you are looking for does not exist, has been removed/renamed, or is temporarily unavailable",
    back_to_home: "Back to homepage",
    enter_table_no: "Please enter table number",
    enter_party_size: "Please enter party size",
    enter_tableno_party_size: "Please enter table number and party size",
    number_of_reviews: "View %s reviews",
    only_have_stock_option: "The %1$s in option %2$s only have %3$d in stock.",
    asset_statements: [
        {
            "relation": ["delegate_permission/common.handle_all_urls"],
            "target": { "namespace": "web", "site": "https://m.goopter.com" },
        },
    ],
    payment_device_name: "Payment Device Name",
    port_number: "Port Number",
    port_number_hint: "Port Number",
    kiosk_device_name: "Kiosk Device Name",
    "3rd_party_package_hint": "3rd Party Package Name",
    order_items: "Order Items",
    add_to_cart_success: "Product has been successfully added to the cart",
    add_back: "Add Back",
    reward_points_extra_text: "The points balance will be updated on your account upon order completion.",
    with_option: "With Option(s)",
    qrcode: "QRcode",
    email_required: "Email is required",
    password_required: "Password is required",
    phone_required: "Phone is required",
    vc_required: "Verification code is required",
    please_input_at_least_two_characters: "Please input at least two characters",
    google: "Google",
    wechat: "WeChat",
    apple: "Apple",
    phone_num: "Phone #",
    change_address: "Change Address",
    purchase_a_giftcard: "Purchase a Gift Card",
    self_checkout_disabled: "Quick pay is not available for this store.",
    camera_error_title: "Camera access denied",
    camera_error_description_dine_in: "Please allow camera access to scan the QR code for ordering.",
    camera_error_button_text: "Enable Camera",
    social_binding: "Social Binding",
    linked: "Linked",
    braintree_credit_card_invalid_message: "Something went wrong. Check your card details and try again.",
    your_order: "Your Order",
    clover_setting_tip: "Clover (e-commerence)",
    global_payment_setting_tip: "Global payment (Payfields)",
    account_credential: "Account credential",
    global_payment_api_key: "Global payment API key",
    api_secret: "API secret",
    duplicate_name_msg: "There is already a menu with this name. Please use a different one.",
    print_layout_on: "Print layout ON",
    print_layout_off: "Print layout OFF",
    no_admin_user_access: "Save unsuccessful: You do not have the required permissions to perform this action.",
    cloud_solution: "Cloud Solution",
    semi_integrated_solution: "Semi Integrated Solution",
    enable_special_request: "Enable Special Request",
    special_request_tip: "Turn this on to allow users to submit special requests when adding this item to the cart.",
    apiSecret: "Api Secret",
    auth_key: "Auth Key",
    config_code: "Config Code",
    dev_id: "Developer ID",
    key_id: "Key ID",
    signing_secret: "Signing Secret",
    third_party_delivery_error: "Can only have one third party delivery enabled at a time",
    third_party_field_error_message: "Error: You must fill out all fields",
    doordash_delivery: "Doordash Delivery",
    delivery_request_title: "Request Delivery",
    request_delivery_estimated_fee: "Estimated delivery cost for this order: {estimatedDeliveryFee} + Tax",
    doordash_delivery_confirmation:
        "The delivery request has been sent, a courier will be assigned to your order based on the availability and your order urgency.",
    successful_cancellation: "The delivery request has been cancelled, no service charge will be applied.",
    doordash_cancel_failure:
        "Failed to cancel the delivery request, please call 855-599-7066 to request cancellation, quote your DoorDash Drive ID: {delivery_task_id}, customer name: {name}, customer phone number: {customer_phone_number}, and your store name.",
    doordash_cancel_failure_note: "Please note, the original delivery fee plus tax will still be applied.",
    cancellation_confirm_title: "Confirm Cancellation",
    cancel_confirmation_msg: "Are you sure you want to cancel this order's delivery request?",
    estimated_delivery: "Estimated Delivery Fee/ Time",
    estimated_delivery_fee: "{price}  + Tax",
    delivery_fee_try_again: "Failed to retrieve data",
    track_delivery: "Track Delivery",
    request_delivery_service: "Request Delivery Service",
    cancel_delivery: "Cancel Delivery",
    estimated_doordash_delivery_time: "Deliver by {time}",
    use_doordash: " Use DoorDash Delivery",
    delivery_sent: "Request Sent",
    request_cancelled: "Request Cancelled",
    both_disabled_error: "Please enable one of the 3rd party delivery services",
    create_delivery_request_failed: "Failed to create delivery task, please try again later.",
    cancel_delivery_request: "Cancel Delivery Request",
    cancel_failure_subtitle_one: "Please contact support team at ",
    cancel_failure_subtitle_two: " to request cancellation.",
    cancel_failure_list_title: "Be ready to provide the following information:",
    cancel_failure_doordash_id: "Doordash ID: ",
    cancel_failure_customer_name: "Customer name: ",
    cancel_failure_customer_phone_number: "Customer phone number: ",
    cancel_failure_store_name: "Store name: ",
    failed_cancel_delivery_request: "Failed to Cancel Delivery Request",
    default_item_name: "Default Item Name ",
    default_order_value: "Default Order Value",
    default_tip_amount: "Default Tip Amount",
    default_pick_up_instructions: "Default Pickup Instructions",
    contains_alcohol: "Contains Alcohol",
    timeslot_delta_title: "Time delta for each time slot",
    timeslot_delta_hints:
        "The time range in each element of the user-selected time slots for pickup/delivery. Set this to 0 to show only single time values (i.e. 9:00 am, 9:30 am, 10:00 am). \n e.g When set to 15, the time slot list format might display as 9:00 am - 9:15 am, 9:15 am - 9:30 am…",
    timeslot_gap_title: "Time difference between adjacent time slots ",
    timeslot_gap_hints:
        "The time difference between two adjacent elements in the user-selected time slots for pickup/delivery. \n eg. When this is set to 30 and Time Delta is set to 0, the time slots will display as 9:00 am, 9:30 am, 10:00 am. When Time Delta is set to 15, the time slots will display as 9:00 am - 9:15am; 9:30 am - 9:45 am; 10 am - 10:15 am...",
    default_pick_up_delay_time: "Pickup Delay Time",
    pickup_delay_time_hint:
        "The default delay time needed to prepare an order before it can be ready for pick up by the courier. e.g. When set to 30, the default start time for the courier to pick up the order will be 30 minutes from the current time.",
    pre_order_days: "Preorder Days",
    create_customer_success: "Successfully created a customer",
    delivery_options: "Delivery Options",
    meet_at_door: "Meet at door",
    leave_at_door: "Leave at door",
    meet_outside: "Meet outside",
    delivery_instructions: "Delivery Instructions",
    doordash_should_send_notifications: "Send Delivery Status Notifications by SMS",
    delivery_fee_credit_limit: "Shipping Fee Overdraft Credit Limit",
    delivery_fee_markup: "Delivery Fee Markup",
    delivery_failed_title: "Create Delivery Task Failed",
    error_enter_allowed_country_field:
        "Specific Countries option is selected: Please enter an allowed country before saving",
    insufficient_fund_title: "Fonds insuffisants pour la demande de livraison.",
    insufficient_fund_msg:
        "Vous n'avez actuellement pas suffisamment de fonds pour utiliser la livraison via un tiers. Veuillez contacter le support à l'adresse support@goopter.com ou appeler le 778-379-7918 pour ajouter des fonds.",
    failed_to_create_delivery_message_line1: "Failed to create a delivery task.",
    failed_to_create_delivery_message_line2: "Please correct the problem and try again.",
    failed_to_cancel_delivery_message: "Failed to canel the task, please see reason from the following error message.",
    failed_to_update_delivery_message: "Failed to update the task, please see reason from the following error message.",
    input_order_value: "Enter Order Value",
    input_pickup_time: "Select Pickup Time",
    generate_order_id: "Generate Order #",
    input_dropoff_time: "Select Dropoff Time",
    input_delivery_time: "Select Delivery Time",
    assigning_driver: "Assigning driver...",
    order_value: "Order Value",
    mandatory_fields_for_delivery_task: "Mandatory fields for creating a delivery task",
    review_booster: "Customer Review Booster",
    enable_automated_review_sms: "Enable automated SMS review reminder",
    sms_review_reminder_style: "SMS review reminder style",
    social_review_url: "URL to post social review",
    review_hint: "Customer review hints",
    review_delay_time: "Delay time to send automated review reminder",
    no_duplicate_review_same_day: "Only allow one review per user per day",
    message_limit_per_month: "Total SMS message limit per month",
    review_booster_setting: "Customer Review Booster Setting",
    review_booster_setting_description:
        "This feature reminds customers to give their feedback after completing an order with the merchant, and encourage them to post their positive reviews on social media.",
    generate_google_review: "Generate Google Review URL",
    failed_to_refund: "Failed to refund",

    // TOADD
    "pickUp.address": "Adresse de ramassage",
    "dropOff.address": "Adresse de dépôt",
    "dropOff.time": "Heure de dépôt",
    "pickUp.time": "Heure de ramassage",
    "additionalInfo.itemsInfo": "Articles",
    "Meet outside": "Rencontrer dehors",
    "additionalInfo.tipAmount": "Pourboires",
    pickup_address: "Pick Up Address",
    dropoff_address: "Drop Off Address",
    dropoff_time: "Drop Off Time",
    electronic_item: "Electronic Item",
    sub_total: "Sub Total",
    new_option: "New Option",
    new_item: "New Item",
    please_select: "Please Select",
    delivery_remark_placeholer: "Additional delivery note, etc...",
    delivery_option: "Delivery Option",
    missing: "Missing",
    firstname: "First Name",
    lastname: "Last Name",
    zipcode: "Zip Code",
    new_pending: "New Pending",
    driver_info_label: "Driver Information",
    assigning_courier: "Assigning Courier...",
    confirmed: "Confirmed",
    enroute_to_pickup: "Enroute To Pickup",
    arrived_at_pickup: "Arrived At Pickup",
    picked_up: "Picked Up",
    enroute_to_dropoff: "Enroute To Dropoff",
    arrived_at_dropoff: "Arrived At Dropoff",
    status_delivered: "Delivered",
    status_cancelled: "Cancelled",
    delivery_task_cancelled_by_merchant: "Livraison Annulée Par Le Marchand",
    delivery_task_cancelled_by_3rd_party_delivery_partner: "Livraison Annulée Par Le Fournisseur",
    cancel_task: "Cancel Task",
    task_detail: "Request Local Delivery",
    task_items: "Task Items",
    task_delivery_status: "Task Delivery Status",
    pick_up: "Pick Up",
    drop_off: "Drop Off",
    ongoing: "On Going",
    tip_amount: "Tip Amount",
    order_info: "Order Info",
    time_info: "Time Info",
    requested_dropoff_time: "Requested Dropoff Time",
    requested_pickup_time: "Requested Pickup Time",
    est_pickup_time: "Estimated Pickup Time",
    est_dropoff_time: "	Estimated Delivery Time",
    new_task_btn_label: "New Task",
    csv: "CSV",
    task_details: "Request Local Delivery",
    edit_order_id: "Edit Order ID",
    request_local_delivery: "Request Local Delivery",
    discard_task: "Discard Task",
    add_an_item: "Add An Item",
    add_an_option: "Add An Option",
    create_on: "Created on",
    total_delivery_fee: "Delivery Fee Total",
    total_balance: "Total balance $",
    proceed: "Proceed",
    TBD: "TBD",
    get_delivery_fee_err: "Unable to get delivery fee: ",
    confim: "Confim",
    distance_error_msg: "The specified address exceeds the delivery distance, please choose a different address.",
    new_address_error_msg: "Unable to add new address, please choose a different address and try again.",
    cancel_create_task_msg: "You have unsaved changes, are you sure you want to abandon creating task?",
    discard_change_msg: "You have unsaved changes, are you sure you want to discard these changes?",
    change_pickup_time_warning_msg:
        "You can only specify one specific time for pick-up or drop-off time. If you provide drop-off time, the pick-up time will be changed to ASAP. Do you want to proceed with the change?",
    change_dropoff_time_warning_msg:
        "You can only specify a specific time for pick-up time or drop-off time, if you provide pick-up time, the drop-off time will be changed to ASAP. Do you want to proceed with the change?",
    remove_dropoff_time_warning_msg:
        "You can only specify one particular time for pick-up or drop-off time. If you provide the drop-off time, the pick-up time will be removed. Do you want to proceed with the change?",
    remove_pickup_time_warning_msg:
        "You can only specify a particular time for pick-up time or drop-off time, if you provide delivery time, the pick-up time will be removed. Do you want to proceed with the change?",
    unexpected_error_msg: "unexpected error",
    tracking_url_not_found: "Tracking Url Not Found.",
    input: "Input",
    delivery_fee_total: "Delivery Fee Total",
    grocery: "Grocery",
    cake: "Cake",
    flower: "Flower",
    document: "Document",
    appliance: "Appliance",
    furniture: "Furniture",
    other: "Other",
    contact_info: "Contact Information",
    search_address_placeholder: "Search address by phone number or name",
    delivery_task_created: "The delivery task has been created, order #{order_id}.",
    create_address: "Create address",
    succeeded_msg: "{msg_type} successfully.",
    delivery_cancelled: "Order #{id} has been cancelled",
    discard_changes: "Discard Changes",
    new_address: "New Address",
    search_result_msg: "Search result · {records} record(s) found",
    search_customer: "Search Customer",
    input_address: "Input Address",
    update_address: "Update address",
    item_option: "Item Option",
    new_item_option: "New Item Option",
    value_or_tips: "Order Value/Tips",
    edit_or_add_address_info: "Add/Edit Address",
    not_authorized: "Not Authorized",
    not_authorized_delivery_msg:
        "You are not authorized to use the 3rd party local delivery service, please contact Goopter to setup the local delivery service account, email: support@goopter.com, Tel: 778-379-7918",
    update_success: "Update Success",
    failed_to_update_delivery_task: "Failed to update task",
    courise_name: "Courier Name",
    assigned_at: "Assigned On",
    delivered_at_from: "Delivered On",
    na: "N/A",
    cancel_changes: "Cancel Changes",
    statuses: "Statuses",
    keywords: "Keywords",
    addressLineTwo: "Unit #/Floor",
    buzzCode: "Buzz Code",
    componentName: "Company/Building name",
    add_balance: "Add Balance",
    delivery_fee_balance_update: "Delivery Service Credit Balance Update",
    current_balance: "Current Balance",
    add_balance_action: "Action",
    add_balance_credit_label: "Credit",
    add_balance_debit_label: "Debit",
    new_balance: "New Balance",
    balance_updated: "Balance Updated",
    partially_refunded: "Partiellement Remboursé",
    fully_refunded: "Remboursé",
    voided: "Annulé",
    amount_paid: "Montant Payé",
    reward_points_redeemed: "Points de récompense échangés",
    gift_card_redeemed: "Carte-cadeau échangée",
    partial_refund_limit_description: "Note : Si la commande a été payée avec un mélange de points ou de cartes-cadeaux, ainsi que d'autres méthodes de paiement, le montant maximum du remboursement partiel ne doit pas dépasser le montant payé.",
};
