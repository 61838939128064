import { Button, Drawer, Form } from "antd";
import { RootState } from "app/reducer";
import React from "react";
import { useIntl } from "react-intl";
import { connect, ConnectedProps } from "react-redux";
import { getDeliveryTasks } from "../../../services/delivery_request";
import {
    clearSearchQuery,
    SearchConditionsPath,
    setSearchQuery,
    setSearchStatues,
    setShouldDrawerOpen,
} from "../../../slices/delivery_request";
import { Row } from "../constants";
import { FormInputComponent, INPUT_TYPE } from "./Form";
import { buildSearchRequestBody } from "./helper";

const DrawerFilter: React.FC<PropsFromRedux> = ({
    shouldOpen,
    setShouldDrawerOpen,
    setSearchQuery,
    clearSearchQuery,
    paginations,
    getDeliveryTasks,
    setSearchStatues,
    onActiveTask,
}) => {
    const { searchConditions } = paginations;
    const intl = useIntl();
    const DRAWER_FILTER_ROWS: Row<typeof searchConditions>[] = [
        {
            state: searchConditions,
            name: "keywords",
            inputDataPath: "quickSearch",
            inputType: INPUT_TYPE.TEXT,
            placeholder: "keyworks",
        },
        {
            state: searchConditions,
            name: "courise_name",
            inputDataPath: "drawerFilter.courierName",
            inputType: INPUT_TYPE.TEXT,
            placeholder: "courise_name",
        },
        {
            state: searchConditions,
            name: "statuses",
            inputDataPath: "statuses",
            inputType: INPUT_TYPE.CHECK_BOXES,
            placeholder: "statuses",
            checkBoxOptions: ["all", "new_pending", "ongoing", "status_cancelled", "status_delivered"].map((v) =>
                intl.formatMessage({ id: v })
            ),
        },
        {
            state: searchConditions,
            name: "create_on",
            inputDataPath: "drawerFilter.createdDateRange",
            inputType: INPUT_TYPE.DATE_RANGE_PICKER,
            placeholder: "createdAtFrom",
        },
        {
            state: searchConditions,
            name: "assigned_at",
            inputDataPath: "drawerFilter.assignedDateRange",
            inputType: INPUT_TYPE.DATE_RANGE_PICKER,
            placeholder: "assigned_at_from",
        },
        {
            state: searchConditions,
            name: "delivered_at_from",
            inputDataPath: "drawerFilter.deliveredDateRange",
            inputType: INPUT_TYPE.DATE_RANGE_PICKER,
            placeholder: "delivered_at_from",
        },
    ];

    const handleCloseDrawer = () => {
        setShouldDrawerOpen(false);
    };

    const handleOnChange = ({ key, value }: { key: SearchConditionsPath; value: any }) => {
        if (key === "statuses") {
            setSearchStatues(value);
        } else {
            setSearchQuery({ key, value });
        }
    };

    const handleClearBtnClick = () => {
        clearSearchQuery();
    };

    const handleSearchSubmit = () => {
        getDeliveryTasks(buildSearchRequestBody(paginations, onActiveTask));
    };

    return (
        <Drawer
            placement="right"
            width={550}
            onClose={handleCloseDrawer}
            closable={true}
            key="drawerFilter"
            visible={shouldOpen}
        >
            <Form>
                {DRAWER_FILTER_ROWS.map((row) => {
                    return (
                        <div key={row.name}>
                            <div>{intl.formatMessage({ id: row.name })}</div>
                            <div>
                                <FormInputComponent
                                    state={searchConditions}
                                    inputType={row.inputType}
                                    inputDataPath={row.inputDataPath}
                                    name={row.name}
                                    handleOnChange={handleOnChange}
                                    checkBoxOptions={row.checkBoxOptions}
                                />
                            </div>
                        </div>
                    );
                })}
                <div style={{ display: "flex", justifyContent: "space-around", padding: "20px" }}>
                    <Button onClick={handleSearchSubmit}>{intl.formatMessage({ id: "submit" })}</Button>
                    <Button onClick={handleClearBtnClick}>{intl.formatMessage({ id: "reset" })}</Button>
                </div>
            </Form>
        </Drawer>
    );
};

const mapStateToProps = (state: RootState) => {
    return {
        paginations: state.deliveryRequests.paginations ?? {},
        shouldOpen: state.deliveryRequests.drawer.shouldOpen,
        onActiveTask: state.deliveryRequests.onActiveTask,
    };
};

const mapDispatchToProps = {
    setShouldDrawerOpen,
    setSearchQuery,
    clearSearchQuery,
    getDeliveryTasks,
    setSearchStatues,
};

const reduxConnenter = connect(mapStateToProps, mapDispatchToProps);
type PropsFromRedux = ConnectedProps<typeof reduxConnenter>;
export default reduxConnenter(DrawerFilter);
