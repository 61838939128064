import React, { Component } from "react";
import _reverseConfig from "config/config-reverse-map";
import config from "config";
import { Modal, DatePicker, TimePicker, Checkbox, Spin, Button as AntdButton } from "antd";
import { injectIntl, FormattedMessage } from "react-intl";
import moment from "moment";
import _ from "lodash";
import { connect } from "react-redux";
import "./_processing-modal.scss";
import { VscCalendar } from "react-icons/vsc";
import { IoMdAdd, IoIosRemove } from "react-icons/io";
import { Button, withStyles } from "@material-ui/core";
import { getDefaultETA, isScheduledOrder, getCommuteTime, getDeliveryStatusNumber } from "pages/order-history/helper";
import _actions from "slices/live-order";
import helper from "util/helper";
import { DELIVERY_STATUS_PENDING } from "../../../delivery_request/constants";
import { FiRefreshCcw } from "react-icons/fi";
import { createDoordashDelivery, fetchDeliveryFeeEstimate } from "../../../../services/store";
import { PREFERRED_DELIVERY_METHOD_VALUES } from "../../../settings/pages/pickup-delivery/constants";
import { HALF_SECOND_IN_MS } from "app/constants";

const DATE_FORMAT = "YYYY-MM-DD";
const DIPLAY_DATE_FORMAT = "ddd MMM DD";
const TIME_FORMAT = "h:mm A";
const DATE_TIME_FORMAT = "YYYY-MM-DD HH:mm:ss";

const ETA_DEFAULT_ADD_MINS = 15;
const ETA_ADD_MINUS_STEP = 5;

const DELIVERY_TIME_FORMAT = "h:mm A, ddd MMM DD";

const StyledButton = withStyles({
    root: {
        lineHeight: 1.3,
        fontSize: 17,
        paddingLeft: 10,
        paddingRight: 10,
        textTransform: "initial",
        border: "1px #2196f3 solid",
        minWidth: "initial",
        maxWidth: "initial",
        color: "#2196f3",
        "&:hover": {
            backgroundColor: "#1769aa",
            borderColor: "#1769aa",
            boxShadow: "none",
            color: "white",
        },
        "&:active": {
            boxShadow: "none",
            backgroundColor: "#1769aa",
            borderColor: "#1769aa",
            color: "white",
        },
    },
})(Button);

const StyledActiveButton = withStyles({
    root: {
        lineHeight: 1.3,
        fontSize: 17,
        textTransform: "initial",
        borderColor: "#2196f3",
        color: "white",
        backgroundColor: "#2196f3",
        "&:hover": {
            backgroundColor: "#1769aa",
            borderColor: "#1769aa",
            boxShadow: "none",
            color: "white",
        },
        "&:active": {
            boxShadow: "none",
            backgroundColor: "#1769aa",
            borderColor: "#1769aa",
            color: "white",
        },
    },
})(Button);

class ProcessingModal extends Component {
    constructor(props) {
        super(props);
        this.state = this.getInitialState();
    }

    isDoordashEnabled = () => !!this.props.thirdPartyDelivery.doordash.is_enabled;
    isThirdPartyPreferred = () =>
        _.get(this.props.storePickupDelivery, "preferred_delivery_method") ===
        PREFERRED_DELIVERY_METHOD_VALUES.third_party_local;
    isThirdPartyLocalDeilveryEnabled = () =>
        this.props?.storeFlags?.split(",")[config.STORE_FLAG_INDEX_MAP.enable_3rdparty_local_delivery] == "1";
    isCreatedThirdPartyDeliveryTaksOnAcceptOrderEnabled = () =>
        this.props?.storeFlags?.split(",")[config.STORE_FLAG_INDEX_MAP.create_3rdparty_delivery_task_on_accept_order] ==
        "1";

    getInitialState = () => {
        const isThirdPartyDeliveryOn = this.isThirdPartyPreferred() || this.isThirdPartyLocalDeilveryEnabled();
        return {
            openDatePicker: false,
            use3rdPartyDelivery: isThirdPartyDeliveryOn,
            useDoordash:
                helper.isDelivery(this.props.shippingMethod) &&
                this.isDoordashEnabled() &&
                (this.isThirdPartyPreferred() ||
                    (this.isThirdPartyLocalDeilveryEnabled() &&
                        this.isCreatedThirdPartyDeliveryTaksOnAcceptOrderEnabled())),
        };
    };

    componentDidUpdate(prevProps, prevState) {
        const isModalTurnOn = !prevProps.displayModal && this.props.displayModal === true;
        const isPickUpDateChanged = prevState.dateString !== this.state.dateString;
        const isPickUpTimeChanged = prevState.timeString !== this.state.timeString;
        const isDoorDashOptionEnabled = prevState.useDoordash === false && this.state.useDoordash === true;

        if (
            this.showDoordash() &&
            this.state.useDoordash &&
            (isModalTurnOn || isPickUpDateChanged || isPickUpTimeChanged || isDoorDashOptionEnabled)
        ) {
            if (!this.state.fetchEstimatedDelivery) {
                this.setState({ fetchEstimatedDelivery: true });
                this.props.fetchDeliveryFeeEstimate({ est_dt: this.getETA(), order_id: this.props.order?.ord_id });
                setTimeout(() => this.unlock(), HALF_SECOND_IN_MS);
            }
        }
    }

    str = (id, values) => this.props.intl.formatMessage({ id }, values);

    afterPreparing = () => {
        const deliveryStatus = getDeliveryStatusNumber(_.get(this.props, "order"));
        return deliveryStatus >= _reverseConfig.ORDER_DELIVERY_STATUS_MAP("store_is_preparing");
    };

    afterAccepted = () => {
        const deliveryStatus = getDeliveryStatusNumber(_.get(this.props, "order"));
        return deliveryStatus >= _reverseConfig.ORDER_DELIVERY_STATUS_MAP("store_accepted");
    };

    getDateString = () => {
        const orderDefaultString = getDefaultETA(this.props.order, this.props.orderStore).format(DATE_FORMAT);
        return this.state.dateString || orderDefaultString;
    };

    getTimeString = () => {
        const orderDefaultString = getDefaultETA(this.props.order, this.props.orderStore).format(TIME_FORMAT);
        return this.state.timeString || orderDefaultString;
    };

    getCommuteTime = () => {
        return this.state.commuteTime || getCommuteTime(this.props.order);
    };

    getTitle = () => {
        return "order_ready_time_setting";
    };

    getETA = () => {
        const dateString = this.getDateString();
        const timeString = this.getTimeString();
        return moment(`${dateString} ${timeString}`, `${DATE_FORMAT} ${TIME_FORMAT}`).format(DATE_TIME_FORMAT);
    };

    onSubmitClick = () => {
        const { updateLiveOrderDeliveryStatus, order, onSubmitClick } = this.props;
        const time = this.getETA();
        const startPreparing = this.state.startPreparing;
        if (this.state.useDoordash) {
            this.props.createDoordashDelivery({
                order_id: this.props.order.ord_id,
                est_dt: time,
            });
            this.onCloseClick();
            return;
        }
        if (onSubmitClick) {
            onSubmitClick({
                est_dt: time,
                start_preparing: startPreparing ? 1 : 0,
                commute_time: this.getCommuteTime(),
            });
        } else {
            updateLiveOrderDeliveryStatus({
                order_id: order["ord_id"],
                action: this.afterAccepted() && startPreparing ? 4 : 1,
                est_dt: time,
                start_preparing: startPreparing ? 1 : 0,
                commute_time: this.getCommuteTime(),
            });
        }
        this.onCloseClick();
    };

    onCloseClick = () => {
        if (this.props.onCloseClick) this.props.onCloseClick();
        this.setState(this.getInitialState());
    };

    renderETADateTime = () => {
        const getETADisplay = () => {
            const formats = {
                nextDay: `[${this.str("tomorrow")},] ${DIPLAY_DATE_FORMAT}`,
                sameDay: `[${this.str("today")},] ${DIPLAY_DATE_FORMAT}`,
                lastDay: `[${this.str("yesterday")},] ${DIPLAY_DATE_FORMAT}`,
                nextWeek: DIPLAY_DATE_FORMAT,
                lastWeek: DIPLAY_DATE_FORMAT,
                sameElse: DIPLAY_DATE_FORMAT,
            };
            return moment(this.getDateString(), DATE_FORMAT).calendar(null, formats);
        };

        const renderTitle = () => {
            return (
                <div className="eta-date-title-wrapper">
                    <FormattedMessage
                        id={
                            helper.isEatIn(this.props.shippingMethod)
                                ? "estimated_ready_time"
                                : "estimated_ready_pickup_time"
                        }
                    />
                </div>
            );
        };

        const renderDate = () => (
            <div className="eta-date-text-wrapper">
                <div className="eta-date-text">
                    <span
                        id="eta-date-display-text"
                        className="eta-date-display-text"
                        onClick={() => {
                            this.setState({ openDatePicker: true });
                        }}
                    >
                        <div className="eta-date-icon mr-2">
                            <VscCalendar />
                        </div>
                        {getETADisplay()}
                    </span>
                    <span className="eta-date-time-picker">
                        <DatePicker
                            allowClear={false}
                            open={this.state.openDatePicker}
                            onOpenChange={(open) => {
                                this.setState({ openDatePicker: open });
                            }}
                            value={moment(this.getDateString(), DATE_FORMAT)}
                            disabledDate={(current) => {
                                return current < moment().startOf("day");
                            }}
                            defaultValue={moment()}
                            onChange={(date, dateString) => this.setState({ dateString: dateString })}
                            format={DATE_FORMAT}
                        />
                    </span>
                </div>
                <div className="eta-date-empty"></div>
            </div>
        );

        const renderTime = () => {
            const currentTime = this.getTimeString();
            const onChange = (add = true) => {
                const newTime = moment(currentTime, TIME_FORMAT)
                    .add(add ? ETA_ADD_MINUS_STEP : -ETA_ADD_MINUS_STEP, "minutes")
                    .format(TIME_FORMAT);
                this.setState({ timeString: newTime });
            };
            return (
                <div className="eta-time-text-wrapper">
                    <div className="eta-selection-item-button eta-removeProduct" onClick={() => onChange(false)}>
                        <IoIosRemove />
                    </div>
                    <div className="eta-time-text">
                        <TimePicker
                            allowClear={false}
                            value={moment(this.getTimeString(), TIME_FORMAT)}
                            defaultValue={moment().add(ETA_DEFAULT_ADD_MINS, "minutes")}
                            use12Hours
                            format={TIME_FORMAT}
                            onChange={(time, timeString) => this.setState({ timeString: timeString })}
                        />
                    </div>
                    <div className="eta-selection-item-button eta-addProduct" onClick={() => onChange(true)}>
                        <IoMdAdd />
                    </div>
                </div>
            );
        };

        return (
            <div>
                {renderTitle()}
                {renderDate()}
                {renderTime()}
            </div>
        );
    };

    renderETAAddTimeButtonGroup = () => {
        //diff Mins
        const now = moment();
        const eta = moment(this.getTimeString(), TIME_FORMAT);
        const duration = moment.duration(eta.diff(now));
        const diffMins = parseInt(duration.asMinutes());

        const data = [
            {
                value: 0,
                label_time: "now",
            },
            {
                value: 15,
                label_time: "mins",
                label_value: 15,
            },
            {
                value: 30,
                label_time: "mins",
                label_value: 30,
            },
            {
                value: 45,
                label_time: "mins",
                label_value: 45,
            },
            {
                value: 60,
                label_time: "hours",
                label_value: 1,
            },
            {
                value: 90,
                label_time: "hours",
                label_value: 1.5,
            },
            {
                value: 120,
                label_time: "hours",
                label_value: 2,
            },
        ];

        const renderNowLabel = () => {
            return (
                <div className="eta-add-time-buttons-now-label">
                    <FormattedMessage id="now" />
                </div>
            );
        };

        const renderOtherTimeLabel = (button) => {
            return (
                <div className="eta-add-time-buttons-other-time-label">
                    <div className="eta-add-time-buttons-other-time-label-value">+{button.label_value}</div>
                    <div className="eta-add-time-buttons-other-time-label-time-text">
                        <FormattedMessage id={button.label_time} />
                    </div>
                </div>
            );
        };

        return (
            <div className="eta-add-time-buttons-group-wrapper">
                {data.map((button, i) => {
                    const isSelected = diffMins < button.value + 1 && button.value - 2 < diffMins;
                    const ButtonComponent = isSelected ? StyledActiveButton : StyledButton;
                    return (
                        <ButtonComponent
                            key={`eta-add-time-button-${button.value}-${i}`}
                            onClick={() => {
                                const newTime =
                                    button.label_time === "now"
                                        ? moment()
                                        : moment(
                                              `${this.getDateString()}${this.getTimeString()}`,
                                              `${DATE_FORMAT}${TIME_FORMAT}`
                                          ).add(button.value, "minutes");
                                this.setState({
                                    dateString: newTime.format(DATE_FORMAT),
                                    timeString: newTime.format(TIME_FORMAT),
                                });
                            }}
                            color="primary"
                        >
                            {button.label_time === "now" ? renderNowLabel() : renderOtherTimeLabel(button)}
                        </ButtonComponent>
                    );
                })}
            </div>
        );
    };

    renderCommuteTime = () => {
        const renderTitle = () => {
            return (
                <div className="eta-date-title-wrapper">
                    <FormattedMessage id="estimated_commute_time" />
                </div>
            );
        };
        const renderTime = () => {
            let currentTime = this.getCommuteTime();
            const onChange = (add = true) => {
                const addTime = add ? ETA_ADD_MINUS_STEP : -ETA_ADD_MINUS_STEP;
                const newTime = Number(currentTime) + addTime;
                this.setState({ commuteTime: newTime });
            };
            return (
                <div className="eta-time-text-wrapper">
                    <div className="eta-selection-item-button eta-removeProduct" onClick={() => onChange(false)}>
                        <IoIosRemove />
                    </div>
                    <div className="eta-time-text">
                        {currentTime} <FormattedMessage id="mins" />
                    </div>
                    <div className="eta-selection-item-button eta-addProduct" onClick={() => onChange(true)}>
                        <IoMdAdd />
                    </div>
                </div>
            );
        };

        if (helper.isDelivery(this.props.shippingMethod))
            return (
                <div>
                    {renderTitle()}
                    {renderTime()}
                </div>
            );
    };

    renderCommuteButtonGroup = () => {
        //diff Mins
        const data = [
            {
                value: 15,
                label_time: "mins",
                label_value: 15,
            },
            {
                value: 30,
                label_time: "mins",
                label_value: 30,
            },
            {
                value: 45,
                label_time: "mins",
                label_value: 45,
            },
            {
                value: 60,
                label_time: "hours",
                label_value: 1,
            },
            {
                value: 90,
                label_time: "hours",
                label_value: 1.5,
            },
            {
                value: 120,
                label_time: "hours",
                label_value: 2,
            },
        ];

        const renderOtherTimeLabel = (button) => {
            return (
                <div className="eta-add-time-buttons-other-time-label">
                    <div className="eta-add-time-buttons-other-time-label-value">+{button.label_value}</div>
                    <div className="eta-add-time-buttons-other-time-label-time-text">
                        <FormattedMessage id={button.label_time} />
                    </div>
                </div>
            );
        };

        if (helper.isDelivery(this.props.shippingMethod))
            return (
                <div className="eta-add-time-buttons-group-wrapper">
                    {data.map((button, i) => {
                        const isSelected = this.getCommuteTime() === button.value;
                        const ButtonComponent = isSelected ? StyledActiveButton : StyledButton;
                        return (
                            <ButtonComponent
                                key={`button-${button.value}-${button.label_time}-${i}`}
                                onClick={() => this.setState({ commuteTime: button.value })}
                                color="primary"
                            >
                                {renderOtherTimeLabel(button)}
                            </ButtonComponent>
                        );
                    })}
                </div>
            );
    };

    renderDeliveryTime = () => {
        if (helper.isDelivery(this.props.shippingMethod))
            return (
                <div className="delivery-time-wrapper">
                    <div className="delivery-time-title">
                        <FormattedMessage id="estimated_delivery_time" />
                    </div>
                    <div className="delivery-time-content">
                        {moment(this.getETA()).add(this.getCommuteTime(), "minutes").format(DELIVERY_TIME_FORMAT)}
                    </div>
                </div>
            );
    };

    renderStartPreparingCheckbox = () => {
        const orderShow = !this.afterPreparing() && isScheduledOrder(this.props.order, this.props.orderStore);
        const show = orderShow;
        const startingPreparing = Boolean(this.state.startPreparing);
        if (show)
            return (
                <div
                    onClick={() => {
                        this.setState({ startPreparing: !startingPreparing });
                    }}
                    className="eta-start-preparing-check-box-wrapper"
                >
                    <Checkbox
                        className="eta-start-preparing-check-box"
                        checked={startingPreparing}
                        style={{ marginRight: "10px" }}
                    />
                    <FormattedMessage id="start_prapring_now_check_box_message" />
                </div>
            );
    };

    renderFooter = () => {
        return (
            <div className="eta-dialog-buttons">
                <div className="eta-dialog-button">
                    <AntdButton size="large" onClick={() => this.onCloseClick()}>
                        <b>
                            {" "}
                            <FormattedMessage id="cancel" />
                        </b>
                    </AntdButton>
                </div>
                <div className="eta-dialog-button">
                    <AntdButton size="large" onClick={() => this.onSubmitClick()} type="primary">
                        <b>
                            <FormattedMessage id="confirm" />
                        </b>
                    </AntdButton>
                </div>
            </div>
        );
    };
    renderDoordashOption = () => {
        // in setting, if "3rd Party Local Delivery" is selected, the doordash option is always eanbled and readonly
        const isThirdPartyPreferred = this.isThirdPartyPreferred();
        return (
            <div
                className="doordash-selection-wrapper"
                onClick={() => {
                    if (!isThirdPartyPreferred) {
                        this.setState({ useDoordash: !this.state.useDoordash });
                    }
                }}
            >
                <Checkbox
                    checked={isThirdPartyPreferred || this.state.useDoordash}
                    disabled={isThirdPartyPreferred}
                    onClick={() => {
                        this.setState({ useDoordash: !this.state.useDoordash });
                    }}
                    className="doordash-check-box"
                />
                <FormattedMessage id="use_doordash" />
            </div>
        );
    };

    /**
     * unlock the reFetchEstFee so it can be called again
     */
    unlock = () => {
        this.setState({ fetchEstimatedDelivery: false });
    };

    renderEstimatedDeliveryCode = () => {
        const fullDate = this.props.doordashDeliveryDetails?.estDeliveryTime;
        const estDeliveryFee = this.props.estimatedDeliveryFee
            ? this.props.estimatedDeliveryFee
            : this.props.doordashDeliveryDetails?.estDeliveryFee;
        const dateString = fullDate?.slice(0, 10);
        const timeString = fullDate?.slice(11, 13) + ":" + fullDate?.slice(14, 16);
        const deliveryTime = moment(`${dateString} ${timeString}`, `${DATE_FORMAT} ${TIME_FORMAT}`).format(
            DELIVERY_TIME_FORMAT
        );
        return (
            <Spin spinning={this.props.loading}>
                <div className="whole-wrapper">
                    <div className="estimated-title-wrapper">
                        <FormattedMessage id="estimated_delivery" />
                    </div>
                    {(this.props.estimatedDeliveryFee !== -1 && this.props.estimatedDeliveryFee != null) ||
                    (estDeliveryFee !== -1 && estDeliveryFee != null) ? (
                        <div className="eta-date-tax-wrapper">
                            <FormattedMessage id="estimated_delivery_fee" values={{ price: estDeliveryFee }} />
                        </div>
                    ) : (
                        <div
                            className="eta-date-fail-tax-wrapper"
                            onClick={() => {
                                this.props.fetchDeliveryFeeEstimate({
                                    est_dt: this.getETA(),
                                    order_id: this.props.order?.ord_id,
                                });
                            }}
                        >
                            <FormattedMessage id="delivery_fee_try_again" />
                            <FiRefreshCcw style={{ marginLeft: "10px" }} />
                        </div>
                    )}
                    {deliveryTime != "Invalid date" && (
                        <div className="deliver-time-text">
                            <FormattedMessage
                                id="estimated_doordash_delivery_time"
                                values={{
                                    time: deliveryTime,
                                }}
                            />
                        </div>
                    )}
                </div>
            </Spin>
        );
    };
    showDoordash = () => {
        const deliveryStatus = this.props.order.delivery_status;
        const hasDeliveryPartnerName = !_.isEmpty(this.props.order.delivery_partner_name);
        const isDelivery = helper.isDelivery(this.props.shippingMethod);
        return (
            !!this.state.use3rdPartyDelivery &&
            deliveryStatus == DELIVERY_STATUS_PENDING &&
            hasDeliveryPartnerName &&
            this.isDoordashEnabled() &&
            isDelivery
        );
    };
    render() {
        let { displayModal, intl } = this.props;
        let { formatMessage } = intl;
        const showDoorDash = this.showDoordash();
        return (
            <Modal
                width={400}
                title={formatMessage({ id: this.getTitle() })}
                bodyStyle={{ paddingTop: "8px", paddingBottom: "8px" }}
                visible={displayModal}
                footer={this.renderFooter()}
                onCancel={() => this.onCloseClick()}
            >
                <div className="processsing-modal-container">
                    {this.renderETADateTime()}
                    {this.renderETAAddTimeButtonGroup()}
                    {showDoorDash && this.renderDoordashOption()}
                    {!this.state.useDoordash && this.renderCommuteTime()}
                    {!this.state.useDoordash && this.renderCommuteButtonGroup()}
                    {showDoorDash && this.state.useDoordash && this.renderEstimatedDeliveryCode()}
                    {!this.state.useDoordash && this.renderDeliveryTime()}
                    {this.renderStartPreparingCheckbox()}
                </div>
            </Modal>
        );
    }
}

const mapStateToProps = (state, ownProps) => ({
    shippingMethod: _.get(ownProps, "order.shp_mtd", null),
    state: state,
    thirdPartyDelivery: _.get(state, "store.storeThirdPartyDelivery", {}),
    doordashDeliveryDetails: _.get(state, "store.doordashDeliveryDetails", {}),
    storeFlags: _.get(state, "store.records.store_flg"),
    loading: _.get(state, "store.doordashDeliveryDetails.loading", false),
    storePickupDelivery: _.get(state, "store.storePickupDelivery", {}),
});

const mapDispatchToProps = {
    updateLiveOrderDeliveryStatus: _actions.updateLiveOrderDeliveryStatus,
    createDoordashDelivery: createDoordashDelivery,
    fetchDeliveryFeeEstimate,
};

export default connect(mapStateToProps, mapDispatchToProps)(injectIntl(ProcessingModal));
